import { type ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import useOrderRequest from '../hooks/useOrderRequest'
import useOrderRequestLineItems from '../hooks/useOrderRequestLineItems'
import OrderRequestSummaryPanel from '../components/OrderRequestSummaryPanel'
import OrderRequestDetailsPanel from '../components/OrderRequestDetailsPanel'
import OrderRequestSpendPanel from '../components/OrderRequestSpendPanel'
import OrderRequestHeader from '../components/OrderRequestHeader'
import styles from '../assets/scss/OrderRequest.module.scss'
import OrderRequestActionMenu from '../components/OrderRequestActionMenu'
import OrderRequestStatusBadge from '../components/OrderRequestStatusBadge'

function OrderRequest (): ReactElement {
  const { orderRequestId } = useParams()
  const { data: orderRequest, isLoading: isLoadingOrderRequest } =
    useOrderRequest(orderRequestId)
  const { isLoading: isLoadingLineItems } =
    useOrderRequestLineItems(orderRequestId)

  const isLoading = isLoadingOrderRequest || isLoadingLineItems

  return (
    <Container fluid="auto" className="ma-page">
      <OrderRequestHeader />

      <div className={styles.content}>
        {isLoading && <LoadingSpinner />}

        {!isLoading && orderRequest && (
          <>
            <div className={styles['summary-header']}>
              {orderRequest.order_status && (
                <OrderRequestStatusBadge status={orderRequest.order_status} />
              )}

              <h2>{orderRequest?.order_ref}</h2>

              <OrderRequestActionMenu orderRequest={orderRequest} />
            </div>

            <OrderRequestSummaryPanel orderRequest={orderRequest} />

            <OrderRequestSpendPanel orderRequest={orderRequest} />

            <OrderRequestDetailsPanel orderRequest={orderRequest} />
          </>
        )}
      </div>
    </Container>
  )
}

export default OrderRequest
