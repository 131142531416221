import { type ReactElement, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import {
  ProductPriceTaxCodeEnum,
  type ProductResource
} from '@amici/myamici-api-client'
import useProductPrice from '../hooks/useProductPrice'
import useStock from '../hooks/useStock'
import MaModal from '../../common/components/MaModal'
import ProductPrice from './ProductPrice'
import AddToBasket from './AddToBasket'
import ProductDetailsStockInfo from './ProductDetailsStockInfo'
import styles from '../assets/scss/ProductDetails.module.scss'
import SupplierSummaryModal from './SupplierSummaryModal'
import SupplierStatusIndicator from './SupplierStatusIndicator'

function ProductDetailsPurchasing ({
  product
}: Readonly<{
  product: ProductResource
}>): ReactElement {
  const { t } = useTranslation()
  const productPriceData = useProductPrice(product?.id, null, null, null)
  const { data: stockCheckData } = useStock(product?.id)
  const [showWarranty, setShowWarranty] = useState<boolean>(false)
  const [showSummary, setShowSummary] = useState<boolean>(false)

  const isSelfServe = !!product?.is_self_serve
  const isActive = product?.status === 'active' && !isSelfServe

  const leadTimeValue = stockCheckData?.lead_time
    ? /^\d+$/.test(stockCheckData?.lead_time)
      ? t('product.details.lead_time.days', {
          count: parseInt(stockCheckData?.lead_time, 10)
        })
      : stockCheckData?.lead_time
    : '-'

  const canAddToBasket = isActive && !!productPriceData.bestPrice

  const hideWarranty = !isActive || !product?.warranty
  const hasWarrantyUrl = /(https?:\/\/)/.test(product.warranty ?? '')
  const canShowWarrantyModal =
    !hasWarrantyUrl && product?.warranty && product.warranty.length > 0

  return (
    <div className={styles.purchasing}>
      <div
        className={classNames(styles.supplier)}
        title={t(
          `product.details.supplier.${
            isSelfServe ? 'supplied_by' : 'buy_from'
          }.title`,
          { supplier: product.supplier?.name }
        )}
      >
        <div className={classNames(styles['name-wrapper'], 'text-truncate')}>
          <Trans
            i18nKey={`product.details.supplier.${
              isSelfServe ? 'supplied_by' : 'buy_from'
            }`}
            values={{ supplier: product.supplier?.name }}
            components={{ bold: <b /> }}
          />
        </div>
        <SupplierStatusIndicator productId={product.id} />
      </div>

      <ProductDetailsStockInfo product={product} />
      <div className={styles['purchasing-controls-wrapper']}>
        {isActive && (
          <ProductPrice
            productId={product.id ?? ''}
            currency={null}
            listPrice={null}
            bestPrice={null}
            isSelfServe={isSelfServe}
            variant="full"
          />
        )}

        {canAddToBasket && (
          <AddToBasket product={product} stockCheckResource={stockCheckData} />
        )}
      </div>

      <Table>
        <tbody>
          <tr>
            <th>{t('product.details.part_number')}</th>
            <td>
              {product?.supplier_product_url !== '' && (
                <Link to={product?.supplier_product_url ?? ''} target="_blank">
                  {product?.part_number}
                </Link>
              )}
              {product?.supplier_product_url === '' && (
                <>{product?.part_number}</>
              )}
            </td>
          </tr>
          <tr>
            <th>{t('product.details.lead_time')}</th>
            <td>{isActive ? leadTimeValue : '-'}</td>
          </tr>
          <tr>
            <th>{t('product.details.tax_rate')}</th>
            <td>
              {isActive
                ? t(
                    `product.details.tax_codes.${
                      productPriceData.taxCode
                        ? productPriceData.taxCode.toLowerCase()
                        : ProductPriceTaxCodeEnum.NOT_SET.toLowerCase()
                    }`
                  )
                : '-'}
            </td>
          </tr>
          <tr>
            <th>{t('product.details.warranty.title')}</th>
            {hideWarranty ? (
              <td>-</td>
            ) : (
              <td>
                {hasWarrantyUrl && (
                  <Link to={product.warranty ?? ''} target="_blank">
                    {t('product.details.warranty.view')}
                  </Link>
                )}

                {canShowWarrantyModal && (
                  <span
                    className={styles['btn-link']}
                    role="button"
                    onClick={() => {
                      setShowWarranty(true)
                    }}
                  >
                    {t('product.details.warranty.view')}
                  </span>
                )}
              </td>
            )}
          </tr>
          <tr>
            <td colSpan={2} className="py-2">
              <span
                className={styles['btn-link']}
                role="button"
                onClick={() => {
                  setShowSummary(true)
                }}
              >
                {t('product.details.supplier.supplier_summary.title')}
              </span>
            </td>
          </tr>
        </tbody>
      </Table>
      <SupplierSummaryModal
        supplierId={product.supplier?.id ?? ''}
        showSummary={showSummary}
        setShowSummary={setShowSummary}
      />

      {canShowWarrantyModal && (
        <MaModal
          show={showWarranty}
          title={t('product.details.warranty.title')}
          onClose={() => {
            setShowWarranty(false)
          }}
        >
          <div className={styles['text-field-container']}>
            {product?.warranty}
          </div>
        </MaModal>
      )}
    </div>
  )
}

export default ProductDetailsPurchasing
