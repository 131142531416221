import { type ReactElement } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import QuickShopPanel from '../components/QuickShopPanel'
import OrdersAndItems from '../components/OrdersAndItems'
import FrequentlyPurchasedProducts from '../../purchasing/components/FrequentlyPurchasedProducts'
import AssetContactCard from '../components/AssetContactCard'
import RequestCard from '../components/RequestCard'
import MaPanel from '../../common/components/MaPanel'
import styles from '../assets/scss/Home.module.scss'

function Home (): ReactElement {
  return (
    <Container fluid="auto" className="ma-page">
      <QuickShopPanel />

      <OrdersAndItems />

      <MaPanel>
        <MaPanel.Body>
          <FrequentlyPurchasedProducts />
        </MaPanel.Body>
      </MaPanel>

      <Row>
        <Col sm={12} md={6} className={styles['info-card-wrapper']}>
          <AssetContactCard />
        </Col>
        <Col sm={12} md={6} className={styles['info-card-wrapper']}>
          <RequestCard />
        </Col>
      </Row>
    </Container>
  )
}

export default Home
