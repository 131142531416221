import useSWR, { type KeyedMutator } from 'swr'
import {
  type OrderRequestHistoryContent,
  type OrderRequestHistoryEntry
} from '@amici/myamici-api-client'
import useAccounts from '../../common/hooks/useAccounts'
import useApi from '../../common/hooks/useApi'

interface UseOrderRequestHistoryHook {
  historyEntries: OrderRequestHistoryEntry[]
  mutate: KeyedMutator<OrderRequestHistoryContent>
  isLoading: boolean
}

function useOrderRequestHistory (
  orderRequestId = ''
): UseOrderRequestHistoryHook {
  const { activeAccount } = useAccounts()
  const {
    orderRequestsApi: { getOrderRequestHistory },
    fetcher
  } = useApi()

  const accountId = activeAccount?.accountId ?? ''

  const { data, isLoading, mutate } = useSWR<OrderRequestHistoryContent, Error>(
    accountId && orderRequestId
      ? ['order-request-history', orderRequestId, accountId]
      : null,
    async () =>
      await fetcher(getOrderRequestHistory, { orderRequestId, accountId })
  )

  const historyEntries = data?.content ?? []

  return {
    historyEntries,
    mutate,
    isLoading
  }
}

export default useOrderRequestHistory
