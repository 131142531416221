import { type ReactElement, type HTMLAttributes } from 'react'
import {
  type OrderRequestReference,
  type OrderLineItemResource
} from '@amici/myamici-api-client'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { type CheckedState } from '../../common/components/MaCheckbox'
import MaPanel from '../../common/components/MaPanel'
import OrderLineItems from './OrderLineItems'
import useAccounts from '../../common/hooks/useAccounts'
import usePurchasingPermissions from '../hooks/usePurchasingPermissions'
import MaActionMenu, {
  MaActionMenuItem
} from '../../common/components/MaActionMenu'
import styles from '../assets/scss/PurchaseOrderPanel.module.scss'

export interface PurchaseOrderOrderItemsProps
  extends HTMLAttributes<HTMLElement> {
  lineItems?: OrderLineItemResource[]
  orderRequestReference?: OrderRequestReference | null
  selectedLineItemIds?: string[]
  onEditLineItem: (lineItem: OrderLineItemResource) => void
  onGroupEdit?: () => void
  onItemSelectedChange?: (
    lineItem: OrderLineItemResource,
    checked: CheckedState
  ) => void
}

function PurchaseOrderOrderItems ({
  lineItems,
  orderRequestReference,
  selectedLineItemIds,
  onEditLineItem,
  onGroupEdit,
  onItemSelectedChange,
  ...props
}: Readonly<PurchaseOrderOrderItemsProps>): ReactElement {
  const { t } = useTranslation()
  const { activeAccount } = useAccounts()
  const { isFinanceUser } = usePurchasingPermissions(activeAccount)
  return (
    <MaPanel className={props.className} {...props}>
      <MaPanel.Header className={styles.header}>
        <h5 className={styles.title}>
          <span>
            {orderRequestReference ? (
              <Trans
                i18nKey={t(
                  'purchase_order.order_items.title_with_order_request_link'
                )}
                values={{
                  count: lineItems?.length ?? 0
                }}
                components={{
                  OrderRequestLink: (
                    <Link
                      to={`/purchasing/order-requests/${orderRequestReference?.id}`}
                    >
                      {orderRequestReference?.reference}
                    </Link>
                  )
                }}
              />
            ) : (
              <Trans
                i18nKey={t('purchase_order.order_items.title')}
                values={{
                  count: lineItems?.length ?? 0
                }}
              />
            )}
          </span>
        </h5>

        <MaActionMenu aria-label={t('purchase_order.order_items.actions')}>
          {isFinanceUser && (
            <MaActionMenuItem
              disabled={(selectedLineItemIds?.length ?? 0) < 1}
              onClick={onGroupEdit}
            >
              {t(
                'purchase_order.order_items.action.group_edit_spend_categories'
              )}
            </MaActionMenuItem>
          )}
        </MaActionMenu>
      </MaPanel.Header>
      <MaPanel.Body>
        <OrderLineItems
          items={lineItems}
          max={10}
          showAllOption={true}
          selectable={isFinanceUser}
          selectedLineItemIds={selectedLineItemIds}
          canEdit={isFinanceUser}
          onEditLineItem={onEditLineItem}
          onItemSelectedChange={onItemSelectedChange}
        />
      </MaPanel.Body>
    </MaPanel>
  )
}

export default PurchaseOrderOrderItems
