import useApi from '../../common/hooks/useApi'
import useSWRImmutable from 'swr/immutable'
import useAccounts from '../../common/hooks/useAccounts'
import { type ProductSpecificationsResource } from '@amici/myamici-api-client'

interface UseProductSpecificationsHook {
  isLoading: boolean
  error: any
  data?: ProductSpecificationsResource
}

function useProductSpecifications (
  productId?: string
): UseProductSpecificationsHook {
  const { activeAccount } = useAccounts()
  const { productsApi, fetcher } = useApi()

  const accountId = activeAccount?.accountId ?? ''

  const { data, error, isLoading } = useSWRImmutable(
    productId && accountId
      ? ['product-specifications', productId, accountId]
      : null,
    async () =>
      await fetcher(productsApi.getSpecifications, {
        productId: productId ?? '',
        accountId
      })
  )

  return {
    isLoading,
    error,
    data
  }
}

export default useProductSpecifications
