import { Table } from 'react-bootstrap'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { type HistoryEvent } from '@amici/myamici-api-client'
import { format, parseISO } from 'date-fns'
import styles from '../assets/scss/SpendCategoryHistoryTable.module.scss'
import useSpendCategoryHistoryEntryDetails from '../hooks/useSpendCategoryHistoryEntryDetails'

function SpendCategoryHistoryTableEntryRows ({
  entry
}: Readonly<{ entry: HistoryEvent }>): ReactElement {
  const details = useSpendCategoryHistoryEntryDetails(entry)

  return (
    <>
      {details.map((value, index) => (
        <tr key={index}>
          <td className={styles.date}>
            {format(parseISO(entry.date), 'd MMM yyyy, HH:mm')}
          </td>
          <td>{value}</td>
          <td className={styles.name}>{entry.account_name}</td>
        </tr>
      ))}
    </>
  )
}

interface SpendCategoryHistoryTableProps {
  entries: HistoryEvent[]
}

function SpendCategoryHistoryTable ({
  entries
}: Readonly<SpendCategoryHistoryTableProps>): ReactElement {
  const { t } = useTranslation()

  return (
    <div className={styles['spend-category-history-table']}>
      <Table bordered>
        <thead>
          <tr>
            <th scope="col" className={styles.date}>
              {t('spend_categories.history.label.date')}
            </th>
            <th scope="col">{t('spend_categories.history.label.details')}</th>
            <th scope="col">{t('spend_categories.history.label.name')}</th>
          </tr>
        </thead>
        <tbody>
          {entries.map((entry, index) => (
            <SpendCategoryHistoryTableEntryRows key={index} entry={entry} />
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default SpendCategoryHistoryTable
