import { type ReactElement } from 'react'
import { Button } from 'react-bootstrap'
import { type FallbackProps } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import styles from './assets/scss/ErrorFallback.module.scss'

const ErrorFallback = ({
  error,
  resetErrorBoundary
}: FallbackProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <div className={styles['error-container']} data-testid="error-fallback">
      <h1>{t('error_page.generic.title')}</h1>
      <p>{t('error_page.generic.please_refresh')}</p>
      <p>
        <Button
          data-testid="error-fallback-try-again"
          onClick={resetErrorBoundary}
        >
          {t('error_page.generic.try_again')}
        </Button>
      </p>
      <p>
        {t('error_page.generic.contact_support.text')}
        <a
          href="https://www.support.myamici.co.uk/s/raise-a-case"
          target="_blank"
          rel="noreferrer"
        >
          {t('error_page.generic.contact_support.link')}
        </a>
        .
      </p>
      <p>{error.message}</p>
    </div>
  )
}

export default ErrorFallback
