import useSWR from 'swr'
import { type OutstandingManagedOrdersChart } from '@amici/myamici-api-client'
import useApi from '../../common/hooks/useApi'
import useAccounts from '../../common/hooks/useAccounts'

interface UseOutstandingManagedOrdersHook {
  data: OutstandingManagedOrdersChart
  isLoading: boolean
}

function useOutstandingManagedOrders (): UseOutstandingManagedOrdersHook {
  const { reportsApi, fetcher } = useApi()
  const { activeAccount } = useAccounts()

  const accountId = activeAccount?.accountId ?? ''

  const { data, isLoading } = useSWR(
    accountId ? ['outstanding-orders-report', accountId] : null,
    async () =>
      await fetcher(reportsApi.getOutstandingManagedOrders, { accountId })
  )

  return {
    data: data as OutstandingManagedOrdersChart,
    isLoading
  }
}

export default useOutstandingManagedOrders
