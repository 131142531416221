import type { SpendCategory } from '@amici/myamici-api-client'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import GroupEditSpendCategoriesModal from '../../common/components/GroupEditSpendCategoriesModal'

interface BasketGroupEditSpendCategoriesModalProps {
  show: boolean
  onSave: (spendCategories: SpendCategory[]) => Promise<void>
  onCancel: () => void
  disabled: boolean
}

function BasketGroupEditSpendCategoriesModal (
  props: Readonly<BasketGroupEditSpendCategoriesModalProps>
): ReactElement {
  const { t } = useTranslation()

  return (
    <GroupEditSpendCategoriesModal
      title={t('basket.group_edit_spend_categories.title')}
      {...props}
    >
      <p className="small">{t('basket.group_edit_spend_categories.message')}</p>
    </GroupEditSpendCategoriesModal>
  )
}

export default BasketGroupEditSpendCategoriesModal
