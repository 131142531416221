import { type ReactElement } from 'react'
import styles from '../assets/scss/Home.module.scss'
import { Link } from 'react-router-dom'
import { t } from 'i18next'
import { useEnv } from '../../common/hooks/useEnv'
import { Card, CardBody, CardFooter, CardHeader } from 'react-bootstrap'

function RequestCard (): ReactElement {
  const { REACT_APP_MA3_BASE_URL } = useEnv()

  return (
    <Card className={styles['info-card']}>
      <CardHeader>
        <h4>{t('request.panel.title')}</h4>
      </CardHeader>
      <CardBody>
        <p className="fw-bold">{t('request.panel.sub-title')}</p>
        <p>{t('request.panel.text')}</p>
      </CardBody>
      <CardFooter>
        <Link
          to={`${REACT_APP_MA3_BASE_URL as string}/CRM/NewRequestPage1.aspx`}
          target="_blank"
          className="btn btn-primary"
        >
          {t('request.panel.action')}
        </Link>
      </CardFooter>
    </Card>
  )
}

export default RequestCard
