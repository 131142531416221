import { type ChangeEvent, type FormEvent, type ReactElement } from 'react'
import { type FormProps } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { type OrderSearchType } from '../types/order-search-type'
import SearchForm from './SearchForm'

interface OrderSearchFormProps extends FormProps {
  search: string
  type: OrderSearchType
  showType?: boolean
  onSubmit: (event: FormEvent<HTMLFormElement>) => void
  onSearchChange: (event: ChangeEvent<HTMLInputElement>) => void
  onTypeChange?: (searchType: OrderSearchType) => void
  searchTypeOptions: Array<{ label: string; value: OrderSearchType }>
}

function OrderSearchForm ({
  search,
  type,
  showType = true,
  onSubmit,
  onSearchChange,
  onTypeChange,
  searchTypeOptions,
  ...props
}: Readonly<OrderSearchFormProps>): ReactElement {
  const { t } = useTranslation()

  return (
    <SearchForm<OrderSearchType>
      search={search}
      type={type}
      typeOptions={showType ? searchTypeOptions : []}
      placeholder={t('orders.search.placeholder')}
      onSubmit={onSubmit}
      onTypeChange={onTypeChange}
      onSearchChange={onSearchChange}
      data-testid="order-search-form"
      {...props}
    />
  )
}

export default OrderSearchForm
