import useSWR from 'swr'
import { type OutstandingLineItemsChart } from '@amici/myamici-api-client'
import useApi from '../../common/hooks/useApi'
import useAccounts from '../../common/hooks/useAccounts'

interface UseOutstandingLineItemsHook {
  data: OutstandingLineItemsChart
  isLoading: boolean
}

function useOutstandingLineItems (): UseOutstandingLineItemsHook {
  const { reportsApi, fetcher } = useApi()
  const { activeAccount } = useAccounts()

  const accountId = activeAccount?.accountId ?? ''

  const { data, isLoading } = useSWR(
    accountId ? ['outstanding-line-items-report', accountId] : null,
    async () => await fetcher(reportsApi.getOutstandingLineItems, { accountId })
  )

  return {
    data: data as OutstandingLineItemsChart,
    isLoading
  }
}

export default useOutstandingLineItems
