import { type ReactElement } from 'react'
import { Container } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import useProductDetails from '../hooks/useProductDetails'
import MaPanel from '../../common/components/MaPanel'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import ProductDetailsHeader from '../components/ProductDetailsHeader'
import ProductDetailsSummary from '../components/ProductDetailsSummary'
import ProductDetailsTabs from '../components/ProductDetailsTabs'
import ProductAlternatives from '../components/ProductAlternatives'
import styles from '../assets/scss/ProductDetails.module.scss'

function ProductDetails (): ReactElement {
  const { t } = useTranslation()
  const { productId } = useParams()
  const { isLoading, error, data: product } = useProductDetails(productId)

  const showProduct = !isLoading && !error && product
  const showError = !isLoading && error

  return (
    <Container
      fluid="auto"
      className={classNames('ma-page', styles['product-details-page'])}
    >
      <ProductDetailsHeader />

      {isLoading && <LoadingSpinner />}

      {showError && (
        <p>
          {t('product.details.not_found.sorry')}{' '}
          {t('product.details.not_found.message', { product_id: productId })}
        </p>
      )}

      {showProduct && (
        <>
          <MaPanel>
            <MaPanel.Body>
              <ProductDetailsSummary product={product} key={product.id} />
              <ProductDetailsTabs product={product} />
            </MaPanel.Body>
          </MaPanel>

          <MaPanel className={styles.panel}>
            <MaPanel.Body>
              <ProductAlternatives product={product} />
            </MaPanel.Body>
          </MaPanel>
        </>
      )}
    </Container>
  )
}

export default ProductDetails
