import useSWR from 'swr'
import useApi from '../../common/hooks/useApi'
import useAccounts from '../../common/hooks/useAccounts'

function useUnreadNotifications (): number {
  const {
    notificationsApi: { getUnreadCount },
    fetcher
  } = useApi()
  const { activeAccount } = useAccounts()

  const { data: count } = useSWR(
    activeAccount?.accountId
      ? ['unread-notifications-count', activeAccount.accountId]
      : null,
    async () =>
      await fetcher(getUnreadCount, { accountId: activeAccount?.accountId })
  )

  return count ?? 0
}

export default useUnreadNotifications
