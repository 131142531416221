import MaActionMenu, {
  MaActionMenuItem
} from '../../common/components/MaActionMenu'
import OrderRequestEditSummaryModal from './OrderRequestEditSummaryModal'
import OrderRequestCancelModal from './OrderRequestCancelModal'
import { type OrderRequest } from '@amici/myamici-api-client'
import { type ReactNode, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import useAccounts from '../../common/hooks/useAccounts'
import OrderRequestWithdrawModal from './OrderRequestWithdrawModal'
import useOrderRequestPermissions from '../hooks/useOrderRequestPermissions'

interface OrderRequestActionMenuProps {
  readonly orderRequest: OrderRequest
}

function OrderRequestActionMenu ({
  orderRequest
}: OrderRequestActionMenuProps): ReactNode {
  const { t } = useTranslation()
  const { activeAccount } = useAccounts()
  const { canCancel, canEdit, canWithdraw } = useOrderRequestPermissions({
    orderRequest,
    account: activeAccount
  })
  const [showEditModal, setShowEditModal] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showWithdrawModal, setShowWithdrawModal] = useState(false)

  return canCancel || canEdit ? (
    <>
      <MaActionMenu aria-label={t('order_request.action.menu')}>
        {canEdit && (
          <MaActionMenuItem
            onClick={() => {
              setShowEditModal(true)
            }}
          >
            <Trans i18nKey="order_request.action.edit" />
          </MaActionMenuItem>
        )}
        {canWithdraw && (
          <MaActionMenuItem
            onClick={() => {
              setShowWithdrawModal(true)
            }}
          >
            <Trans i18nKey="order_request.action.withdraw" />
          </MaActionMenuItem>
        )}
        {canCancel && (
          <MaActionMenuItem
            onClick={() => {
              setShowCancelModal(true)
            }}
          >
            <Trans i18nKey="order_request.action.cancel" />
          </MaActionMenuItem>
        )}
      </MaActionMenu>
      <OrderRequestEditSummaryModal
        orderRequest={orderRequest}
        show={showEditModal}
        onClose={() => {
          setShowEditModal(false)
        }}
      />
      <OrderRequestCancelModal
        orderRequest={orderRequest}
        show={showCancelModal}
        onClose={() => {
          setShowCancelModal(false)
        }}
      />
      <OrderRequestWithdrawModal
        orderRequest={orderRequest}
        show={showWithdrawModal}
        onClose={() => {
          setShowWithdrawModal(false)
        }}
      />
    </>
  ) : null
}

export default OrderRequestActionMenu
