import { type Dispatch, useReducer } from 'react'
import ReactGA from 'react-ga4'

export interface Layout {
  showOffcanvasNav: boolean
  showBottomOffcanvasMenu: boolean
  quickAccessExpanded: boolean
  quickAccessSidebarWidthPx: number
}

export enum LayoutAction {
  OPEN_OFFCANVAS_NAV = 'OPEN_OFFCANVAS_NAV',
  CLOSE_OFFCANVAS_NAV = 'CLOSE_OFFCANVAS_NAV',
  OPEN_BOTTOM_OFFCANVAS_MENU = 'OPEN_BOTTOM_OFFCANVAS_MENU',
  CLOSE_BOTTOM_OFFCANVAS_MENU = 'CLOSE_BOTTOM_OFFCANVAS_MENU',
  CLOSE_ALL_MENUS = 'CLOSE_ALL_MENUS',
  EXPAND_QUICK_ACCESS = 'EXPAND_QUICK_ACCESS',
  COLLAPSE_QUICK_ACCESS = 'COLLAPSE_QUICK_ACCESS'
}

export const QUICK_ACCESS_COLLAPSED_WIDTH_PX = 56
export const QUICK_ACCESS_EXPANDED_WIDTH_PX = 332

function layoutReducer (
  layout: Layout,
  action: { type: LayoutAction; value?: number | string }
): Layout {
  switch (action.type) {
    case LayoutAction.OPEN_OFFCANVAS_NAV:
      return {
        ...layout,
        showOffcanvasNav: true,
        showBottomOffcanvasMenu: false
      }
    case LayoutAction.CLOSE_OFFCANVAS_NAV:
      return {
        ...layout,
        showOffcanvasNav: false
      }
    case LayoutAction.OPEN_BOTTOM_OFFCANVAS_MENU:
      ReactGA.event('open_component', {
        component_type: 'offcanvas',
        component_id: 'tasks'
      })
      return {
        ...layout,
        showBottomOffcanvasMenu: true,
        showOffcanvasNav: false,
        quickAccessExpanded: true,
        quickAccessSidebarWidthPx: QUICK_ACCESS_EXPANDED_WIDTH_PX
      }
    case LayoutAction.CLOSE_BOTTOM_OFFCANVAS_MENU:
      ReactGA.event('close_component', {
        component_type: 'offcanvas',
        component_id: 'tasks'
      })
      return {
        ...layout,
        showBottomOffcanvasMenu: false,
        quickAccessExpanded: false,
        quickAccessSidebarWidthPx: QUICK_ACCESS_COLLAPSED_WIDTH_PX
      }
    case LayoutAction.CLOSE_ALL_MENUS:
      return {
        ...layout,
        showOffcanvasNav: false,
        showBottomOffcanvasMenu: false
      }
    case LayoutAction.EXPAND_QUICK_ACCESS:
      ReactGA.event('open_component', {
        component_type: 'quickaccess',
        component_id: 'tasks'
      })
      return {
        ...layout,
        quickAccessExpanded: true,
        quickAccessSidebarWidthPx: QUICK_ACCESS_EXPANDED_WIDTH_PX
      }
    case LayoutAction.COLLAPSE_QUICK_ACCESS:
      ReactGA.event('close_component', {
        component_type: 'quickaccess',
        component_id: 'tasks'
      })
      return {
        ...layout,
        quickAccessExpanded: false,
        quickAccessSidebarWidthPx: QUICK_ACCESS_COLLAPSED_WIDTH_PX
      }
  }
}

const initialLayout: Layout = {
  showOffcanvasNav: false,
  showBottomOffcanvasMenu: false,
  quickAccessExpanded: false,
  quickAccessSidebarWidthPx: QUICK_ACCESS_COLLAPSED_WIDTH_PX
}

interface UseAppLayoutHook {
  layout: Layout
  dispatch: Dispatch<{ type: LayoutAction; value?: number | string }>
}

function useAppLayout (): UseAppLayoutHook {
  const [layout, dispatch] = useReducer(layoutReducer, initialLayout)

  return {
    layout,
    dispatch
  }
}

export default useAppLayout
