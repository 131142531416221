import {
  type OrderRequest,
  OrderRequestAllOfOrderStatusEnum,
  type OrderRequestLineItem,
  type SpendCategory
} from '@amici/myamici-api-client'
import { type ReactNode, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  type ViewType,
  ViewTypeValue
} from '../types/order-request-details-summary-view-type'
import useAccounts from '../../common/hooks/useAccounts'
import useLineItemSelection from '../hooks/useLineItemSelection'
import useMinWidthObserver from '../../common/hooks/useMinWidthObserver'
import useOrderRequestLineItems from '../hooks/useOrderRequestLineItems'
import useOrderRequestSummary from '../hooks/useOrderRequestSummary'
import useOrderRequestPermissions from '../hooks/useOrderRequestPermissions'
import { useToastNotification } from '../../common/components/ToastNotificationContextProvider'
import MaPanel from '../../common/components/MaPanel'
import OrderRequestSpendPanelHeaderControls from './OrderRequestSpendPanelHeaderControls'
import OrderRequestSpendGroupedView from './OrderRequestSpendGroupedView'
import OrderRequestEditLineItemModal from './OrderRequestEditLineItemModal'
import OrderRequestRemoveLineItemModal from './OrderRequestRemoveLineItemModal'
import OrderRequestAddLineItemModal from './OrderRequestAddLineItemModal'
import OrderRequestSpendTable from './OrderRequestSpendTable'
import OrderRequestGroupEditSpendCategoriesModal from './OrderRequestGroupEditSpendCategoriesModal'
import styles from '../assets/scss/OrderRequest.module.scss'

const FULL_VIEW_MIN_WIDTH_PX = 705
const { NEW, WITHDRAWN, REJECTED } = OrderRequestAllOfOrderStatusEnum

function OrderRequestSpendPanel ({
  orderRequest
}: Readonly<{
  orderRequest: OrderRequest
}>): ReactNode {
  const { t } = useTranslation()
  const { activeAccount } = useAccounts()
  const { isFinanceUser, isRequestedByUser, isCreatedByUser } =
    useOrderRequestPermissions({
      orderRequest,
      account: activeAccount
    })
  const { lineItems, isLoading, groupUpdateLineItems } =
    useOrderRequestLineItems(orderRequest.id)
  const [activeView, setActiveView] = useState<ViewType>(ViewTypeValue.grouped)
  const { supplierIds } = useOrderRequestSummary(lineItems)
  const {
    selectedLineItemIds,
    selectedSuppliers,
    handleItemSelectedChange,
    handleToggleSelectionBySupplier
  } = useLineItemSelection(lineItems)
  const { showToastMessage } = useToastNotification()
  const [expandedSupplierIds, setExpandedSupplierIds] = useState<string[]>([])
  const [showAddLineItemModal, setShowAddLineItemModal] = useState(false)
  const [showGroupEditModal, setShowGroupEditModal] = useState(false)
  const [lineItemToEdit, setLineItemToEdit] = useState<
    OrderRequestLineItem | undefined
  >()
  const [lineItemToRemove, setLineItemToRemove] = useState<
    OrderRequestLineItem | undefined
  >()

  const ref = useRef(null)
  const compactView = !useMinWidthObserver(ref, FULL_VIEW_MIN_WIDTH_PX)

  // Expanded sections state cleanup
  useEffect(() => {
    expandedSupplierIds.forEach(id => {
      if (!supplierIds.includes(id)) {
        setExpandedSupplierIds(expandedIds =>
          expandedIds.filter(expandedId => expandedId !== id)
        )
      }
    })
  }, [expandedSupplierIds, supplierIds])

  const orderRequestStatus = orderRequest.order_status
  const statusAllowsChanges =
    orderRequestStatus === NEW ||
    orderRequestStatus === REJECTED ||
    orderRequestStatus === WITHDRAWN
  const canChange =
    (isRequestedByUser || isCreatedByUser || isFinanceUser) &&
    statusAllowsChanges
  const canGroupEdit =
    (isRequestedByUser || isCreatedByUser) && statusAllowsChanges
  const itemsSelected = selectedLineItemIds.length > 0

  const handleToggleExpandedSupplier = (supplierId: string): void => {
    if (expandedSupplierIds.includes(supplierId)) {
      setExpandedSupplierIds(
        expandedSupplierIds.filter(id => id !== supplierId)
      )
    } else {
      setExpandedSupplierIds([...expandedSupplierIds, supplierId])
    }
  }

  const handleEditFormOpen = (lineItem: OrderRequestLineItem): void => {
    setLineItemToEdit(lineItem)
  }

  const handleEditFormClose = (): void => {
    setLineItemToEdit(undefined)
  }

  const handleRemoveModalOpen = (lineItem: OrderRequestLineItem): void => {
    setLineItemToRemove(lineItem)
  }

  const handleRemoveModalClose = (): void => {
    setLineItemToRemove(undefined)
  }

  const handleGroupEditSave = async (
    newSpendCategories: SpendCategory[]
  ): Promise<void> => {
    setShowGroupEditModal(false)

    const lineItemsToUpdate = lineItems.filter(lineItem =>
      selectedLineItemIds.includes(lineItem.line_item.id)
    )
    const modifiedLineItems = lineItemsToUpdate.map(lineItem => ({
      ...lineItem,
      line_item: {
        ...lineItem.line_item,
        issues: [],
        spend_categories: [
          ...(lineItem.line_item.spend_categories ?? []).filter(
            existingSpendCategory =>
              !newSpendCategories.find(
                newSpendCategory =>
                  newSpendCategory.field_id === existingSpendCategory.field_id
              )
          ),
          ...newSpendCategories
        ].toSorted((a, b) => a.field_id.localeCompare(b.field_id))
      }
    }))

    try {
      await groupUpdateLineItems(modifiedLineItems)
    } catch {
      showToastMessage('danger', t('order_request.line_items.group_edit.error'))
    }
  }

  if (!orderRequest || isLoading) {
    return null
  }

  return (
    <>
      <MaPanel className={styles['spend-panel']} ref={ref}>
        <MaPanel.Header className={styles['spend-panel-header']}>
          <h4>{t('order_request.spend.title')}</h4>

          <OrderRequestSpendPanelHeaderControls
            compactView={compactView}
            activeView={activeView}
            allCollapsed={expandedSupplierIds.length === 0}
            canAdd={canChange}
            canGroupEdit={canGroupEdit}
            itemsSelected={itemsSelected}
            onAddNew={() => {
              setShowAddLineItemModal(true)
            }}
            onCollapseAll={() => {
              setExpandedSupplierIds([])
            }}
            onExpandAll={() => {
              setExpandedSupplierIds(supplierIds)
            }}
            onViewChange={view => {
              setActiveView(view)
            }}
            onGroupEdit={() => {
              setShowGroupEditModal(true)
            }}
          />
        </MaPanel.Header>

        <MaPanel.Body className={styles['spend-panel-body']}>
          {(compactView || activeView === ViewTypeValue.grouped) && (
            <OrderRequestSpendGroupedView
              lineItems={lineItems}
              expandedSupplierIds={expandedSupplierIds}
              compactView={compactView}
              canChange={canChange}
              selectable={canGroupEdit}
              selectedSuppliers={selectedSuppliers}
              selectedLineItemIds={selectedLineItemIds}
              onToggleExpanded={handleToggleExpandedSupplier}
              onEdit={handleEditFormOpen}
              onRemove={handleRemoveModalOpen}
              onItemSelectedChange={handleItemSelectedChange}
              onToggleSelectionBySupplier={handleToggleSelectionBySupplier}
            />
          )}
          {!compactView && activeView === ViewTypeValue.table && (
            <OrderRequestSpendTable
              orderRequestLineItems={lineItems}
              onEdit={handleEditFormOpen}
              onRemove={handleRemoveModalOpen}
              includeActions={canChange}
            />
          )}
        </MaPanel.Body>
      </MaPanel>

      <OrderRequestAddLineItemModal
        orderRequestId={orderRequest.id}
        show={showAddLineItemModal}
        onClose={() => {
          setShowAddLineItemModal(false)
        }}
      />

      <OrderRequestEditLineItemModal
        orderRequestId={orderRequest.id}
        lineItem={lineItemToEdit}
        onClose={handleEditFormClose}
      />

      <OrderRequestRemoveLineItemModal
        orderRequestId={orderRequest.id}
        lineItem={lineItemToRemove}
        onClose={handleRemoveModalClose}
      />

      <OrderRequestGroupEditSpendCategoriesModal
        show={showGroupEditModal}
        disabled={false}
        onCancel={() => {
          setShowGroupEditModal(false)
        }}
        onSave={handleGroupEditSave}
      />
    </>
  )
}

export default OrderRequestSpendPanel
