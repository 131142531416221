import useSWRMutation from 'swr/mutation'
import {
  type LineItem,
  type OrdersApiUnreceiveLineItemsRequest
} from '@amici/myamici-api-client'
import useAccounts from '../../common/hooks/useAccounts'
import useApi from '../../common/hooks/useApi'

interface useUnreceiveLineItemHook {
  isMutating: boolean
  error?: Error
  submit: (orderId: string | undefined, items: LineItem[]) => Promise<void>
}

function useUnreceiveLineItem (): useUnreceiveLineItemHook {
  const {
    ordersApi: { unreceiveLineItems },
    fetcher
  } = useApi()
  const { activeAccount } = useAccounts()

  const accountId = activeAccount?.accountId ?? ''

  const { trigger, isMutating, error } = useSWRMutation(
    accountId ? ['unreceive-line-items', accountId] : null,
    async (_, { arg }: { arg: OrdersApiUnreceiveLineItemsRequest }) =>
      await fetcher(unreceiveLineItems, arg),
    { populateCache: false, revalidate: false }
  )

  const submit = async (
    orderId: string | undefined,
    // By the spec it's an array, but always containing only one line item
    item: LineItem[]
  ): Promise<void> => {
    if (!orderId || !accountId) {
      return
    }

    await trigger({
      orderId,
      accountId,
      lineItem: item
    })
  }

  return {
    isMutating,
    error,
    submit
  }
}

export default useUnreceiveLineItem
