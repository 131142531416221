import { type HTMLAttributes, type ReactElement } from 'react'
import { type ProductHazardsResource } from '@amici/myamici-api-client'
import { Trans, useTranslation } from 'react-i18next'
import { format, isBefore, isToday } from 'date-fns'
import classNames from 'classnames'
import useDateLocale from '../../common/hooks/useDateLocale'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import styles from '../assets/scss/ProductDetailsTabHazards.module.scss'

interface CoshhAndLicenceSectionContentProps
  extends HTMLAttributes<HTMLDivElement> {
  readonly data?: ProductHazardsResource
  readonly isLoading: boolean
}

function CoshhAndLicenceSectionContent ({
  data,
  isLoading
}: CoshhAndLicenceSectionContentProps): ReactElement {
  const { t } = useTranslation()
  const dateLocale = useDateLocale()

  if (isLoading) {
    return <LoadingSpinner />
  }

  const noCoshhData = data?.coshh?.has_coshh == null
  const coshhRequired = data?.coshh?.has_coshh === true
  const coshhExpiryDate: Date | null = data?.coshh?.expiry_date
    ? new Date(data?.coshh?.expiry_date)
    : null
  const isCoshhExpired =
    coshhExpiryDate &&
    isBefore(coshhExpiryDate, new Date()) &&
    !isToday(coshhExpiryDate)
  const coshhExpiryDateFormatted =
    coshhExpiryDate &&
    format(coshhExpiryDate, 'do MMMM yyyy', { locale: dateLocale })

  const licenceRequired = data?.licence?.has_licence === true
  const licenceExpiryDate: Date | null = data?.licence?.expiry_date
    ? new Date(data?.licence?.expiry_date)
    : null
  const isLicenceExpired =
    licenceExpiryDate &&
    isBefore(licenceExpiryDate, new Date()) &&
    !isToday(licenceExpiryDate)
  const licenceExpiryDateFormatted =
    licenceExpiryDate &&
    format(licenceExpiryDate, 'do MMMM yyyy', { locale: dateLocale })

  const noDataAvailable =
    !data?.coshh && !data?.licence?.has_licence && !data?.note
  const noInfo = !coshhRequired && !licenceRequired && !data?.note

  if (noDataAvailable) {
    return (
      <div className={styles.content}>
        <p className="text-center">
          {t('product.details.tabs.hazards.content.no_coshh_or_licence')}
        </p>
      </div>
    )
  }

  return (
    <div className={styles.content}>
      <div className={styles.coshh}>
        {noCoshhData && (
          <p className={classNames({ 'text-center': noInfo })}>
            {t('product.details.tabs.hazards.content.no_coshh_requirements')}
          </p>
        )}

        {!noCoshhData && !coshhRequired && (
          <p className={classNames({ 'text-center': noInfo })}>
            {t('product.details.tabs.hazards.content.no_coshh_required')}
          </p>
        )}

        {coshhRequired && (
          <>
            <p>
              <Trans
                i18nKey="product.details.tabs.hazards.content.coshh_required"
                components={{ bold: <b /> }}
              />

              <b>{data?.coshh?.reference}</b>

              {coshhExpiryDateFormatted && (
                <Trans
                  i18nKey="product.details.tabs.hazards.content.coshh_review_date"
                  values={{
                    date: coshhExpiryDateFormatted
                  }}
                  components={{ bold: <b /> }}
                />
              )}
            </p>

            {isCoshhExpired && (
              <p className={styles.expired}>
                {t('product.details.tabs.hazards.content.coshh_expired')}
              </p>
            )}
          </>
        )}
      </div>

      <div className={styles.licence}>
        {!licenceRequired && (
          <p className={classNames({ 'text-center': noInfo })}>
            {t('product.details.tabs.hazards.content.no_licence')}
          </p>
        )}

        {licenceRequired && (
          <>
            <p>
              <Trans
                i18nKey="product.details.tabs.hazards.content.licence_required"
                components={{ bold: <b /> }}
              />

              <b>{data?.licence?.reference}</b>

              {licenceExpiryDateFormatted && !isLicenceExpired && (
                <Trans
                  i18nKey="product.details.tabs.hazards.content.licence_expires_date"
                  values={{
                    date: licenceExpiryDateFormatted
                  }}
                  components={{ bold: <b /> }}
                />
              )}

              {licenceExpiryDateFormatted && isLicenceExpired && (
                <Trans
                  i18nKey="product.details.tabs.hazards.content.licence_expired_date"
                  values={{
                    date: licenceExpiryDateFormatted
                  }}
                  components={{ bold: <b /> }}
                />
              )}
            </p>

            {isLicenceExpired && (
              <p className={styles.expired}>
                {t('product.details.tabs.hazards.content.licence_expired')}
              </p>
            )}
          </>
        )}
      </div>

      {data?.note && (
        <div className={styles.note}>
          <p>{data?.note}</p>
        </div>
      )}
    </div>
  )
}

export default CoshhAndLicenceSectionContent
