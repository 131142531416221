import type { ReactElement } from 'react'
import { type HistoryEvent } from '@amici/myamici-api-client'
import MaDescriptionList from '../../common/components/MaDescriptionList'
import { format, parseISO } from 'date-fns'
import styles from '../assets/scss/SpendCategoryHistoryList.module.scss'
import useSpendCategoryHistoryEntryDetails from '../hooks/useSpendCategoryHistoryEntryDetails'
import { useTranslation } from 'react-i18next'

interface SpendCategoryHistoryListProps {
  entries: HistoryEvent[]
}

function SpendCategoryHistoryListEntry ({
  entry
}: Readonly<{ entry: HistoryEvent }>): ReactElement {
  const { t } = useTranslation()
  const details = useSpendCategoryHistoryEntryDetails(entry)

  return (
    <>
      {details.map((value, index) => (
        <li key={index}>
          <MaDescriptionList
            items={[
              {
                term: t('spend_categories.history.label.date'),
                details: format(parseISO(entry.date), 'd MMM yyyy, HH:mm')
              },
              {
                term: t('spend_categories.history.label.details'),
                details: value
              },
              {
                term: t('spend_categories.history.label.name'),
                details: entry.account_name
              }
            ]}
          />
        </li>
      ))}
    </>
  )
}

function SpendCategoryHistoryList ({
  entries
}: Readonly<SpendCategoryHistoryListProps>): ReactElement {
  return (
    <ol className={styles['spend-category-history-list']}>
      {entries.map((entry, index) => (
        <SpendCategoryHistoryListEntry key={index} entry={entry} />
      ))}
    </ol>
  )
}

export default SpendCategoryHistoryList
