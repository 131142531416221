import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styles from './assets/scss/ErrorFallback.module.scss'

const PageNotFound = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <div className={styles['error-container']} data-testid="page-not-found">
      <h1>{t('error_page.not_found.title')}</h1>
      <p>{t('error_page.not_found.try_refreshing')}</p>
      <p>
        <Link to="/">{t('error_page.not_found.home')}</Link>
      </p>
    </div>
  )
}

export default PageNotFound
