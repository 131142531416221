/** @module common/utils/date-diff */

/**
 * Calculates time difference between the provided datetime and current time.
 * If the difference is more than one day difference in hours is discarded (equals 0).
 *
 * Do not expect precise time difference calculations -
 * these values are used only as additional info in some UI components
 * (see unit tests for return value examples)
 *
 * @param {string} dateStr - datetime string
 * @returns {days: number, hours: number}
 */
export default function getDaysHoursDiff (dateStr: string): {
  days: number
  hours: number
} {
  const currentTime = new Date().getTime()
  const providedTime = new Date(dateStr).getTime()
  const diffInDays = Math.floor(
    (currentTime - providedTime) / (1000 * 60 * 60 * 24)
  )
  const diffInHours = Math.floor(
    diffInDays < 1 ? (currentTime - providedTime) / (1000 * 60 * 60) : 0
  )

  return {
    days: diffInDays,
    hours: diffInHours
  }
}
