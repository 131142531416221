import { type Dispatch, type SetStateAction, useState } from 'react'
import {
  type DefaultApiGetAlternativesRequest,
  type DefaultApiGetBiologicalSearchRequest,
  type DefaultApiGetCASNumberSearchRequest,
  type DefaultApiGetChemicalSearchRequest,
  type DefaultApiGetECNumberSearchRequest,
  type DefaultApiGetKeywordSearchRequest,
  type DefaultApiGetPartNumberSearchRequest,
  type Results
} from '@amici/myamici-search-client'
import useSWRImmutable from 'swr/immutable'
import { type KeyedMutator } from 'swr'
import { ProductSearchType } from '../types/product-search-type'
import { type ProductSortType } from '../types/product-sort-type'
import useApi from '../../common/hooks/useApi'
import useAccounts from '../../common/hooks/useAccounts'
import { useEnv } from '../../common/hooks/useEnv'

type SearchRequestParams =
  | DefaultApiGetAlternativesRequest
  | DefaultApiGetBiologicalSearchRequest
  | DefaultApiGetCASNumberSearchRequest
  | DefaultApiGetChemicalSearchRequest
  | DefaultApiGetECNumberSearchRequest
  | DefaultApiGetKeywordSearchRequest
  | DefaultApiGetPartNumberSearchRequest

export const MIN_PAGE_SIZE = 12

export interface ProductSearchParams {
  productId?: string
  term: string
  type: ProductSearchType
  sort: ProductSortType
  page: number
  pageSize: number
  facetFilter?: string[]
}

interface UseProductSearchHook {
  data: Results
  mutate: KeyedMutator<any>
  isLoading: boolean
  searchParams: ProductSearchParams
  setSearchParams: Dispatch<SetStateAction<ProductSearchParams>>
}

function useProductSearch (): UseProductSearchHook {
  const { activeAccount } = useAccounts()
  const { searchApi, fetcher } = useApi()
  const [searchParams, setSearchParams] = useState<ProductSearchParams>({
    productId: '',
    term: '',
    type: ProductSearchType.Keyword,
    sort: 'default' as ProductSortType,
    page: 1,
    pageSize: MIN_PAGE_SIZE,
    facetFilter: []
  })
  const env = useEnv()

  const { productId, term, type, page, pageSize, sort, facetFilter } =
    searchParams

  const requestParams: SearchRequestParams = {
    query: term,
    productId,
    sort,
    page,
    size: pageSize,
    ...(facetFilter?.length && { facetFilter }),
    accountId: activeAccount?.accountId ?? '',
    maxDocs: env.REACT_APP_SEARCH_MAX_DOCS ?? 10000,
    bestPrice: true,
    returnFacets: false,
    returnResults: true
  }

  const { data, mutate, isLoading } = useSWRImmutable(
    (productId || term) && activeAccount?.accountId
      ? [
          'product-search',
          term,
          productId,
          type,
          sort,
          page,
          pageSize,
          facetFilter,
          activeAccount?.accountId
        ]
      : null,
    async () =>
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-arguments -- allow any
      await fetcher<any>(
        {
          [ProductSearchType.Keyword]: searchApi.getKeywordSearch,
          [ProductSearchType.CASNumber]: searchApi.getCASNumberSearch,
          [ProductSearchType.PartNumber]: searchApi.getPartNumberSearch,
          [ProductSearchType.ECNumber]: searchApi.getECNumberSearch,
          [ProductSearchType.Chemical]: searchApi.getChemicalSearch,
          [ProductSearchType.Biological]: searchApi.getBiologicalSearch,
          [ProductSearchType.Alternatives]: searchApi.getAlternatives
        }[type],
        requestParams
      )
  )

  return {
    data,
    mutate,
    isLoading,
    searchParams,
    setSearchParams
  }
}

export default useProductSearch
