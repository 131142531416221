import { type ReactElement } from 'react'
import { type LineItemResource } from '@amici/myamici-api-client'
import { type Row } from '@tanstack/react-table'
import OrderItemActions from './OrderItemActions'
import { useNavigate } from 'react-router'

interface OrderItemsTableBodyProps {
  rows: Array<Row<LineItemResource>>
  canReceiveItems: boolean
  onQuickReceive?: (lineItem: LineItemResource) => Promise<void>
  onReceiveAndEdit?: (lineItem: LineItemResource) => void
}

function OrderItemsTableBody ({
  rows,
  canReceiveItems,
  onQuickReceive,
  onReceiveAndEdit
}: Readonly<OrderItemsTableBodyProps>): ReactElement {
  const navigate = useNavigate()

  const handleOrderViewClick = (orderId: string): void => {
    navigate(`/purchasing/orders/${orderId}`)
  }

  return (
    <tbody>
      {rows.map((row: Row<LineItemResource>) => (
        <tr key={row.id}>
          {row.getAllCells().map(cell => (
            <td
              key={cell.id}
              className={cell.column.id}
              onClick={() => {
                if (!row.original.is_self_serve) {
                  handleOrderViewClick(row.original.order?.id ?? '')
                }
              }}
            >
              {cell.getValue() as string}
            </td>
          ))}
          <td className="actions">
            <OrderItemActions
              canReceiveItems={canReceiveItems}
              onQuickReceiveAction={() => {
                void onQuickReceive?.(row.original)
              }}
              onReceiveAndEditAction={() => {
                onReceiveAndEdit?.(row.original)
              }}
            />
          </td>
        </tr>
      ))}
    </tbody>
  )
}

export default OrderItemsTableBody
