import useSWR from 'swr'
import useApi from '../../common/hooks/useApi'
import useAccounts from '../../common/hooks/useAccounts'
import { type Products } from '@amici/myamici-api-client'

interface UseRecentlyPurchased {
  data: Products
  error: any
  isLoading: boolean
}

function useRecentlyPurchased (): UseRecentlyPurchased {
  const { activeAccount } = useAccounts()
  const {
    productsApi: { getPurchased },
    fetcher
  } = useApi()

  const accountId = activeAccount?.accountId ?? ''

  const { data, error, isLoading } = useSWR(
    accountId ? ['recently-purchased-products', accountId] : null,
    async () => await fetcher(getPurchased, { accountId })
  )

  return {
    data: data as Products,
    error,
    isLoading
  }
}

export default useRecentlyPurchased
