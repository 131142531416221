import { type Address } from '@amici/myamici-api-client'

const nl = (strings: TemplateStringsArray, str?: string): string =>
  str ? `${str}${strings?.[1] ?? ''}\n` : ''

export function formatDelimitedAddress (
  delimitedAddress: string | undefined
): string {
  const addressLines =
    delimitedAddress && delimitedAddress.length > 0
      ? delimitedAddress.split(',')
      : []

  return addressLines.reduce(
    (formattedAddress, currentLine) => formattedAddress + nl`${currentLine}`,
    ''
  )
}

export function formatAddress (address?: Address, companyName?: string): string {
  if (!address) {
    return ''
  }

  const {
    fao,
    address_line_1: addressLine1,
    address_line_2: addressLine2,
    address_line_3: addressLine3,
    address_line_4: addressLine4,
    address_line_5: addressLine5,
    town,
    county,
    country,
    postal_code: postalCode
  } = address

  const formattedAddress = (
    nl`${fao},` +
    nl`${companyName}` +
    nl`${addressLine1},` +
    nl`${addressLine2},` +
    nl`${addressLine3},` +
    nl`${addressLine4},` +
    nl`${addressLine5},` +
    nl`${town}` +
    nl`${county}` +
    nl`${country}` +
    nl`${postalCode}`
  ).trim()

  return formattedAddress.endsWith(',')
    ? formattedAddress.slice(0, -1)
    : formattedAddress
}
