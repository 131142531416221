import { type ReactElement, useState } from 'react'
import { Image, type ImageProps } from 'react-bootstrap'
import { type IErrorComponentProps } from 'react-ocl/types'

/**
 * FallbackImage Component
 *
 * This component renders a React Bootstrap image based on the provided props. If the image fails to load
 * (e.g., due to a 404 error), it will handle the error gracefully and show an empty element.
 *
 * Props:
 * - All standard image properties as defined in react-bootstrap `ImageProps`.
 *
 * Usage:
 * ```jsx
 * <FallbackImage src="https://example.com/image.jpg" alt="Description" />
 * ```
 *
 * @param {ImageProps | IErrorComponentProps} props - The properties for the image.
 * @returns {ReactElement} The rendered image element with error handling.
 */
export default function FallbackImage ({
  ...props
}: ImageProps | IErrorComponentProps): ReactElement | null {
  const [error, setError] = useState<boolean>(false)
  const [loaded, setLoaded] = useState<boolean>(false)

  const handleImageErrorEvent = (): void => {
    setError(true)
  }

  const handleImageLoadedEvent = (): void => {
    setLoaded(true)
  }

  if (error || Object.hasOwn(props, 'error')) {
    return null
  }

  return (
    <Image
      onError={handleImageErrorEvent}
      onLoad={handleImageLoadedEvent}
      style={!loaded ? { opacity: 0 } : {}}
      {...props}
    />
  )
}
