import { type ReactElement } from 'react'
import { t } from 'i18next'
import { Alert } from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'
import {
  BsEmojiSmile,
  BsExclamationOctagon,
  BsExclamationTriangle
} from 'react-icons/bs'
import { type Product } from '@amici/myamici-api-client'
import { highlightTypeMap } from '../pages/Basket'
import {
  ChangeType,
  type BasketLineItemChange
} from '../hooks/useBasketItemChanges'
import styles from '../assets/scss/BasketChangeNotifications.module.scss'

function getPackSize (product?: Product): string {
  if (!product) {
    return ''
  }

  const packSize = product.pack_size ?? 0
  const packSizeValue =
    ((product.no_of_units ?? 0) > 1 ? `${product.no_of_units} x ` : '') +
    packSize.toString()
  const packSizeUnit: string = product.pack_size_unit ?? ''

  return t('common.product.pack_size_value', {
    packSizeValue,
    packSizeUnit: t([`units.${packSizeUnit}`, packSizeUnit], {
      count: +packSize
    })
  })
}

function BasketChangeNotifications ({
  changes,
  onNotificationClose,
  onNotificationClick
}: Readonly<{
  changes: BasketLineItemChange[]
  onNotificationClose: (id: string) => void
  onNotificationClick: (target: HTMLElement, id: string) => void
}>): ReactElement | null {
  const { t } = useTranslation()

  if (!changes || changes.length < 1) {
    return null
  }

  return (
    <div className={styles.notifications}>
      {changes.map(change => (
        <Alert
          key={change.id}
          className={styles['change-alert']}
          dismissible
          variant={highlightTypeMap[change.changeType]}
          onClose={() => {
            onNotificationClose(change.id)
          }}
          onClick={event => {
            onNotificationClick(
              event.target as HTMLElement,
              change.item.line_item.id
            )
          }}
        >
          <Alert.Heading className={styles.heading}>
            <span className={styles.icon}>
              {
                {
                  danger: <BsExclamationOctagon />,
                  warning: <BsExclamationTriangle />,
                  success: <BsEmojiSmile />
                }[highlightTypeMap[change.changeType]]
              }
            </span>

            {t(`basket.notification.${change.changeType.toLowerCase()}.title`)}
          </Alert.Heading>

          <div className={styles.body}>
            <p>
              <Trans
                i18nKey={`basket.notification.${change.changeType.toLowerCase()}.message`}
                values={{
                  productName: change.item.line_item?.product.description,
                  oldProductName: change.item.product_snapshot?.description,
                  supplier: change.item.line_item?.product.supplier?.name,
                  oldPrice: change.item.line_item?.price,
                  newPrice: change.item.product_price,
                  currency: change.item.line_item?.currency,
                  packSize: getPackSize(change.item.line_item?.product),
                  oldPackSize: getPackSize(change.item.product_snapshot)
                }}
              />
            </p>

            {(change.changeType === ChangeType.ProductUnavailable ||
              change.changeType === ChangeType.PriceIncrease) && (
              <p>{t('basket.notification.note.message')}</p>
            )}
          </div>
        </Alert>
      ))}
    </div>
  )
}

export default BasketChangeNotifications
