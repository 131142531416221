import { type ReactElement, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {
  ProductStatusEnum,
  type ProductResource
} from '@amici/myamici-api-client'
import classNames from 'classnames'
import { SmilesSvgRenderer } from 'react-ocl/minimal.js'
import useIsMobile from '../../common/hooks/useIsMobile'
import { useEnv } from '../../common/hooks/useEnv'
import FallbackImage from '../../common/components/FallbackImage'
import ProductDetailsPurchasing from './ProductDetailsPurchasing'
import FavouriteButton from '../../favourites/components/FavouriteButton'
import useMinWidthObserver from '../../common/hooks/useMinWidthObserver'
import useProductPrice from '../hooks/useProductPrice'
import styles from '../assets/scss/ProductDetails.module.scss'

const FULL_LAYOUT_MIN_WIDTH_PX = 649

function ProductDetailsSummary ({
  product
}: Readonly<{
  product: ProductResource
}>): ReactElement {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const { REACT_APP_MA3_BASE_URL } = useEnv()
  const summaryRef = useRef(null)
  const fullLayout = useMinWidthObserver(summaryRef, FULL_LAYOUT_MIN_WIDTH_PX)
  const [hasImageLoadingError, setHasImageLoadingError] = useState(false)

  const productDetailUrl = `${
    (REACT_APP_MA3_BASE_URL as string) ?? ''
  }/Product/ProductDetail.aspx?ProductId=${product?.id}`

  const productImageUrl =
    (product?.product_large_image_url || product?.product_image_url) ?? ''

  const noOfUnits: string =
    product?.no_of_units && product.no_of_units > 1
      ? `${product.no_of_units} x `
      : ''
  const packSize: string = product?.pack_size
    ? product.pack_size?.toString()
    : ''
  const packSizeValue: string = noOfUnits + packSize
  const packSizeUnit: string = product?.pack_size_unit
    ? ` ${product.pack_size_unit as unknown as string}`
    : ''

  const productPriceData = useProductPrice(product?.id, null, null, null)

  const cannotBuy =
    !!product?.is_self_serve ||
    product?.status === ProductStatusEnum.INACTIVE ||
    productPriceData.bestPrice == null ||
    productPriceData.bestPrice <= 0

  const showSmiles = hasImageLoadingError && !!product?.smiles

  const isImageVisible = !hasImageLoadingError || showSmiles

  return (
    <div
      className={classNames(styles.summary, {
        [styles.mobile]: !fullLayout || isMobile
      })}
      ref={summaryRef}
    >
      <div className={styles['summary-header']}>
        <h2 className={styles.title}>
          {!product?.is_self_serve ? (
            <Link to={productDetailUrl} target="_blank">
              {product?.description}
            </Link>
          ) : (
            product?.description
          )}
        </h2>

        {!cannotBuy && <FavouriteButton productId={product.id} scale={24} />}
      </div>

      <div className={styles['summary-body']}>
        <div className={styles['left-col']}>
          <div className={styles['product-info']}>
            <Table>
              <tbody>
                <tr>
                  <th>{t('product.card.brand')}</th>
                  <td>{product?.brand}</td>
                </tr>
                <tr>
                  <th>{t('product.card.manufacturer_part_no')}</th>
                  <td>{product?.manufacturer_part_number}</td>
                </tr>
                <tr>
                  <th>{t('product.card.pack_size')}</th>
                  <td>
                    {t('product.card.pack_size_value', {
                      packSizeValue,
                      packSizeUnit: t([`units.${packSizeUnit}`, packSizeUnit], {
                        count: +packSize
                      })
                    })}
                  </td>
                </tr>
              </tbody>
            </Table>

            {product.long_description && (
              <p
                aria-label="long-description"
                className={styles['long-description']}
                dangerouslySetInnerHTML={{ __html: product.long_description }}
              />
            )}
          </div>

          {isImageVisible && (
            <div className={styles['image-wrapper']}>
              {!showSmiles && (
                <FallbackImage
                  key={productImageUrl}
                  src={productImageUrl}
                  alt={product?.description ?? ''}
                  className={styles.img}
                  onError={() => {
                    setHasImageLoadingError(true)
                  }}
                />
              )}
              {showSmiles && (
                <SmilesSvgRenderer
                  width={150}
                  height={150}
                  smiles={product?.smiles ?? ''}
                  ErrorComponent={FallbackImage}
                />
              )}
            </div>
          )}
        </div>

        <div className={styles['right-col']}>
          <ProductDetailsPurchasing product={product} />
        </div>
      </div>
    </div>
  )
}

export default ProductDetailsSummary
