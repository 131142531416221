import useSWR from 'swr'
import useApi from '../../common/hooks/useApi'
import useAccounts from '../../common/hooks/useAccounts'
import format from 'date-fns/format'

interface UseItemsDueTodayHook {
  count?: number
  isLoading: boolean
}

function useItemsDueToday (): UseItemsDueTodayHook {
  const {
    ordersApi: { getOutstandingLineItems },
    fetcher
  } = useApi()
  const { activeAccount } = useAccounts()

  const { data: items, isLoading } = useSWR(
    activeAccount?.accountId
      ? ['items-due-today', activeAccount.accountId]
      : null,
    async () =>
      await fetcher(getOutstandingLineItems, {
        accountId: activeAccount?.accountId,
        size: 1,
        dateFrom: format(new Date(), 'yyyy-MM-dd'),
        dateTo: format(new Date(), 'yyyy-MM-dd')
      })
  )

  return {
    count: items?.totalElements,
    isLoading
  }
}

export default useItemsDueToday
