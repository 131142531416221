import { type FacetFilters } from '../types/facet-filters'

function filtersCount (filters: FacetFilters): number {
  return Array.from(filters.values()).reduce(
    (count, filter) => count + filter.size,
    0
  )
}

export default filtersCount
