import { type ReactElement } from 'react'
import { BsPatchCheck, BsPatchExclamation } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import styles from '../assets/scss/SupplierStatusIndicator.module.scss'
import useProductDetails from '../hooks/useProductDetails'
import { SupplierStatusEnum } from '@amici/myamici-api-client'

interface SupplierStatusIndicatorProps {
  productId: string
  scale?: number
  className?: string
}

function SupplierStatusIndicator ({
  productId,
  scale,
  className
}: Readonly<SupplierStatusIndicatorProps>): ReactElement {
  const { t } = useTranslation()

  const { isLoading, error, data } = useProductDetails(productId)

  const supplierStatus: string | undefined = !(isLoading || error)
    ? data?.supplier?.status
    : SupplierStatusEnum.INACTIVE

  if (supplierStatus === SupplierStatusEnum.INACTIVE) {
    return <></>
  }

  return (
    <div
      title={t(
        `product.card.supplier.status.${
          supplierStatus ?? (SupplierStatusEnum.INACTIVE as string)
        }`
      )}
      className={classNames(
        styles['supplier-status-indicator-icon'],
        className
      )}
      aria-label={t(
        `product.card.supplier.status.${
          supplierStatus ?? (SupplierStatusEnum.INACTIVE as string)
        }`
      )}
    >
      {supplierStatus === SupplierStatusEnum.CAN_BUY ? (
        <BsPatchCheck
          size={scale ?? 16}
          className={classNames(styles['can-buy'], styles.icon)}
        />
      ) : (
        <BsPatchExclamation
          size={scale ?? 16}
          className={classNames(styles['could-buy'], styles.icon)}
        />
      )}
    </div>
  )
}

export default SupplierStatusIndicator
