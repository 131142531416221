import { type HTMLAttributes, type ReactElement } from 'react'
import { Accordion } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import type MaTab from '../models/MaTab'
import styles from '../assets/scss/MaAccordion.module.scss'

interface MaAccordionProps extends HTMLAttributes<HTMLDivElement> {
  tabs: MaTab[]
  activeTab: string
  onSelectTab?: (tabKey: string) => void
}

function MaAccordion ({
  tabs,
  activeTab,
  onSelectTab
}: Readonly<MaAccordionProps>): ReactElement {
  const { t } = useTranslation()

  return (
    <div className={styles['ma-accordion']}>
      <Accordion
        defaultActiveKey={activeTab}
        onSelect={eventKey => {
          if (onSelectTab) onSelectTab(eventKey as string)
        }}
      >
        {tabs.map(tab => (
          <Accordion.Item key={tab.key} eventKey={tab.key}>
            <Accordion.Header>
              <span
                className={classNames({
                  'no-data': !tab.hasData
                })}
              >
                {t(`tabs.tab.title.${tab.key}`)}
              </span>
            </Accordion.Header>
            <Accordion.Body>{tab.component}</Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  )
}

export default MaAccordion
