import type {
  HistoryEvent,
  SpendCategoryHistory
} from '@amici/myamici-api-client'
import useAccounts from '../../common/hooks/useAccounts'
import useApi from '../../common/hooks/useApi'
import useSWR, { type KeyedMutator } from 'swr'

export interface UseSpendCategoryHistoryHook {
  entries: HistoryEvent[] | undefined
  mutate: KeyedMutator<SpendCategoryHistory>
  isLoading: boolean
}

function useSpendCategoryHistory (): UseSpendCategoryHistoryHook {
  const { activeAccount } = useAccounts()
  const {
    spendCategoriesApi: { getSpendCategoryHistory },
    fetcher
  } = useApi()

  const accountId = activeAccount?.accountId ?? ''
  const { data, isLoading, mutate } = useSWR<SpendCategoryHistory, Error>(
    accountId ? ['spend-category-history', accountId] : null,
    async () =>
      await fetcher(getSpendCategoryHistory, {
        accountId,
        size: 0,
        direction: 'desc'
      })
  )
  return {
    entries: data?.content,
    mutate,
    isLoading
  }
}

export default useSpendCategoryHistory
