import { useRef, useState, type ReactElement } from 'react'
import { Offcanvas } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { BsXLg } from 'react-icons/bs'
import classNames from 'classnames'
import { type DateRange } from 'react-day-picker'
import { type OrderRequestSortOption } from '../types/order-request-sort-option'
import MaIconButton from '../../common/components/MaIconButton'
import MaDateRangePicker from '../../common/components/MaDateRangePicker'
import SearchFilters, {
  type SearchFiltersProps
} from '../../purchasing/components/SearchFilters'
import OrderRequestsSortSelect from './OrderRequestsSortSelect'
import styles from '../../common/assets/scss/SearchPage.module.scss'

export interface OrderRequestsOffcanvasFilterProps extends SearchFiltersProps {
  show: boolean
  dateRange?: DateRange
  sort: OrderRequestSortOption
  onHide?: () => void
  onShow?: () => void
  onSortingChange: (value: OrderRequestSortOption) => void
  onDateRangeChange: (range: DateRange | undefined) => void
}

function OrderRequestsOffcanvasFilter ({
  show,
  dateRange,
  sort,
  facets,
  availableFacets,
  enabledFilters,
  expandedCategories,
  canApply,
  categoryNameFormatter,
  onFiltersApply,
  onFiltersClear,
  onFilterChange,
  onShow,
  onHide,
  onSortingChange,
  onDateRangeChange
}: Readonly<OrderRequestsOffcanvasFilterProps>): ReactElement {
  const { t } = useTranslation()

  const [menuFullyVisible, setMenuFullyVisible] = useState(false)
  const ref = useRef<any>(null)
  const datepickerContainer = ref?.current?.dialog

  const handleShow = (): void => {
    setMenuFullyVisible(true)
    onShow?.()
  }

  const handleHide = (): void => {
    setMenuFullyVisible(false)
    onHide?.()
  }

  return (
    <Offcanvas
      ref={ref}
      className={styles['offcanvas-filters']}
      show={show}
      placement="start"
      backdrop={false}
      responsive="md"
      onShow={handleShow}
      onHide={handleHide}
    >
      <Offcanvas.Header className={styles['offcanvas-filters-header']}>
        <h2 className={styles['offcanvas-filters-title']}>
          {t('order_requests.sort_filter.title')}
        </h2>

        <MaIconButton
          onClick={handleHide}
          aria-label={t('common.button.labels.close')}
        >
          <BsXLg size={24} />
        </MaIconButton>
      </Offcanvas.Header>

      <Offcanvas.Body>
        <div className={classNames(styles.sorting, styles.mobile)}>
          <div className={classNames(styles.sorting, styles.mobile)}>
            {menuFullyVisible && (
              <MaDateRangePicker
                value={dateRange}
                onDateRangeChange={onDateRangeChange}
                placeholder={t('order.filters.date_range.placeholder')}
                container={datepickerContainer}
              />
            )}

            <OrderRequestsSortSelect value={sort} onChange={onSortingChange} />
          </div>

          <SearchFilters
            facets={facets}
            availableFacets={availableFacets}
            enabledFilters={enabledFilters}
            expandedCategories={expandedCategories}
            canApply={canApply}
            categoryNameFormatter={categoryNameFormatter}
            onFiltersApply={onFiltersApply}
            onFiltersClear={onFiltersClear}
            onFilterChange={onFilterChange}
          />
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default OrderRequestsOffcanvasFilter
