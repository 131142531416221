import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import ProductCard from './ProductCard'
import MaProductCarousel from '../../common/components/MaProductCarousel'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import useFrequentlyPurchased from '../hooks/useFrequentlyPurchased'
import styles from '../assets/scss/PurchasedProducts.module.scss'

function FrequentlyPurchasedProducts (): ReactElement {
  const { t } = useTranslation()
  const { data, isLoading } = useFrequentlyPurchased()

  const products = data?.content ?? []

  return (
    <div>
      <h4>{t('product.frequently-purchased.title')}</h4>

      {isLoading && <LoadingSpinner />}

      {!isLoading && products.length < 1 && (
        <p className="text-center">{t('product.frequently-purchased.empty')}</p>
      )}

      {products.length > 0 && (
        <MaProductCarousel>
          {products.map(product => (
            <div key={product.id} className={styles['purchased-product']}>
              <ProductCard
                productSummary={product}
                variant="alternative"
                currentPage={0}
              />
            </div>
          ))}
        </MaProductCarousel>
      )}
    </div>
  )
}

export default FrequentlyPurchasedProducts
