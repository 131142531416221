import useSWR from 'swr'
import { type ProductResource } from '@amici/myamici-api-client'
import useApi from '../../common/hooks/useApi'
import useAccounts from '../../common/hooks/useAccounts'

interface UseProductDetailsHook {
  isLoading: boolean
  error: any
  data?: ProductResource
}

function useProductDetails (productId?: string): UseProductDetailsHook {
  const { activeAccount } = useAccounts()
  const { productsApi, fetcher } = useApi()
  const { isLoading, error, data } = useSWR(
    productId && activeAccount?.accountId
      ? ['product', productId, activeAccount?.accountId]
      : null,
    async () =>
      await fetcher(productsApi.getProduct, {
        productId: productId ?? '',
        accountId: activeAccount?.accountId ?? ''
      })
  )

  return {
    isLoading,
    error,
    data
  }
}

export default useProductDetails
