import * as yup from 'yup'

export const schema = yup
  .object({
    coshh: yup
      .object({
        has_coshh: yup.boolean().nullable(),
        reference: yup
          .string()
          .max(50, 'validation.error.coshh_and_licence.max_length.reference')
          .when('has_coshh', {
            is: true,
            then: schema =>
              schema.required(
                'validation.error.coshh_and_licence.required.reference'
              ),
            otherwise: schema => schema.optional()
          })
          .nullable(),
        expiry_date: yup.string().nullable()
      })
      .notRequired(),
    licence: yup
      .object({
        has_licence: yup.boolean().required(),
        reference: yup
          .string()
          .max(50, 'validation.error.coshh_and_licence.max_length.reference')
          .when('has_licence', {
            is: true,
            then: schema =>
              schema.required(
                'validation.error.coshh_and_licence.required.reference'
              ),
            otherwise: schema => schema.optional()
          })
          .nullable(),
        expiry_date: yup.string().nullable()
      })
      .notRequired(),
    note: yup
      .string()
      .max(400, 'validation.error.coshh_and_licence.max_length.note')
      .notRequired()
  })
  .required()
