import { Badge } from 'react-bootstrap'
import { type ReactElement } from 'react'
import { Trans } from 'react-i18next'
import { type OrderRequestAllOfOrderStatusEnum } from '@amici/myamici-api-client'
import styles from '../assets/scss/OrderRequestStatusBadge.module.scss'

function OrderRequestStatusBadge ({
  status
}: Readonly<{
  status: OrderRequestAllOfOrderStatusEnum
}>): ReactElement {
  const statusLowerCase = status?.toLowerCase()

  return (
    <Badge
      className={styles.badge}
      pill={true}
      bg={statusLowerCase}
      text={statusLowerCase}
    >
      <Trans i18nKey={`order_request.status.${statusLowerCase}`} />
    </Badge>
  )
}

export default OrderRequestStatusBadge
