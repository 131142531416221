import { type ReactElement } from 'react'
import styles from '../assets/scss/Home.module.scss'
import { Link } from 'react-router-dom'
import { t } from 'i18next'
import { Card, CardBody, CardFooter, CardHeader } from 'react-bootstrap'

function AssetContactCard (): ReactElement {
  return (
    <Card className={styles['info-card']}>
      <CardHeader>
        <h4>{t('asset.contact-panel.title')}</h4>
      </CardHeader>
      <CardBody>
        <p className="fw-bold">{t('asset.contact-panel.sub-title')}</p>
        <p>{t('asset.contact-panel.text')}</p>
      </CardBody>
      <CardFooter>
        <Link
          to="mailto:assetsupport@amiciprocurement.com?subject=Amici asset support request"
          className="btn btn-primary"
        >
          {t('asset.contact-panel.contact-us')}
        </Link>
      </CardFooter>
    </Card>
  )
}

export default AssetContactCard
