import { type RouteObject } from 'react-router-dom'
import Home from './pages/Home'

const homeRoutes: RouteObject[] = [
  {
    index: true,
    element: <Home />
  },
  {
    index: true,
    path: 'home',
    element: <Home />
  }
]

export default homeRoutes
