import { type RouteObject } from 'react-router-dom'
import UserAccount from '../user/pages/UserAccount'

const userAccountRoutes: RouteObject[] = [
  {
    path: 'user-account',
    children: [
      {
        path: '',
        element: <UserAccount />
      }
    ]
  }
]

export default userAccountRoutes
