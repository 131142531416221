import { type HTMLAttributes, type ReactElement } from 'react'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { BsExclamationCircle } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import classNames from 'classnames'
import { OutstandingOrderItemsColumnId as ColumnId } from '../types/outstanding-order-items-column-id'
import FallbackImage from '../../common/components/FallbackImage'
import MaCard from '../../common/components/MaCard'
import MaTooltip from '../../common/components/MaTooltip'
import styles from '../assets/scss/OrderItemCard.module.scss'

export interface CardItem {
  orderId: string
  productId: string
  [ColumnId.DESCRIPTION]: string
  [ColumnId.REFERENCE]: string
  [ColumnId.PART_NUMBER]: string
  [ColumnId.SUPPLIER]: string
  [ColumnId.QUANTITY]: string | number
  [ColumnId.ESTIMATED_DELIVERY_DATE]: string
  [ColumnId.ISSUES]?: string
  imageUrl?: string
  isSelfServe: boolean
}

interface OrderItemCardProps extends HTMLAttributes<HTMLDivElement> {
  item: CardItem
  variant?: 'full' | 'compact'
  actions?: ReactElement | ReactElement[]
}

function OrderItemCard ({
  item,
  variant,
  actions,
  className
}: Readonly<OrderItemCardProps>): ReactElement {
  const { t } = useTranslation()

  const formattedDueDate = item[ColumnId.ESTIMATED_DELIVERY_DATE]
    ? format(new Date(item[ColumnId.ESTIMATED_DELIVERY_DATE]), 'do LLLL yyyy')
    : '-'

  const orderUrl = `/purchasing/orders/${item.orderId}`

  return (
    <MaCard
      className={classNames(
        styles['order-item-card'],
        { [styles.compact]: variant === 'compact' },
        className
      )}
    >
      <MaCard.Header className={styles.header}>
        {!item?.isSelfServe ? (
          <Link to={orderUrl} aria-label="order-link">
            {item[ColumnId.DESCRIPTION]}
          </Link>
        ) : (
          <span>{item[ColumnId.DESCRIPTION]}</span>
        )}
      </MaCard.Header>

      <MaCard.Body>
        <div className={styles.content}>
          <div className={styles.info}>
            <Table className={styles.table}>
              <tbody>
                <tr>
                  <th>{t('order.item.card.po_ref')}</th>
                  <td>{item[ColumnId.REFERENCE]}</td>
                </tr>
                <tr>
                  <th>{t('order.item.card.due')}</th>
                  <td className="text-nowrap" title={formattedDueDate}>
                    {formattedDueDate}{' '}
                    {!!item[ColumnId.ISSUES] &&
                      item[ColumnId.ISSUES].length > 0 && (
                        <MaTooltip
                          text={
                            <div className={styles['tooltip-text']}>
                              {item[ColumnId.ISSUES]}
                            </div>
                          }
                          className={styles.tooltip}
                        >
                          <BsExclamationCircle />
                        </MaTooltip>
                      )}
                  </td>
                </tr>
                <tr>
                  <th>{t('order.item.card.outstanding')}</th>
                  <td>{item[ColumnId.QUANTITY]}</td>
                </tr>
              </tbody>
            </Table>

            <Table className={styles.table}>
              <tbody>
                <tr>
                  <th colSpan={2} className={styles.supplier}>
                    {item[ColumnId.SUPPLIER]}
                  </th>
                </tr>
                <tr className={styles['part-number']}>
                  <th>{t('order.item.card.part_number')}</th>
                  <td>{item[ColumnId.PART_NUMBER]}</td>
                </tr>
              </tbody>
            </Table>
          </div>

          {item.imageUrl && (
            <FallbackImage
              className={styles.img}
              src={item.imageUrl}
              loading="lazy"
              thumbnail
              data-testid="card-image"
            />
          )}
        </div>
      </MaCard.Body>

      <MaCard.Footer className={styles.footer}>{actions}</MaCard.Footer>
    </MaCard>
  )
}

export default OrderItemCard
