import { type HTMLAttributes, type ReactElement } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { type TFunction } from 'i18next'
import { type ProductSpecification } from '@amici/myamici-api-client/models'
import useProductSpecifications from '../hooks/useProductSpecifications'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import styles from '../assets/scss/ProductDetails.module.scss'

interface ProductDetailsTabSpecificationsProps
  extends HTMLAttributes<HTMLDivElement> {
  productId: string | undefined
}

function localiseSpecValue (
  t: TFunction<any, any>,
  spec: ProductSpecification
): string {
  if (!spec.value) return '-'
  switch (spec.format) {
    case 'boolean': {
      return t(`product.details.tabs.specifications.value.${spec.value}`)
    }
    case 'number':
    case 'percentage': {
      return t(`product.details.tabs.specifications.value.${spec.format}`, {
        value: spec.value
      })
    }
    default: {
      if (!isNaN(+spec.value)) {
        return spec.value
      }
      return t(
        `product.details.tabs.specifications.${spec.name}.${spec.value}`,
        spec.value
      )
    }
  }
}

function ProductDetailsTabSpecifications ({
  productId
}: Readonly<ProductDetailsTabSpecificationsProps>): ReactElement {
  const { t } = useTranslation()
  const { isLoading, error, data: specs } = useProductSpecifications(productId)

  const rowCount = 8
  const columns: ProductSpecification[][] = (() => {
    const cols = []
    if (specs?.content) {
      for (let i = 0; i < specs.content.length; i += rowCount) {
        cols.push(specs.content.slice(i, i + rowCount))
      }
    }
    return cols
  })()

  if (isLoading || error) {
    return <LoadingSpinner />
  }

  return (
    <div className={styles.specifications}>
      {columns.map((column: ProductSpecification[], index: number) => (
        <Col className={styles.column} key={`column${index}`}>
          {column.map((spec: ProductSpecification) => (
            <Row className={styles.specification} key={spec.name}>
              <Col className={styles.name}>
                {t(
                  `product.details.tabs.specifications.${spec.name}`,
                  spec.name
                )}
              </Col>
              <Col className={styles.value}>{localiseSpecValue(t, spec)}</Col>
            </Row>
          ))}
        </Col>
      ))}
    </div>
  )
}

export default ProductDetailsTabSpecifications
