import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import MaHoverCard from '../../common/components/MaHoverCard'
import useBasketSettings from '../hooks/useBasketSettings'
import useBasketPreview from '../hooks/useBasketPreview'
import useBasketCount from '../hooks/useBasketCount'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import BasketIcon from './BasketIcon'
import BasketPreviewItem from './BasketPreviewItem'
import styles from '../assets/scss/BasketPreview.module.scss'

function BasketPreview (): ReactElement {
  const { t } = useTranslation()
  const { basketCount } = useBasketCount()
  const { basketUrl, target } = useBasketSettings()
  const { items, isLoading } = useBasketPreview()

  const isEmptyBasket = !isLoading && items.length < 1
  const hasItems = !isLoading && items.length > 0

  return (
    <MaHoverCard
      trigger={
        <span>
          <BasketIcon />
        </span>
      }
    >
      <div className={styles['basket-preview']} data-testid="basket-preview">
        {isLoading && <LoadingSpinner />}

        {isEmptyBasket && (
          <p className={styles['empty-basket-txt']}>
            {t('basket_preview.empty')}
          </p>
        )}

        {hasItems && (
          <>
            <h6 className={styles.heading}>
              {t('basket_preview.heading', {
                count: items.length,
                total: basketCount
              })}
            </h6>

            {items.map(({ line_item: item }) => (
              <BasketPreviewItem key={item?.id} item={item} />
            ))}
          </>
        )}

        {!isLoading && (
          <Link
            to={hasItems ? basketUrl : '/purchasing/products'}
            target={hasItems ? target : '_self'}
            className="btn btn-primary rounded"
          >
            {hasItems && t('basket_preview.view_basket')}
            {isEmptyBasket && t('basket.empty_basket.browse_products')}
          </Link>
        )}
      </div>
    </MaHoverCard>
  )
}

export default BasketPreview
