import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { type Task } from '@amici/myamici-api-client'
import TaskCard from './TaskCard'
import styles from '../assets/scss/TasksGrid.module.scss'

function TasksGrid ({
  tasks,
  totalTasks
}: Readonly<{
  tasks: Task[]
  totalTasks: number
}>): ReactElement {
  const { t } = useTranslation()

  if (!tasks || totalTasks < 1) {
    return (
      <>
        <p className="text-center">{t('tasks.list.no_tasks.part_1')}</p>
        <p className="text-center">{t('tasks.list.no_tasks.part_2')}</p>
      </>
    )
  }

  if (tasks.length > 0) {
    return (
      <div className={styles.tasks}>
        {tasks.map((task: Task) => (
          <TaskCard key={task.id} task={task} />
        ))}
      </div>
    )
  } else {
    return <p className="text-center">{t('tasks.list.nothing_found')}</p>
  }
}

export default TasksGrid
