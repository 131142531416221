import { type ColumnSort } from '@tanstack/react-table'
import { OutstandingOrderItemsColumnId } from './outstanding-order-items-column-id'

const sortingOptions: Array<{ name: string; value: ColumnSort }> = [
  {
    name: 'outstanding_order_items.sort.by_due_date.asc',
    value: {
      id: OutstandingOrderItemsColumnId.ESTIMATED_DELIVERY_DATE,
      desc: false
    }
  },
  {
    name: 'outstanding_order_items.sort.by_po_number.asc',
    value: {
      id: OutstandingOrderItemsColumnId.REFERENCE,
      desc: false
    }
  },
  {
    name: 'outstanding_order_items.sort.by_supplier.asc',
    value: {
      id: OutstandingOrderItemsColumnId.SUPPLIER,
      desc: false
    }
  }
]

export default sortingOptions
