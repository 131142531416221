import { type ChangeEvent, type FormEvent, type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { type FormProps } from 'react-bootstrap'
import { ProductSearchType } from '../types/product-search-type'
import useIsMobile from '../../common/hooks/useIsMobile'
import SearchForm from './SearchForm'

function useInvalidSearchFeedback (
  type: ProductSearchType,
  term: string
): string | null {
  const { t } = useTranslation()
  switch (type) {
    case ProductSearchType.CASNumber: {
      let casNumber: string = term.replace(/[^0-9A-Za-z]/g, '')
      if (isNaN(+casNumber)) {
        return t('product.search.feedback.casNumber.notNumeric')
      }
      if (casNumber.length > 0 && casNumber.length < 5) {
        return t('product.search.feedback.casNumber.tooShort')
      }

      const checkDigit = casNumber.slice(-1)
      casNumber = casNumber.substring(0, casNumber.length - 1)
      let result = 0
      for (let i = 1; i <= casNumber.length; i++) {
        result += +casNumber[casNumber.length - i] * i
      }
      if (result % 10 !== +checkDigit) {
        return t('product.search.feedback.casNumber.invalid')
      }
      return null
    }
    default: {
      return null
    }
  }
}

interface ProductSearchFormProps extends FormProps {
  search: string
  type: ProductSearchType
  showType?: boolean
  onSubmit: (event: FormEvent<HTMLFormElement>) => void
  onSearchChange: (event: ChangeEvent<HTMLInputElement>) => void
  onTypeChange?: (searchType: ProductSearchType) => void
}

function ProductSearchForm ({
  search,
  type,
  showType = true,
  onSubmit,
  onSearchChange,
  onTypeChange,
  ...props
}: Readonly<ProductSearchFormProps>): ReactElement {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const feedback = useInvalidSearchFeedback(type, search)

  const placeholder = isMobile
    ? t('product.search.placeholder')
    : t(`product.search.placeholder.${type}`)

  const searchTypeOptions = [
    {
      value: ProductSearchType.Keyword,
      label: t('product.search.type.keyword')
    },
    {
      value: ProductSearchType.CASNumber,
      label: t('product.search.type.cas_number')
    },
    {
      value: ProductSearchType.PartNumber,
      label: t('product.search.type.part_number')
    },
    {
      value: ProductSearchType.Chemical,
      label: t('product.search.type.chemical')
    },
    {
      value: ProductSearchType.Biological,
      label: t('product.search.type.biological')
    }
  ]

  return (
    <SearchForm<ProductSearchType>
      search={search}
      type={type}
      typeOptions={showType ? searchTypeOptions : []}
      placeholder={placeholder}
      invalidFeedback={feedback}
      onSubmit={onSubmit}
      onTypeChange={onTypeChange}
      onSearchChange={onSearchChange}
      {...props}
    />
  )
}

export default ProductSearchForm
