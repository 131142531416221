import { type ReactElement } from 'react'
import { Container, Navbar } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import { ReactComponent as AmiciLogo } from '../assets/images/amici-logo.svg'
import { useEnv } from '../hooks/useEnv'
import HeaderNavigation from './HeaderNavigation'
import UserToolbar, { type UserToolbarProps } from './UserToolbar'
import useIsMobile from '../hooks/useIsMobile'

function MyAmiciHeader (props: Readonly<UserToolbarProps>): ReactElement {
  const env = useEnv()
  const isMobile = useIsMobile()

  let environmentName = ''
  if (env.REACT_APP_ENVIRONMENT_NAME !== 'Production') {
    environmentName = env.REACT_APP_ENVIRONMENT_NAME as string
  }

  const { pathname, search } = useLocation()
  const homePath = pathname === '/home' ? `${pathname}${search}` : '/home'

  return (
    <Navbar as="header" sticky="top" expand="md" className="p-0">
      <Container
        fluid
        className="p-0 d-flex flex-row flex-nowrap justify-content-between"
      >
        <Navbar.Brand as={Link} to={homePath} className="p-0">
          <AmiciLogo />
        </Navbar.Brand>

        {!isMobile && <HeaderNavigation />}

        {environmentName && environmentName !== 'prod' && (
          <span style={{ fontSize: '0.5rem' }}>{environmentName}</span>
        )}

        <UserToolbar {...props} />
      </Container>
    </Navbar>
  )
}

export default MyAmiciHeader
