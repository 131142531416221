import { type ReactElement } from 'react'
import styles from '../assets/scss/ProfileAvatar.module.scss'

interface ProfileAvatarProps {
  fullName: string
}

function ProfileAvatar ({
  fullName
}: Readonly<ProfileAvatarProps>): ReactElement | null {
  const names = fullName.split(' ')

  let initials = ''

  if (fullName === '' || names.length === 0) {
    return null
  } else if (names.length === 2) {
    initials = `${names[0][0]}${names[1][0]}`
  } else {
    initials = names[0][0]
  }
  return <span className={styles['profile-avatar']}>{initials}</span>
}

export default ProfileAvatar
