import { type ReactElement } from 'react'
import { type ProductResource } from '@amici/myamici-api-client'
import { Link } from 'react-router-dom'
import MaCard from '../../common/components/MaCard'
import FavouriteButton from './FavouriteButton'
import styles from '../assets/scss/FavouriteCard.module.scss'

interface FavouriteCardProps {
  product: ProductResource
}

function FavourteCard ({ product }: Readonly<FavouriteCardProps>): ReactElement {
  return (
    <MaCard className={styles['favourite-card']}>
      <MaCard.Header className={styles.header}>
        <Link to={`/purchasing/products/${product.id}`}>
          {product.description}
        </Link>

        <FavouriteButton productId={product.id} />
      </MaCard.Header>

      <MaCard.Body className={styles.body}>
        {product.supplier?.name}
      </MaCard.Body>

      <MaCard.Footer className={styles.footer}>
        {product.part_number}
      </MaCard.Footer>
    </MaCard>
  )
}

export default FavourteCard
