import type { ReactElement } from 'react'
import { Table } from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'
import type {
  OrderRequestLineItem,
  SupplierResource
} from '@amici/myamici-api-client'
import useOrderRequestSummary from '../hooks/useOrderRequestSummary'
import MaActionIcon from '../../common/components/MaActionIcon'
import { BsPencil, BsXLg } from 'react-icons/bs'
import styles from '../assets/scss/OrderRequestSpendTable.module.scss'
import { Link } from 'react-router-dom'

function TableHead ({
  includeActions
}: Readonly<{
  includeActions: boolean
}>): ReactElement {
  return (
    <thead>
      <tr>
        <th scope="col">
          <Trans i18nKey="order_request.spend.table.column.supplier" />
        </th>
        <th scope="col">
          <Trans i18nKey="order_request.spend.table.column.product" />
        </th>
        <th scope="col">
          <Trans i18nKey="order_request.spend.table.column.pack_size" />
        </th>
        <th scope="col" className={styles.quantity}>
          <Trans i18nKey="order_request.spend.table.column.quantity" />
        </th>
        <th scope="col" className={styles.price}>
          <Trans i18nKey="order_request.spend.table.column.price" />
        </th>
        <th scope="col" className={styles.subtotal}>
          <Trans i18nKey="order_request.spend.table.column.subtotal" />
        </th>
        {includeActions && (
          <th scope="col" className={styles.actions}>
            <Trans i18nKey="order_request.spend.table.column.actions" />
          </th>
        )}
      </tr>
    </thead>
  )
}

function TableFooter ({
  totalItemCount,
  total,
  currency,
  includeActions
}: Readonly<{
  totalItemCount: number
  total: number
  currency: string
  includeActions: boolean
}>): ReactElement {
  return (
    <tfoot>
      <tr>
        <td />
        <td />
        <td>
          <Trans i18nKey="order_request.spend.table.summary.totals" />
        </td>
        <td className={styles.items}>
          <Trans
            i18nKey="order_request.spend.table.summary.items"
            values={{
              count: totalItemCount
            }}
          />
        </td>
        <td colSpan={2} className={styles.total}>
          <Trans
            i18nKey="common.price"
            values={{
              price: total,
              currency
            }}
          />
        </td>
        {includeActions && <td />}
      </tr>
    </tfoot>
  )
}

function LineItemRow ({
  orderRequestLineItem,
  onEdit,
  onRemove,
  includeActions
}: Readonly<{
  orderRequestLineItem: OrderRequestLineItem
  onEdit: (orderRequestLineItem: OrderRequestLineItem) => void
  onRemove: (orderRequestLineItem: OrderRequestLineItem) => void
  includeActions: boolean
}>): ReactElement {
  const { t } = useTranslation()
  const lineItem = orderRequestLineItem.line_item
  const productPrice = orderRequestLineItem.product_price
  return (
    <tr key={lineItem.id}>
      <td className={styles.supplier}>{lineItem.product.supplier?.name}</td>
      <td className={styles.product}>
        <Link to={`/purchasing/products/${lineItem.product.id}`}>
          {lineItem.product.description}
        </Link>
      </td>
      <td className={styles['pack-size']}>
        <Trans
          i18nKey="common.product.pack_size_value"
          values={{
            count: lineItem.product.no_of_units ?? 1,
            packSizeValue: lineItem.product.pack_size,
            packSizeUnit: t(`units.${lineItem.product.pack_size_unit}`, {
              count: lineItem.product.pack_size
            })
          }}
        />
      </td>
      <td className={styles.quantity}>{lineItem.quantity}</td>
      <td className={styles.price}>
        <Trans
          i18nKey="common.price"
          values={{
            price: productPrice,
            currency: lineItem.currency
          }}
        />
      </td>
      <td className={styles.subtotal}>
        <Trans
          i18nKey="common.price"
          values={{
            price: lineItem.quantity * productPrice,
            currency: lineItem.currency
          }}
        />
      </td>
      {includeActions && (
        <td className={styles.actions}>
          <div>
            <MaActionIcon
              title={t('order_request.action.line_item.edit')}
              onClick={() => {
                onEdit(orderRequestLineItem)
              }}
            >
              <BsPencil size={16} />
            </MaActionIcon>
            <MaActionIcon
              className={styles.remove}
              title={t('order_request.action.line_item.remove')}
              onClick={() => {
                onRemove(orderRequestLineItem)
              }}
            >
              <BsXLg size={16} />
            </MaActionIcon>
          </div>
        </td>
      )}
    </tr>
  )
}

function SupplierRows ({
  supplier,
  supplierItems,
  minOrderCharge,
  deliveryCharge,
  currency,
  onEdit,
  onRemove,
  includeActions
}: Readonly<{
  supplier: SupplierResource
  supplierItems: OrderRequestLineItem[]
  minOrderCharge: number
  deliveryCharge: number
  currency: string
  onEdit: (orderRequestLineItem: OrderRequestLineItem) => void
  onRemove: (orderRequestLineItem: OrderRequestLineItem) => void
  includeActions: boolean
}>): ReactElement[] {
  const supplierRows = supplierItems.map(orderRequestLineItem => (
    <LineItemRow
      key={orderRequestLineItem.line_item.id}
      orderRequestLineItem={orderRequestLineItem}
      onEdit={onEdit}
      onRemove={onRemove}
      includeActions={includeActions}
    />
  ))
  if (minOrderCharge > 0) {
    const price = (
      <Trans
        i18nKey="common.price"
        values={{
          price: minOrderCharge,
          currency
        }}
      />
    )
    supplierRows.push(
      <tr key={`${supplier.id}-min_order_charge`}>
        <td>{supplier.name}</td>
        <td>
          <Trans i18nKey="order_request.spend.min_order_charge" />
        </td>
        <td>-</td>
        <td className={styles.quantity}>-</td>
        <td className={styles.price}>{price}</td>
        <td className={styles.subtotal}>{price}</td>
        {includeActions && <td />}
      </tr>
    )
  }
  if (deliveryCharge > 0) {
    const price = (
      <Trans
        i18nKey="common.price"
        values={{
          price: deliveryCharge,
          currency
        }}
      />
    )
    supplierRows.push(
      <tr key={`${supplier.id}-est_delivery`}>
        <td>{supplier.name}</td>
        <td>
          <Trans i18nKey="order_request.spend.est_delivery" />
        </td>
        <td>-</td>
        <td className={styles.quantity}>-</td>
        <td className={styles.price}>{price}</td>
        <td className={styles.subtotal}>{price}</td>
        {includeActions && <td />}
      </tr>
    )
  }
  return supplierRows
}

function OrderRequestSpendTable ({
  orderRequestLineItems,
  onEdit,
  onRemove,
  includeActions
}: Readonly<{
  orderRequestLineItems: OrderRequestLineItem[]
  onEdit: (orderRequestLineItem: OrderRequestLineItem) => void
  onRemove: (orderRequestLineItem: OrderRequestLineItem) => void
  includeActions: boolean
}>): ReactElement {
  const currency = orderRequestLineItems[0]?.line_item.currency ?? 'GBP'
  const {
    suppliers,
    getSupplierItems,
    getTotal,
    getTotalItemCount,
    getSupplierMinOrderCharge,
    getSupplierDeliveryCharge
  } = useOrderRequestSummary(orderRequestLineItems)
  return (
    <div className={styles['order-request-spend-table']}>
      <Table bordered>
        <TableHead includeActions={includeActions} />
        <tbody>
          {suppliers.map(supplier => (
            <SupplierRows
              key={supplier.id}
              supplier={supplier}
              supplierItems={getSupplierItems(supplier, currency)}
              minOrderCharge={getSupplierMinOrderCharge(supplier, currency)}
              deliveryCharge={getSupplierDeliveryCharge(supplier, currency)}
              currency={currency}
              onEdit={onEdit}
              onRemove={onRemove}
              includeActions={includeActions}
            />
          ))}
        </tbody>
        <TableFooter
          totalItemCount={getTotalItemCount(currency)}
          total={getTotal(currency)}
          currency={currency}
          includeActions={includeActions}
        />
      </Table>
    </div>
  )
}

export default OrderRequestSpendTable
