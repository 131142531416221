import useApi from '../../common/hooks/useApi'
import useSWR, { type KeyedMutator } from 'swr'
import useAccounts from '../../common/hooks/useAccounts'

interface HookBasketCount {
  basketCount: number | undefined
  mutate: KeyedMutator<number | undefined>
}

function useBasketCount (): HookBasketCount {
  const {
    basketApi: { getBasketCount },
    fetcher
  } = useApi()
  const { activeAccount } = useAccounts()

  const accountId = activeAccount?.accountId ?? ''

  const { data: basketCount, mutate } = useSWR<number | undefined, Error>(
    activeAccount?.accountId ? ['basket-count', accountId] : null,
    async () => await fetcher(getBasketCount, { accountId })
  )

  return { basketCount, mutate }
}

export default useBasketCount
