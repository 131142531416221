import { type ReactElement, useId } from 'react'
import { type BasketLineItem, type Supplier } from '@amici/myamici-api-client'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import useOrderRequestSummary from '../../order-requests/hooks/useOrderRequestSummary'
import MaPanel from '../../common/components/MaPanel'
import styles from '../assets/scss/OrderRequestSummary.module.scss'
import { BsExclamationTriangle } from 'react-icons/bs'
import MaTooltip from '../../common/components/MaTooltip'

interface OrderRequestSummaryProps {
  selectedItems: BasketLineItem[]
  onCreateRequest: (lineItems: BasketLineItem[]) => void
}

function MinimumOrderThresholdWarning ({
  supplier
}: Readonly<{
  supplier: Supplier
}>): ReactElement {
  const { t } = useTranslation()
  const message = t('basket.request_summary.minimum_order_threshold.warning', {
    threshold: supplier.min_order_threshold,
    currency: supplier.currency
  })
  return (
    <MaTooltip text={message} trigger={['hover', 'click']}>
      <BsExclamationTriangle aria-label={message} className={styles.warning} />
    </MaTooltip>
  )
}

function OrderRequestSummarySupplierSection ({
  selectedItems,
  supplier
}: Readonly<{
  selectedItems: BasketLineItem[]
  supplier: Supplier
}>): ReactElement {
  const { t } = useTranslation()
  const {
    getSupplierCurrencies,
    getSupplierTotal,
    getSupplierItems,
    getSupplierSurcharges
  } = useOrderRequestSummary(selectedItems)
  const id = useId()

  let showMinimumOrderThresholdWarning = false
  if (supplier.currency) {
    const supplierCurrencyTotal = getSupplierTotal(supplier, supplier.currency)
    showMinimumOrderThresholdWarning =
      !!supplierCurrencyTotal &&
      supplierCurrencyTotal < (supplier.min_order_threshold ?? 0)
  }

  return (
    <section aria-labelledby={id} className={styles.supplier}>
      <div className={styles['supplier-header']}>
        <h6 id={id}>{supplier.name}</h6>
        {showMinimumOrderThresholdWarning && (
          <MinimumOrderThresholdWarning supplier={supplier} />
        )}
      </div>

      {getSupplierCurrencies(supplier).map(currency => (
        <p key={`${supplier.id}-${currency}-items`}>
          <span>
            {t('basket.request_summary.item', {
              count: getSupplierItems(supplier, currency).length
            })}
          </span>

          <span>
            {t('common.price', {
              price: getSupplierTotal(supplier, currency),
              currency
            })}
          </span>
        </p>
      ))}

      {getSupplierCurrencies(supplier).map((currency, index) => (
        <p key={`${supplier.id}-${currency}-delivery`}>
          <span>
            {index === 0 && t('basket.request_summary.delivery_charge')}
          </span>

          <span>
            {getSupplierSurcharges(supplier, currency) < 0
              ? t('basket.request_summary.delivery_charge.unknown')
              : getSupplierSurcharges(supplier, currency) === 0
                ? t('basket.request_summary.delivery_charge.free')
                : t('common.price', {
                    price: getSupplierSurcharges(supplier, currency),
                    currency
                  })}
          </span>
        </p>
      ))}
    </section>
  )
}

function OrderRequestSummary ({
  selectedItems,
  onCreateRequest
}: Readonly<OrderRequestSummaryProps>): ReactElement {
  const { t } = useTranslation()

  const noSelectedItems = selectedItems.length < 1

  const { suppliers, currencies, getTotal } =
    useOrderRequestSummary(selectedItems)

  return (
    <MaPanel className={styles['order-request']}>
      <MaPanel.Header
        text={t('basket.request_summary.title')}
        className={styles.header}
      />

      <MaPanel.Body className={styles.body}>
        {noSelectedItems && (
          <p>{t('basket.request_summary.no_selected_items')}</p>
        )}

        {suppliers.map(supplier => (
          <OrderRequestSummarySupplierSection
            key={supplier.id}
            selectedItems={selectedItems}
            supplier={supplier}
          />
        ))}
      </MaPanel.Body>

      <MaPanel.Footer className={styles.footer}>
        <div className={styles.total}>
          {currencies.map((currency, index) => (
            <p key={`${currency}-total`}>
              <span>{index === 0 && t('basket.request_summary.total')}</span>

              <span>
                {t('common.price', { price: getTotal(currency), currency })}
              </span>
            </p>
          ))}
        </div>

        <Button
          variant="primary"
          className={classNames('rounded', styles.create)}
          disabled={noSelectedItems}
          onClick={() => {
            onCreateRequest(selectedItems)
          }}
        >
          {t('basket.request_summary.create_request')}
        </Button>
      </MaPanel.Footer>
    </MaPanel>
  )
}

export default OrderRequestSummary
