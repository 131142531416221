import i18n from 'i18next'
import { Table } from 'react-bootstrap'
import useOrderHistory from '../hooks/useOrderHistory'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import type {
  HistoryEvent,
  OrderLineItemResource,
  OrderResource
} from '@amici/myamici-api-client'
import type { ReactElement } from 'react'
import styles from '../assets/scss/OrderHistory.module.scss'
import { Trans, useTranslation } from 'react-i18next'

interface OrderHistoryProps {
  order: OrderResource
  lineItems?: OrderLineItemResource[]
}

function HistoryEventMessages ({
  order,
  lineItems,
  historyEvent
}: Readonly<{
  order: OrderResource
  lineItems?: OrderLineItemResource[]
  historyEvent: HistoryEvent
}>): ReactElement {
  const { t } = useTranslation()
  const messages = []

  if (i18n.exists('order.history.event.' + historyEvent.event)) {
    messages.push(t('order.history.event.' + historyEvent.event))
  }

  if (historyEvent.event === 'order_changed') {
    if (historyEvent.changes?.carriage_charge) {
      messages.push(
        t('order.history.event.order_changed.carriage_charge', {
          carriage_charge: historyEvent.changes?.carriage_charge.new_value,
          currency: order.currency
        })
      )
    }

    if (historyEvent.changes?.order_charge) {
      messages.push(
        t('order.history.event.order_changed.order_charge', {
          order_charge: historyEvent.changes?.order_charge.new_value,
          currency: order.currency
        })
      )
    }
  }

  if (historyEvent.event === 'line_item_created') {
    const lineItem = lineItems?.find(
      lineItem => lineItem.id === historyEvent.changes?.line_item_id.new_value
    )
    const baseMessagePath =
      'order.history.event.' +
      (lineItem == null ? 'unknown_' : '') +
      'line_item.created'
    messages.push(
      <Trans
        i18nKey={baseMessagePath}
        values={{
          line_number: lineItem?.line_number,
          line_item: lineItem?.product.description
        }}
      />
    )
  }

  if (historyEvent.event === 'line_item_changed') {
    const lineItem = lineItems?.find(
      lineItem => lineItem.id === historyEvent.changes?.line_item_id.new_value
    )
    const baseMessagePath =
      'order.history.event.' +
      (lineItem == null ? 'unknown_' : '') +
      'line_item_changed.'

    if (historyEvent.changes?.quantity) {
      messages.push(
        <Trans
          i18nKey={baseMessagePath + 'quantity'}
          values={{
            line_number: lineItem?.line_number,
            line_item: lineItem?.product.description,
            quantity: historyEvent.changes?.quantity.new_value
          }}
        />
      )
    }

    if (historyEvent.changes?.price) {
      messages.push(
        <Trans
          i18nKey={baseMessagePath + 'price'}
          values={{
            line_number: lineItem?.line_number,
            line_item: lineItem?.product.description,
            price: historyEvent.changes?.price.new_value,
            currency: order.currency
          }}
        />
      )
    }

    if (
      historyEvent.changes?.spend_category_1 != null ||
      historyEvent.changes?.spend_category_2 != null ||
      historyEvent.changes?.spend_category_3 != null ||
      historyEvent.changes?.spend_category_4 != null
    ) {
      messages.push(
        <Trans
          i18nKey={baseMessagePath + 'spend_categories'}
          values={{
            line_number: lineItem?.line_number,
            line_item: lineItem?.product.description,
            spend_categories: [
              historyEvent.changes?.spend_category_1?.new_value,
              historyEvent.changes?.spend_category_2?.new_value,
              historyEvent.changes?.spend_category_3?.new_value,
              historyEvent.changes?.spend_category_4?.new_value
            ]
              .filter(spendCategory => spendCategory != null)
              .map(spendCategory =>
                spendCategory === '' ? t('common.values.none') : spendCategory
              )
              .join(' > ')
          }}
        />
      )
    }

    if (
      !historyEvent.changes?.quantity &&
      !historyEvent.changes?.price &&
      historyEvent.changes?.spend_category_1 == null &&
      historyEvent.changes?.spend_category_2 == null &&
      historyEvent.changes?.spend_category_3 == null &&
      historyEvent.changes?.spend_category_4 == null
    ) {
      messages.push(
        <Trans
          i18nKey={baseMessagePath + 'comment'}
          values={{
            line_number: lineItem?.line_number,
            line_item: lineItem?.product.description
          }}
        />
      )
    }
  }

  if (historyEvent.event === 'line_item_received') {
    const lineItem = lineItems?.find(
      lineItem => lineItem.id === historyEvent.changes?.line_item_id.new_value
    )

    messages.push(
      <Trans
        i18nKey={'order.history.event.line_item.received'}
        values={{
          line_item: lineItem?.product.description,
          part_number: lineItem?.product.part_number,
          quantity: historyEvent.changes?.quantity.new_value,
          date_received: new Date(
            historyEvent.changes?.date_received.new_value ?? ''
          )
        }}
      />
    )
  }

  if (historyEvent.event === 'line_item_unreceived') {
    const lineItem = lineItems?.find(
      lineItem => lineItem.id === historyEvent.changes?.line_item_id.new_value
    )

    messages.push(
      <Trans
        i18nKey={'order.history.event.line_item.unreceived'}
        values={{
          line_item: lineItem?.product.description,
          part_number: lineItem?.product.part_number,
          quantity: historyEvent.changes?.quantity.new_value
        }}
      />
    )
  }

  if (historyEvent.reason !== '') {
    messages.push(<i>{historyEvent.reason}</i>)
  }

  return (
    <>
      {messages.map((message, index) => (
        <p key={index}>{message}</p>
      ))}
    </>
  )
}

function OrderHistory ({
  order,
  lineItems
}: Readonly<OrderHistoryProps>): ReactElement {
  const { t } = useTranslation()
  const { data, isLoading } = useOrderHistory(order.id)

  return (
    <>
      {isLoading || data == null ? (
        <LoadingSpinner />
      ) : (
        <Table className={styles['order-history']}>
          <tbody>
            {data.content?.map((historyEvent, index) => (
              <tr key={index}>
                <td>
                  {t('datetime', { datetime: new Date(historyEvent.date) })}
                </td>
                <td className={styles['history-event-messages']}>
                  <HistoryEventMessages
                    order={order}
                    lineItems={lineItems}
                    historyEvent={historyEvent}
                  />
                </td>
                <td>{historyEvent.account_name}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  )
}

export default OrderHistory
