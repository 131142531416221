import { type FacetFilters } from '../types/facet-filters'

export default function filtersQueryStringToMap (
  filtersQueryString: string
): FacetFilters {
  if (!filtersQueryString) {
    return new Map()
  }

  return filtersQueryString
    .split(/,(?=\S)/)
    .reduce((filters: FacetFilters, category: string): FacetFilters => {
      const [name, value] = category.split(':')
      filters.set(name, new Set(value.split(';')))
      return new Map(filters)
    }, new Map())
}
