import {
  forwardRef,
  type ReactNode,
  type HTMLAttributes,
  type PropsWithChildren,
  type ReactElement
} from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { BsThreeDots } from 'react-icons/bs'
import { Button } from 'react-bootstrap'
import classNames from 'classnames'
import '../assets/scss/MaActionMenu.scss'

export interface MaActionMenuProps
  extends DropdownMenu.DropdownMenuProps,
    PropsWithChildren {
  className?: string
  'aria-label'?: string
}

function MaActionMenu ({
  children,
  className,
  ...props
}: Readonly<MaActionMenuProps>): ReactNode {
  if (
    !children ||
    (Array.isArray(children) && children.every(child => !child))
  ) {
    return null
  }

  return (
    <DropdownMenu.Root {...props}>
      <DropdownMenu.Trigger
        asChild
        className={classNames('ma-action-menu', className)}
        data-testid="ma-action-menu-btn"
        aria-label={props['aria-label']}
      >
        <Button size="sm" variant="light">
          <BsThreeDots size="24" />
        </Button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content className="ma-action-menu-content" sideOffset={5}>
          {children}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

export const MaActionMenuItem = forwardRef<
  HTMLDivElement,
  DropdownMenu.DropdownMenuItemProps & HTMLAttributes<HTMLElement>
>(function MaSelectItem (
  { children, className, ...props },
  forwardedRef
): ReactElement<HTMLDivElement> {
  return (
    <DropdownMenu.Item
      className={classNames('ma-action-menu-item', className)}
      {...props}
      ref={forwardedRef}
    >
      {children}
    </DropdownMenu.Item>
  )
})

export default MaActionMenu
