import { type HTMLAttributes, type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import useProductPrice from '../hooks/useProductPrice'
import styles from '../assets/scss/ProductCard.module.scss'

interface ProductPricePerUnitProps extends HTMLAttributes<HTMLDivElement> {
  productId: string
  currencyCode: string | undefined
  listPrice: number | undefined
  price: number | undefined
  noOfUnits: number
  packSize: number
  unit: string
  isSelfServe: boolean
}

function ProductPricePerUnit ({
  productId,
  currencyCode,
  listPrice,
  price,
  noOfUnits,
  packSize,
  unit,
  isSelfServe
}: Readonly<ProductPricePerUnitProps>): ReactElement | null {
  const { t } = useTranslation()

  const productPriceData = useProductPrice(
    productId,
    currencyCode ?? null,
    listPrice ?? null,
    price ?? null
  )

  const pricePerUnitValue =
    productPriceData.bestPrice != null && noOfUnits != null && packSize != null
      ? productPriceData.bestPrice / (noOfUnits * packSize)
      : null

  const pricePerUnit = t('product.card.price_per_unit', {
    price: pricePerUnitValue,
    currency: productPriceData.currency,
    maximumFractionDigits: 4,
    packSizeUnit: t([`units.${unit}`, unit], {
      count: 1
    })
  })

  if (!pricePerUnitValue || isSelfServe) {
    return null
  }

  return (
    <div className={styles['price-per-unit']} title={pricePerUnit}>
      {pricePerUnit}
    </div>
  )
}

export default ProductPricePerUnit
