import useSWR, { type KeyedMutator } from 'swr'
import { type Orders } from '@amici/myamici-api-client'
import useAccounts from '../../common/hooks/useAccounts'
import useApi from '../../common/hooks/useApi'
import { OrderSearchType } from '../types/order-search-type'
import { OrderSortType } from '../types/order-sort-type'
import { SortDirection } from '../../common/types/sort-direction'

interface OrderSearchParams {
  term?: string
  page?: number
  sort?: OrderSortType
  size?: number
  facetFilter?: string[]
  returnResults?: boolean
  orderSearchType?: OrderSearchType
  dateFrom?: string
  dateTo?: string
  returnFacets?: boolean
}

interface UseOrdersHook {
  data: Orders
  totalCount: number
  isLoading: boolean
  mutate: KeyedMutator<Orders>
}

function useOrders ({
  term,
  page,
  sort,
  size,
  facetFilter,
  returnResults,
  orderSearchType,
  dateFrom,
  dateTo,
  returnFacets
}: OrderSearchParams): UseOrdersHook {
  const { activeAccount } = useAccounts()
  const { ordersApi, fetcher } = useApi()

  const accountId = activeAccount?.accountId ?? ''
  const includeClientPurchases = orderSearchType === OrderSearchType.AllOrders
  const direction =
    sort === OrderSortType.OrderedBy || sort === OrderSortType.Supplier
      ? SortDirection.ASC
      : SortDirection.DESC

  const dateRangeParams = {
    dateFrom,
    dateTo
  }

  const { data, mutate, isLoading } = useSWR(
    accountId
      ? [
          'orders',
          term,
          orderSearchType,
          sort,
          facetFilter,
          page,
          accountId,
          dateFrom,
          dateTo
        ]
      : null,
    async () =>
      await fetcher(ordersApi.getPage, {
        query: term,
        accountId,
        page,
        size,
        sort,
        direction,
        facetFilter,
        returnResults,
        ...(dateFrom && dateTo && dateRangeParams),
        includeClientPurchases,
        returnFacets
      })
  )

  const totalCount = data?.totalElements ?? 0

  return {
    data: data as Orders,
    totalCount,
    isLoading,
    mutate
  }
}

export default useOrders
