import { type ReactElement } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'
import classNames from 'classnames'
import useIsMobile from '../../common/hooks/useIsMobile'
import useOrderRequestsPage from '../hooks/useOrderRequestsPage'
import MaPageTitle from '../../common/components/MaPageTitle'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import MaDateRangePicker from '../../common/components/MaDateRangePicker'
import OrderRequestSearchForm from '../components/OrderRequestSearchForm'
import OrderRequestsOffcanvasFilter from '../components/OrderRequestsOffcanvasFilter'
import SearchFilters from '../../purchasing/components/SearchFilters'
import DynamicPagination from '../../common/components/DynamicPagination'
import OrderRequestsSortSelect from '../components/OrderRequestsSortSelect'
import OrderRequestsSearchSummary from '../components/OrderRequestsSearchSummary'
import OrderRequestCard from '../components/OrderRequestCard'
import styles from '../assets/scss/OrderRequests.module.scss'

function OrderRequests (): ReactElement {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const {
    data,
    isLoading,
    dateRange,
    term,
    isNewSearch,
    searchType,
    sort,
    filters,
    filtersUpdated,
    initialFacets,
    availableFacets,
    emptyFacets,
    showOffcanvasFilters,
    appliedFiltersCount,
    handleDateRangeChange,
    handlePageChange,
    handleSearchChange,
    handleSearchTypeChange,
    handleSortingChange,
    handleSubmit,
    handleOpenOffcanvasFilters,
    handleCloseOffcanvasFilters,
    handleApplyFilters,
    handleFilterUpdate,
    handleClearFilters,
    filterNameFormatters
  } = useOrderRequestsPage()

  return (
    <Container
      fluid="auto"
      className={classNames('ma-page', { [styles.mobile]: isMobile })}
    >
      <MaPageTitle>{t('order_requests.title')}</MaPageTitle>

      <div className={styles['search-form-row']}>
        <OrderRequestSearchForm
          className={styles['search-input']}
          search={term}
          type={searchType}
          onSubmit={handleSubmit}
          onSearchChange={handleSearchChange}
          onTypeChange={handleSearchTypeChange}
        />

        {!isMobile && (
          <div className={styles['search-options']}>
            <MaDateRangePicker
              value={dateRange}
              onDateRangeChange={handleDateRangeChange}
              placeholder={t('order.filters.date_range.placeholder')}
            />
          </div>
        )}
      </div>

      <div className={styles['search-results']}>
        {!isMobile && (
          <div className={styles.facets}>
            {!emptyFacets && (
              <SearchFilters
                facets={initialFacets}
                availableFacets={availableFacets}
                enabledFilters={filters}
                expandedCategories={[Object.keys(initialFacets)[0]]}
                canApply={filtersUpdated && !isNewSearch}
                categoryNameFormatter={category =>
                  t(`order_requests.search.filter.${category.toLowerCase()}`)
                }
                filterNameFormatters={filterNameFormatters}
                onFiltersApply={handleApplyFilters}
                onFiltersClear={handleClearFilters}
                onFilterChange={handleFilterUpdate}
              />
            )}
          </div>
        )}

        {isMobile && (
          <OrderRequestsOffcanvasFilter
            show={showOffcanvasFilters}
            onHide={handleCloseOffcanvasFilters}
            dateRange={dateRange}
            sort={sort}
            onSortingChange={handleSortingChange}
            onDateRangeChange={handleDateRangeChange}
            facets={initialFacets}
            availableFacets={availableFacets}
            enabledFilters={filters}
            expandedCategories={[Object.keys(initialFacets)[0]]}
            canApply={filtersUpdated && !isNewSearch}
            categoryNameFormatter={category =>
              t(`order_requests.search.filter.${category.toLowerCase()}`)
            }
            onFiltersApply={handleApplyFilters}
            onFiltersClear={handleClearFilters}
            onFilterChange={handleFilterUpdate}
          />
        )}

        <div className={styles['card-grid']}>
          {isMobile && (
            <div className={styles['search-controls']}>
              <Row>
                <Col xs={12}>
                  <Button
                    variant="light"
                    className={styles['offcanvas-filters-toggle-btn']}
                    onClick={handleOpenOffcanvasFilters}
                  >
                    <Trans
                      i18nKey="common.button.labels.sort_filter"
                      values={{ count: appliedFiltersCount }}
                    />
                  </Button>
                </Col>
              </Row>
            </div>
          )}

          <Row>
            <Col>
              <OrderRequestsSearchSummary total={data?.totalElements} />
            </Col>

            {!isMobile && (
              <Col>
                <div className={styles.sorting}>
                  <OrderRequestsSortSelect
                    value={sort}
                    onChange={handleSortingChange}
                  />
                </div>
              </Col>
            )}
          </Row>

          <div data-testid="order-requests">
            {isLoading && (
              <div className={styles.loading}>
                <LoadingSpinner />
              </div>
            )}

            <Row>
              {data?.content?.map(orderRequest => (
                <Col xs={12} key={orderRequest.id}>
                  <OrderRequestCard orderRequest={orderRequest} />
                </Col>
              ))}
            </Row>
          </div>

          {data && (data?.totalElements as number) > 0 && (
            <div className={styles.pagination}>
              <DynamicPagination
                currentPage={data?.page ?? 0}
                totalPages={data?.totalPages ?? 0}
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </div>
    </Container>
  )
}

export default OrderRequests
