import { type ReactElement } from 'react'
import classNames from 'classnames'
import useIsMobile from '../../common/hooks/useIsMobile'
import { type ProductResource } from '@amici/myamici-api-client'
import { t } from 'i18next'
import styles from '../assets/scss/ProductDetails.module.scss'

function ProductDetailsTabDocumentation ({
  product
}: Readonly<{
  product?: ProductResource
}>): ReactElement | null {
  const isMobile = useIsMobile()

  if (!product?.documentation) {
    return (
      <div
        className={classNames(styles.documentation, {
          [styles.mobile]: isMobile
        })}
      >
        <p role="no-documentation" className="text-center">
          {t('product.details.tabs.documentation.no_documentation')}
        </p>
      </div>
    )
  }

  return (
    <div
      className={classNames(styles.documentation, {
        [styles.mobile]: isMobile
      })}
    >
      <p
        role="documentation"
        dangerouslySetInnerHTML={{ __html: product.documentation }}
      ></p>
    </div>
  )
}

export default ProductDetailsTabDocumentation
