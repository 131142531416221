import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { format, subYears } from 'date-fns'
import classNames from 'classnames'
import useIsMobile from '../../common/hooks/useIsMobile'
import useDateLocale from '../../common/hooks/useDateLocale'
import useOutstandingManagedOrders from '../hooks/useOutstandingManagedOrders'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import MaPieChart from '../../common/components/MaPieChart'
import styles from '../assets/scss/OrdersAndItems.module.scss'
import { useNavigate } from 'react-router-dom'

const colours: Record<string, string> = {
  empty: '#cdcdcd',
  new: '#8fadda',
  pending: '#6fa8ff',
  open: '#2278b6',
  complete: '#598bd7'
}

function OutstandingManagedOrdersChart (): ReactElement {
  const { t } = useTranslation()
  const dateLocale = useDateLocale()
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  const { data, isLoading } = useOutstandingManagedOrders()

  const managedOrdersSinceDateFormatted = format(
    subYears(new Date(), 1),
    'd MMM yyyy',
    { locale: dateLocale }
  )

  const navigateToOrdersPage = (status?: string): void => {
    if (!data || !data.total || !data.values) {
      return
    }

    const availableStatuses = data.values
      .filter(({ value }) => !!value)
      .map(({ name }) => name)
      .join(';')

    const todaysDateFormatted = format(new Date(), 'yyyy-MM-dd')

    const oneYearAgoDateFormatted = format(
      subYears(new Date(), 1),
      'yyyy-MM-dd'
    )

    navigate({
      pathname: '/purchasing/orders',
      ...{
        search:
          '?filters=managed_order:amici_serviced' +
          `,status:${status || availableStatuses}` +
          `&dateFrom=${oneYearAgoDateFormatted}` +
          `&dateTo=${todaysDateFormatted}`
      }
    })
  }

  return (
    <div
      className={classNames(styles['chart-widget'], {
        [styles.mobile]: isMobile
      })}
    >
      <h4 className={styles.title}>
        {t('home.chart.managed_orders.title', {
          date: managedOrdersSinceDateFormatted
        })}
      </h4>

      <div className={styles['chart-wrapper']}>
        {isLoading && <LoadingSpinner />}

        {!isLoading && (
          <MaPieChart
            data={data}
            colours={colours}
            className={styles.chart}
            noItemsText={t('home.chart.managed_orders.no_items')}
            legendFormatter={(value: string | number) =>
              t(`home.chart.managed_orders.${value}`)
            }
            tooltipFormatter={(value: number, name: string) =>
              t(`home.chart.managed_orders.tooltip.${name}`, { count: value })
            }
            onTotalClick={() => {
              navigateToOrdersPage()
            }}
            onSegmentClick={({ name }) => {
              navigateToOrdersPage(name)
            }}
          />
        )}
      </div>
    </div>
  )
}

export default OutstandingManagedOrdersChart
