import { type ReactElement } from 'react'
import { t } from 'i18next'
import { BsQuestionLg } from 'react-icons/bs'
import MaIconButton from './MaIconButton'

function SupportMenu (): ReactElement {
  const support = t('support.title') && 'Support'

  return (
    <MaIconButton
      to="https://www.support.myamici.co.uk/"
      target="_blank"
      rel="noreferrer"
      title={support}
    >
      <BsQuestionLg size="24" />
    </MaIconButton>
  )
}

export default SupportMenu
