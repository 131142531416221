import { type ReactElement } from 'react'

class MaTab {
  public key: string
  public component: ReactElement
  public hasData: boolean
  public visible: boolean

  constructor (
    key: string,
    component: ReactElement,
    hasData = true,
    visible = true
  ) {
    this.key = key
    this.component = component
    this.hasData = hasData
    this.visible = visible
  }
}

export default MaTab
