import OrderRequestStatusUpdateModal from './OrderRequestStatusUpdateModal'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { type OrderRequestResource } from '@amici/myamici-api-client'
import useOrderRequest from '../hooks/useOrderRequest'
import { useToastNotification } from '../../common/components/ToastNotificationContextProvider'
import useOrderRequestHistory from '../hooks/useOrderRequestHistory'

interface OrderRequestWithdrawModalProps {
  show: boolean
  orderRequest: OrderRequestResource
  onClose: () => void
}

function OrderRequestWithdrawModal ({
  show,
  orderRequest,
  onClose
}: Readonly<OrderRequestWithdrawModalProps>): ReactElement {
  const { t } = useTranslation()
  const { showToastMessage } = useToastNotification()
  const { withdraw, isWithdrawing } = useOrderRequest(orderRequest.id)
  const { mutate: refreshHistory } = useOrderRequestHistory(orderRequest.id)

  return (
    <OrderRequestStatusUpdateModal
      title={t('order_request.status_update.withdraw.title', {
        ref: orderRequest.order_ref
      })}
      description={t('order_request.status_update.withdraw.description')}
      show={show}
      onClose={onClose}
      onConfirm={reason => {
        void withdraw(reason)
          .then(() => {
            void refreshHistory()
          })
          .catch(() => {
            showToastMessage(
              'danger',
              t('order_request.status_update.withdraw.failure', {
                ref: orderRequest.order_ref
              })
            )
          })
          .finally(onClose)
      }}
      disabled={isWithdrawing}
      confirmLabel={t('order_request.status_update.withdraw.confirm')}
      reasonLabel={t(
        'order_request.status_update.withdraw.withdrawal_reason.label'
      )}
      reasonRequired={true}
    />
  )
}
export default OrderRequestWithdrawModal
