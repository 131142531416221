import { type FormEvent, type ReactElement, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ProductSearchType } from '../types/product-search-type'
import MaPageTitle from '../../common/components/MaPageTitle'
import ProductSearchForm from './ProductSearchForm'
import styles from '../assets/scss/ProductDetails.module.scss'

function ProductDetailsHeader (): ReactElement {
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState('')
  const [searchType, setSearchType] = useState<ProductSearchType>(
    ProductSearchType.Keyword
  )
  const navigate = useNavigate()

  const handleSearchSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    if (!searchTerm.trim()) {
      setSearchTerm('')
      return
    }

    navigate(
      `/purchasing/products?term=${searchTerm.trim()}&type=${searchType}`
    )
  }

  return (
    <div className={styles.header}>
      <MaPageTitle>{t('products.title')}</MaPageTitle>

      <Row>
        <Col sm={12} md={8} xl={6}>
          <ProductSearchForm
            search={searchTerm}
            type={searchType}
            onSearchChange={e => {
              setSearchTerm(e.target.value)
            }}
            onTypeChange={setSearchType}
            onSubmit={handleSearchSubmit}
          />
        </Col>
      </Row>
    </div>
  )
}

export default ProductDetailsHeader
