import { useLocation } from 'react-router-dom'
import { useEnv } from './useEnv'

function useAccountSwitcher (): string {
  const { REACT_APP_MA3_BASE_URL } = useEnv()
  const { pathname, search } = useLocation()
  const { protocol, host } = globalThis.location
  const returnUrl = encodeURIComponent(
    `${protocol}//${host}${pathname}${search}`
  )
  const accountSwitcherUrl = `${
    (REACT_APP_MA3_BASE_URL as string) ?? ''
  }/Account/SSOAccountSelectionListView.aspx?ReturnUrl=${returnUrl}`

  return accountSwitcherUrl
}

export default useAccountSwitcher
