class UserAccount {
  public accountId: string
  public username: string
  public client: string
  public modules: string[]
  public permissions: string[]

  constructor (
    accountId: string,
    username: string,
    client: string,
    modules: string[],
    permissions: string[]
  ) {
    this.accountId = accountId
    this.username = username
    this.client = client
    this.modules = modules
    this.permissions = permissions
  }
}

export default UserAccount
