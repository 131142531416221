import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import useOpenRequests from '../hooks/useOpenRequests'
import useAccountMenu from '../../common/hooks/useAccountMenu'
import useOrders from '../../purchasing/hooks/useOrders'
import useItemsDueToday from '../hooks/useItemsDueToday'
import OutstandingManagedOrdersChart from './OutstandingManagedOrdersChart'
import OutstandingLineItemsChart from './OutstandingLineItemsChart'
import MaPanel from '../../common/components/MaPanel'
import Widget from './Widget'
import styles from '../assets/scss/OrdersAndItems.module.scss'

function OrdersAndItems (): ReactElement {
  const { MA3_BASE_URL } = useAccountMenu()
  const { t } = useTranslation()

  const { count: openRequestsCount, isLoading: isOpenRequestsLoading } =
    useOpenRequests()

  const { totalCount: openOrdersCount, isLoading: isOpenOrdersLoading } =
    useOrders({
      facetFilter: ['status:open'],
      returnResults: false
    })

  const { count: itemsDueTodayCount, isLoading: isItemsDueTodayLoading } =
    useItemsDueToday()

  return (
    <MaPanel className={styles['orders-and-items']}>
      <MaPanel.Header text={t('home.orders_and_items.title')} />

      <MaPanel.Body>
        <div className={styles.widgets}>
          <Widget
            value={openOrdersCount}
            text={'home.orders_and_items.open_orders'}
            url={'/purchasing/orders?filters=status:open&dateFrom=&dateTo='}
            isLoading={isOpenOrdersLoading}
          />
          <Widget
            value={itemsDueTodayCount ?? 0}
            text={'home.orders_and_items.items_due_today'}
            url={'/purchasing/outstanding-order-items?preset=due_today'}
            isLoading={isItemsDueTodayLoading}
          />
          <Widget
            value={openRequestsCount ?? 0}
            text={'home.orders_and_items.open_requests'}
            url={`${MA3_BASE_URL}/default.aspx?ShowRequests=true`}
            isLoading={isOpenRequestsLoading}
          />
        </div>

        <div className={styles.charts}>
          <OutstandingLineItemsChart />
          <OutstandingManagedOrdersChart />
        </div>
      </MaPanel.Body>
    </MaPanel>
  )
}

export default OrdersAndItems
