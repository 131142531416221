import { useAuth } from 'react-oidc-context'
import { type AccountResource } from '@amici/myamici-api-client'
import { useEnv } from './useEnv'
import useAccounts from './useAccounts'
import { UserPermission } from '../types/user-permission'

interface UseAccountMenu {
  MA3_BASE_URL: string
  accountProfile?: AccountResource
  canSwitchAccounts: boolean
  canChangePassword: boolean
  canSetupNewPin: boolean
  canChangePin: boolean
  handleLogOut: () => void
}

function useAccountMenu (): UseAccountMenu {
  const auth = useAuth()

  const { REACT_APP_MA3_BASE_URL } = useEnv()
  const { accounts, activeAccount, accountProfile } = useAccounts()

  const MA3_BASE_URL: string = REACT_APP_MA3_BASE_URL ?? ''
  const canSwitchAccounts = !!(accounts && accounts?.length > 1)
  const canChangePassword = !!activeAccount?.permissions.includes(
    UserPermission.CanChangePassword
  )
  const canSetupNewPin = !!(
    activeAccount?.permissions.includes(UserPermission.IsAudited) &&
    !accountProfile?.pin_set
  )
  const canChangePin = !!(
    activeAccount?.permissions.includes(UserPermission.IsAudited) &&
    accountProfile?.pin_set
  )

  const handleLogOut = (): void => {
    void auth.removeUser()
    void auth.signoutRedirect({
      post_logout_redirect_uri: REACT_APP_MA3_BASE_URL + '/Account/Logout.aspx'
    })
  }

  return {
    MA3_BASE_URL,
    accountProfile,
    canSwitchAccounts,
    canChangePassword,
    canSetupNewPin,
    canChangePin,
    handleLogOut
  }
}

export default useAccountMenu
