import { createBrowserRouter } from 'react-router-dom'
import App from '../App'
import homeRoutes from '../home/routes'
import purchasingRoutes from '../purchasing/routes'
import notificationsRoutes from '../notifications/routes'
import settingsRoutes from '../settings/routes'
import PageNotFound from '../errors/PageNotFound'
import userAccountRoutes from '../user/routes'

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      ...homeRoutes,
      ...notificationsRoutes,
      ...purchasingRoutes,
      ...settingsRoutes,
      ...userAccountRoutes
    ]
  },
  {
    path: '*',
    element: <PageNotFound />
  }
])

export default router
