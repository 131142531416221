import {
  type ComponentPropsWithoutRef,
  type ElementRef,
  forwardRef,
  type ReactElement,
  type PropsWithChildren
} from 'react'
import * as Switch from '@radix-ui/react-switch'
import '../assets/scss/MaSwitch.scss'

interface MaSwitchProps extends Switch.SwitchProps, PropsWithChildren {}

const MaSwitch = forwardRef<
  ElementRef<typeof Switch.Root>,
  ComponentPropsWithoutRef<typeof Switch.Root>
>(function MaSwitch (
  { children, id, ...props }: MaSwitchProps,
  ref
): ReactElement {
  return (
    <div className="ma-switch-wrapper">
      {children && (
        <label className="ma-switch-label" htmlFor={id}>
          {children}
        </label>
      )}

      <Switch.Root className="ma-switch" id={id} ref={ref} {...props}>
        <Switch.Thumb className="ma-switch-thumb" />
      </Switch.Root>
    </div>
  )
})

export default MaSwitch
