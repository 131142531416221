import MaConfirm from '../../common/components/MaConfirm'
import { type ReactElement, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import styles from '../assets/scss/OrderRequestStatusUpdateModal.module.scss'
import { type Control, useForm } from 'react-hook-form'
import FormControlTextArea from '../../common/components/FormControlTextArea'

const REASON_MAX_LENGTH = 500

interface OrderRequestStatusUpdateModalProps {
  show: boolean
  onClose: () => void
  onConfirm: (reason: string) => void
  disabled: boolean
  confirmLabel: string
  title: string
  description?: string
  reasonLabel: string
  reasonRequired?: boolean
}

function OrderRequestStatusUpdateModal ({
  show,
  onClose,
  onConfirm,
  disabled,
  confirmLabel,
  title,
  description,
  reasonLabel,
  reasonRequired = false
}: Readonly<OrderRequestStatusUpdateModalProps>): ReactElement {
  const {
    control,
    watch,
    formState: { isValid },
    reset,
    getValues,
    setValue
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    values: {
      reason: ''
    }
  })
  const reason = watch('reason')

  useEffect(() => {
    reset()
  }, [reset, show])

  const handleConfirm = (): void => {
    setValue('reason', getValues('reason').trim())
    onConfirm(getValues('reason'))
  }

  return (
    <MaConfirm
      size="lg"
      show={show}
      onConfirm={handleConfirm}
      onClose={onClose}
      title={title}
      confirmLabel={confirmLabel}
      disabled={
        disabled || !isValid || (reasonRequired && reason.trim().length === 0)
      }
    >
      {description && <p className={styles.description}>{description}</p>}
      <Form>
        <fieldset disabled={disabled}>
          <FormControlTextArea
            label={reasonLabel}
            value={reason}
            name="reason"
            required={reasonRequired}
            maxLength={REASON_MAX_LENGTH}
            rows={6}
            control={control as unknown as Control}
          />
        </fieldset>
      </Form>
    </MaConfirm>
  )
}

export default OrderRequestStatusUpdateModal
