import { type PropsWithChildren, type ReactElement } from 'react'
import { Toast } from 'react-bootstrap'
import styles from '../assets/scss/MaToast.module.scss'

function MaToast ({
  type,
  onClose,
  children
}: {
  readonly type: string
  readonly onClose: () => void
} & PropsWithChildren): ReactElement {
  const TOAST_AUTO_HIDE_DELAY_MS = 5000

  return (
    <Toast
      bg={type}
      autohide
      className={styles.toast}
      delay={TOAST_AUTO_HIDE_DELAY_MS}
      onClose={onClose}
    >
      <Toast.Body>{children}</Toast.Body>
    </Toast>
  )
}

export default MaToast
