import { useCallback } from 'react'
import {
  type BasketApiClearBasketRequest,
  type BasketApiDeleteLineItemFromBasketRequest,
  type BasketApiUpdateBasketLineItemRequest,
  type BasketLineItem,
  type BasketLineItems
} from '@amici/myamici-api-client'
import useSWR, { type KeyedMutator } from 'swr'
import useSWRMutation from 'swr/mutation'
import useApi from '../../common/hooks/useApi'
import useAccounts from '../../common/hooks/useAccounts'
import useBasketCount from './useBasketCount'
import useBasketPreview from './useBasketPreview'
import ReactGA from 'react-ga4'

export interface UseBasketHook {
  items: BasketLineItem[]
  suppliers: string[]
  mutate: KeyedMutator<BasketLineItems>
  error?: Error
  isLoading: boolean
  isRemoving: boolean
  isValidating: boolean
  isUpdating: boolean
  isClearing: boolean
  remove: (uuid: string) => Promise<void>
  update: (basketLineItem: BasketLineItem) => Promise<void>
  updateAll: (basketLineItems: BasketLineItem[]) => Promise<void>
  removeAll: () => Promise<void>
  clearCache: () => void
}

function useBasket (): UseBasketHook {
  const { activeAccount } = useAccounts()
  const {
    basketApi: {
      getBasketLineItems,
      deleteLineItemFromBasket,
      updateBasketLineItem,
      clearBasket
    },
    fetcher
  } = useApi()
  const { mutate: updateBasketPreview } = useBasketPreview()
  const { mutate: updateBasketCount } = useBasketCount()

  const accountId = activeAccount?.accountId ?? ''

  const { data, error, isLoading, mutate, isValidating } = useSWR<
    BasketLineItems,
    Error
  >(
    accountId ? ['basket-items', accountId] : null,
    async () =>
      await fetcher(getBasketLineItems, {
        accountId,
        page: 1,
        size: 0
      })
  )

  const items = data?.content ?? []

  const suppliers = [
    ...items.reduce(
      (suppliers, item) =>
        suppliers.add(item.line_item?.product.supplier?.name ?? ''),
      new Set<string>()
    )
  ]
    .filter(s => !!s)
    .sort((a, b) => a.localeCompare(b))

  const { trigger: triggerRemove, isMutating: isRemoving } = useSWRMutation(
    accountId ? ['basket-items', accountId] : null,
    async (_, { arg }: { arg: BasketApiDeleteLineItemFromBasketRequest }) => {
      await fetcher(deleteLineItemFromBasket, arg)
    }
  )

  const { trigger: triggerUpdate, isMutating: isUpdating } = useSWRMutation(
    accountId ? ['basket-items', accountId] : null,
    async (_, { arg }: { arg: BasketApiUpdateBasketLineItemRequest }) =>
      await fetcher(updateBasketLineItem, arg)
  )

  const { trigger: triggerRemoveAll, isMutating: isClearing } = useSWRMutation(
    accountId ? ['basket-items', accountId] : null,
    async (_, { arg }: { arg: BasketApiClearBasketRequest }) => {
      await fetcher(clearBasket, arg)
    }
  )

  const remove = async (uuid: string): Promise<void> => {
    if (!accountId) {
      return
    }

    await triggerRemove({ uuid, accountId })
    await updateBasketPreview()
    await updateBasketCount()
  }

  const update = async (basketLineItem: BasketLineItem): Promise<void> => {
    if (!accountId) {
      return
    }

    await triggerUpdate(
      {
        basketLineItem,
        uuid: basketLineItem.line_item.id,
        accountId
      },
      {
        optimisticData: _currentData => {
          const actualCurrentData = _currentData as unknown as BasketLineItems
          return {
            ...actualCurrentData,
            content: actualCurrentData.content?.map(existingBasketLineItem =>
              existingBasketLineItem.line_item.id ===
              basketLineItem.line_item.id
                ? basketLineItem
                : existingBasketLineItem
            )
          } as unknown as BasketLineItem
        }
      }
    )
    await updateBasketPreview()
  }

  const updateAll = async (
    basketLineItems: BasketLineItem[]
  ): Promise<void> => {
    if (!accountId) {
      return
    }

    const updates = basketLineItems.map(
      async basketLineItem =>
        await triggerUpdate(
          {
            basketLineItem,
            uuid: basketLineItem.line_item.id,
            accountId
          },
          {
            optimisticData: _currentData => {
              const actualCurrentData =
                _currentData as unknown as BasketLineItems
              return {
                ...actualCurrentData,
                content: actualCurrentData.content?.map(
                  existingBasketLineItem =>
                    basketLineItems.find(
                      updatedBasketLineItem =>
                        updatedBasketLineItem.line_item.id ===
                        existingBasketLineItem.line_item.id
                    ) ?? existingBasketLineItem
                )
              } as unknown as BasketLineItem
            }
          }
        )
    )
    await Promise.all(updates)
    await updateBasketPreview()
  }

  const removeAll = async (): Promise<void> => {
    if (!accountId) {
      return
    }

    ReactGA.event('action_item_list', {
      item_list_id: 'basket_items',
      action: 'delete'
    })

    await triggerRemoveAll({ accountId })
    await updateBasketPreview()
    await updateBasketCount()
  }

  const clearCache = useCallback((): void => {
    void mutate(undefined)
  }, [mutate])

  return {
    items,
    suppliers,
    mutate,
    remove,
    update,
    updateAll,
    removeAll,
    clearCache,
    error,
    isLoading,
    isValidating,
    isRemoving,
    isUpdating,
    isClearing
  }
}

export default useBasket
