import { type ReactElement } from 'react'
import { Container } from 'react-bootstrap'
import TasksFilter from './TasksFilter'
import TasksGrid from './TasksGrid'
import useTasks from '../../tasks/hooks/useTasks'

function Tasks (): ReactElement {
  const {
    tasks,
    search,
    sortingOrder,
    totalTasks,
    changeSearch,
    changeSortingOrder
  } = useTasks()

  return (
    <Container fluid="auto" className="h-100">
      <TasksFilter
        search={search}
        sortingOrder={sortingOrder}
        totalTasks={totalTasks}
        onSearchChange={changeSearch}
        onSortingOrderChange={changeSortingOrder}
      />

      <TasksGrid tasks={tasks} totalTasks={totalTasks} />
    </Container>
  )
}

export default Tasks
