import {
  type HTMLAttributes,
  type MouseEventHandler,
  type ReactElement,
  type ReactNode,
  useLayoutEffect,
  useRef,
  useState
} from 'react'
import { Alert } from 'react-bootstrap'
import { BsChevronDoubleDown, BsChevronDoubleUp } from 'react-icons/bs'
import classNames from 'classnames'
import styles from '../assets/scss/NotificationAlert.module.scss'

interface NotificationAlertProps extends HTMLAttributes<HTMLDivElement> {
  title?: string
  body?: string
  footer?: ReactNode
  unread?: boolean
  expandable?: boolean
  onClick?: () => void
  onClose?: () => void
}

function NotificationAlert ({
  title,
  body,
  footer,
  expandable,
  unread,
  onClick,
  onClose
}: Readonly<NotificationAlertProps>): ReactElement {
  const [expanded, setExpanded] = useState<boolean>(false)
  const [hasOverflow, setHasOverflow] = useState<boolean>(false)
  const contentBodyRef = useRef<HTMLDivElement>(null)

  const resizeObserver = useRef(
    new ResizeObserver(entries => {
      const [entry] = entries
      setHasOverflow(entry.target.scrollHeight > entry.target.clientHeight)
    })
  )

  useLayoutEffect(() => {
    const { current: contentBodyEl } = contentBodyRef
    const observer = resizeObserver.current

    if (!expandable || !contentBodyEl) {
      return
    }

    observer.observe(contentBodyEl)

    return () => {
      observer.unobserve(contentBodyEl)
    }
  }, [expandable])

  const handleToggleExpandClick: MouseEventHandler<HTMLButtonElement> = (
    event
  ): void => {
    event.stopPropagation()
    setExpanded(currentValue => !currentValue)
  }

  return (
    <Alert
      dismissible
      variant="light"
      onClose={onClose}
      className={classNames(styles['notification-alert'], {
        [styles.expandable]: expandable,
        [styles.expanded]: expanded,
        [styles.unread]: unread
      })}
    >
      <div className={styles.content} onClick={onClick}>
        {title && (
          <div className={styles.header}>
            <h5 className={styles.title}>{title}</h5>
          </div>
        )}
        <div
          ref={contentBodyRef}
          className={`${styles.body}${
            hasOverflow ? ` ${styles['has-overflow']}` : ''
          }`}
          dangerouslySetInnerHTML={{ __html: body ?? '' }}
        />
        {(hasOverflow || expanded) && (
          <button
            className={styles['toggle-expanded-btn']}
            onClick={handleToggleExpandClick}
          >
            {expanded ? (
              <BsChevronDoubleUp size={16} />
            ) : (
              <BsChevronDoubleDown size={16} />
            )}
          </button>
        )}
        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </Alert>
  )
}

export default NotificationAlert
