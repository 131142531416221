import useSWR from 'swr'
import useApi from '../../common/hooks/useApi'
import useAccounts from '../../common/hooks/useAccounts'

interface UseOpenRequestsHook {
  count?: number
  isLoading: boolean
}

function useOpenRequests (): UseOpenRequestsHook {
  const {
    requestsApi: { getOpenCount },
    fetcher
  } = useApi()
  const { activeAccount } = useAccounts()

  const { data: count, isLoading } = useSWR(
    activeAccount?.accountId
      ? ['open-requests-count', activeAccount.accountId]
      : null,
    async () =>
      await fetcher(getOpenCount, { accountId: activeAccount?.accountId })
  )

  return {
    count,
    isLoading
  }
}

export default useOpenRequests
