import { useMemo } from 'react'
import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'
import {
  type SpendCategoryField,
  SpendCategoryFieldStatusEnum,
  type SpendCategoriesApiCreateSpendCategoryRequest,
  type SpendCategory,
  type SpendCategoriesApiUpdateSpendCategoryRequest,
  type SpendCategoriesApiUpdateSpendCategoryFieldRequest,
  type SpendCategoriesApiUpdateDefaultClientSpendCategoryRequest,
  type SpendCategoriesApiUpdateDefaultAccountSpendCategoryRequest
} from '@amici/myamici-api-client'
import useApi from '../../common/hooks/useApi'
import useAccounts from '../../common/hooks/useAccounts'

interface useSpendCategoriesHook {
  data?: SpendCategoryField[]
  groupNames: string[]
  isLoading: boolean
  isValidating: boolean
  isAdding: boolean
  isUpdating: boolean
  isUpdatingField: boolean
  isSettingAccountDefault: boolean
  isSettingClientDefault: boolean
  addNew: (newSpendCategory: SpendCategory) => Promise<SpendCategory>
  handleUpdate: (spendCategory: SpendCategory) => Promise<SpendCategory>
  handleUpdateField: (
    spendCategoryField: SpendCategoryField
  ) => Promise<SpendCategoryField>
  handleSetAccountDefault: (
    spendCategory: SpendCategory
  ) => Promise<SpendCategory>
  handleSetClientDefault: (
    spendCategory: SpendCategory
  ) => Promise<SpendCategory>
}

function useSpendCategories (excludeInactive?: boolean): useSpendCategoriesHook {
  const {
    spendCategoriesApi: {
      getSpendCategoryFields,
      createSpendCategory,
      updateSpendCategory,
      updateSpendCategoryField,
      updateDefaultAccountSpendCategory,
      updateDefaultClientSpendCategory
    },
    fetcher
  } = useApi()
  const { activeAccount, accountProfile } = useAccounts()
  const accountId = activeAccount?.accountId ?? ''

  const { data, isLoading, isValidating } = useSWR(
    accountId ? ['spend-categories', accountId] : null,
    async () => await fetcher(getSpendCategoryFields, { accountId })
  )

  const { trigger: add, isMutating: isAdding } = useSWRMutation(
    accountId ? ['spend-categories', accountId] : null,
    async (_, { arg }: { arg: SpendCategoriesApiCreateSpendCategoryRequest }) =>
      await fetcher(createSpendCategory, arg),
    { populateCache: false, revalidate: true }
  )

  const { trigger: update, isMutating: isUpdating } = useSWRMutation(
    accountId ? ['spend-categories', accountId] : null,
    async (_, { arg }: { arg: SpendCategoriesApiUpdateSpendCategoryRequest }) =>
      await fetcher(updateSpendCategory, arg),
    { populateCache: false, revalidate: true }
  )

  const { trigger: updateField, isMutating: isUpdatingField } = useSWRMutation(
    accountId ? ['spend-categories', accountId] : null,
    async (
      _,
      { arg }: { arg: SpendCategoriesApiUpdateSpendCategoryFieldRequest }
    ) => await fetcher(updateSpendCategoryField, arg),
    { populateCache: false, revalidate: true }
  )

  const { trigger: setAccountDefault, isMutating: isSettingAccountDefault } =
    useSWRMutation(
      accountId ? ['spend-categories', accountId] : null,
      async (
        _,
        {
          arg
        }: { arg: SpendCategoriesApiUpdateDefaultAccountSpendCategoryRequest }
      ) => await fetcher(updateDefaultAccountSpendCategory, arg),
      { populateCache: false, revalidate: false }
    )

  const { trigger: setClientDefault, isMutating: isSettingClientDefault } =
    useSWRMutation(
      accountId ? ['spend-categories', accountId] : null,
      async (
        _,
        {
          arg
        }: { arg: SpendCategoriesApiUpdateDefaultClientSpendCategoryRequest }
      ) => await fetcher(updateDefaultClientSpendCategory, arg),
      { populateCache: false, revalidate: false }
    )

  let spendCategories = data?.content

  if (excludeInactive) {
    spendCategories = data?.content?.filter(
      spendCategoryField =>
        spendCategoryField.status === SpendCategoryFieldStatusEnum.ACTIVE
    )
    spendCategories = spendCategories?.map(spendCategoryField => ({
      ...spendCategoryField,
      spend_categories: spendCategoryField.spend_categories.filter(
        spendCategory =>
          spendCategory.status === SpendCategoryFieldStatusEnum.ACTIVE
      )
    }))
  }

  const groupNames: string[] = useMemo(
    () => spendCategories?.map(group => group?.name) ?? [],
    [spendCategories]
  )

  const addNew = async (spendCategory: SpendCategory): Promise<SpendCategory> =>
    await add({
      spendCategory,
      fieldId: `${accountProfile?.client?.id}-${spendCategory.field_id}`,
      accountId
    })

  const handleUpdate = async (
    spendCategory: SpendCategory
  ): Promise<SpendCategory> =>
    await update({
      spendCategoryId: spendCategory.id.toString(),
      spendCategory,
      fieldId: `${accountProfile?.client?.id}-${spendCategory.field_id}`,
      accountId
    })

  const handleUpdateField = async (
    spendCategoryField: SpendCategoryField
  ): Promise<SpendCategoryField> =>
    await updateField({
      fieldId: `${accountProfile?.client?.id}-${spendCategoryField.id}`,
      spendCategoryField,
      accountId
    })

  const handleSetAccountDefault = async (
    spendCategory: SpendCategory
  ): Promise<SpendCategory> =>
    await setAccountDefault({
      fieldId: `${accountProfile?.client?.id}-${spendCategory.field_id}`,
      spendCategory,
      accountId
    })

  const handleSetClientDefault = async (
    spendCategory: SpendCategory
  ): Promise<SpendCategory> =>
    await setClientDefault({
      fieldId: `${accountProfile?.client?.id}-${spendCategory.field_id}`,
      spendCategory,
      accountId
    })

  return {
    data: spendCategories,
    groupNames,
    isLoading,
    isValidating,
    isAdding,
    isUpdating,
    isSettingAccountDefault,
    isSettingClientDefault,
    isUpdatingField,
    addNew,
    handleUpdate,
    handleUpdateField,
    handleSetAccountDefault,
    handleSetClientDefault
  }
}

export default useSpendCategories
