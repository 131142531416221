import { type ReactElement, useEffect, useRef, useState } from 'react'
import { Container } from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'
import { createSearchParams, Link, useNavigate } from 'react-router-dom'
import {
  type BasketLineItem,
  type LineItem,
  type OrderRequestResource,
  ProductStatusEnum
} from '@amici/myamici-api-client'
import classNames from 'classnames'
import useBasket from '../hooks/useBasket'
import useBasketStockInfo from '../hooks/useBasketStockInfo'
import useBasketItemChanges, {
  type BasketLineItemChange,
  ChangeType
} from '../hooks/useBasketItemChanges'
import { useToastNotification } from '../../common/components/ToastNotificationContextProvider'
import MaPageTitle from '../../common/components/MaPageTitle'
import MaCheckbox, {
  type CheckedState
} from '../../common/components/MaCheckbox'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import BasketLineItemCard from '../components/BasketLineItemCard'
import OrderRequestSummary from '../components/OrderRequestSummary'
import BasketChangeNotifications from '../components/BasketChangeNotifications'
import EmptyBasketMessage from '../components/EmptyBasketMessage'
import OrderRequestFormModal from '../components/OrderRequestFormModal'
import styles from '../assets/scss/Basket.module.scss'
import ReactGA from 'react-ga4'
import { format } from 'date-fns'
import { OrderRequestSearchType } from '../../order-requests/types/order-request-search-type'
import { OrderRequestSortBy } from '../../order-requests/types/order-request-sort-option'
import { SortDirection } from '../../common/types/sort-direction'
import { BasketActionMenu } from '../components/BasketActionMenu'

export const highlightTypeMap: Record<ChangeType, string> = {
  [ChangeType.ProductUnavailable]: 'danger',
  [ChangeType.OutOfStock]: 'danger',
  [ChangeType.PriceIncrease]: 'warning',
  [ChangeType.InsufficientStock]: 'warning',
  [ChangeType.ProductName]: 'warning',
  [ChangeType.PackSize]: 'warning',
  [ChangeType.PriceDecrease]: 'success'
}

export function getHighlightType (
  changes: BasketLineItemChange[],
  item: BasketLineItem
): string | undefined {
  const change = changes.find(
    change => change.item.line_item.id === item.line_item.id
  )

  return change && highlightTypeMap[change.changeType]
}

function Basket (): ReactElement {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    items,
    suppliers,
    remove,
    update,
    clearCache,
    isRemoving,
    isLoading,
    isValidating,
    isClearing
  } = useBasket()
  const { showToastMessage } = useToastNotification()
  const stockInfo = useBasketStockInfo(items)
  const basketItemChanges = useBasketItemChanges(items, stockInfo)
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([])
  const [dismissedNotificationIds, setDismissedNotificationIds] = useState<
    string[]
  >([])
  const [lineItemsToSubmit, setLineItemsToSubmit] = useState<BasketLineItem[]>(
    []
  )

  const isBusy = isLoading || isRemoving || isValidating || isClearing
  const isEmpty = !isLoading && items.length < 1

  const cardRefs = useRef<Record<string, HTMLDivElement | null>>({})

  useEffect(
    () => () => {
      clearCache()
    },
    [clearCache]
  )

  useEffect(() => {
    if (!isLoading && items.length > 0) {
      // Preselecting only active products and items with known price
      setSelectedItemIds(
        items
          .filter(
            item =>
              item.line_item.product.status !== ProductStatusEnum.INACTIVE &&
              !!item.product_price
          )
          .map(item => item.line_item.id)
      )
    }
    // Disabling the warning because we don't need to watch for
    // the items list changes - this is for initial load only
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps -- skip list of items
  }, [isLoading])

  const visibleBasketItemChanges = basketItemChanges.filter(
    change => !dismissedNotificationIds.includes(change.id)
  )

  const getItemsBySupplier = (supplier: string): BasketLineItem[] =>
    items.filter(
      ({ line_item: item }) => item.product.supplier?.name === supplier
    )

  const getSupplierSelectionState = (supplier: string): CheckedState =>
    getItemsBySupplier(supplier).every(({ line_item: item }) =>
      selectedItemIds.includes(item.id)
    )
      ? true
      : getItemsBySupplier(supplier).some(({ line_item: item }) =>
            selectedItemIds.includes(item.id)
          )
        ? 'indeterminate'
        : false

  const handleToggleSelectionBySupplier = (
    supplier: string,
    checked: CheckedState
  ): void => {
    ReactGA.event('action_item_list', {
      item_list_id: 'basket_items',
      action: checked ? 'supplier_selected' : 'supplier_deselected'
    })

    const supplierLineItemIds = getItemsBySupplier(supplier).map(
      ({ line_item: item }) => item.id
    )
    const filteredSelectedItemIds = [...selectedItemIds].filter(
      selectedItemId => !supplierLineItemIds.includes(selectedItemId)
    )

    setSelectedItemIds(
      checked
        ? [...filteredSelectedItemIds, ...supplierLineItemIds]
        : [...filteredSelectedItemIds]
    )
  }

  const handleItemSelectedChange = (
    lineItem: LineItem,
    checked: CheckedState
  ): void => {
    ReactGA.event('action_item', {
      item_list_id: 'basket_items',
      item_id: lineItem.id,
      action: checked ? 'selected' : 'deselected'
    })

    if (checked) {
      setSelectedItemIds([...selectedItemIds, lineItem.id])
    }

    if (!checked) {
      setSelectedItemIds([...selectedItemIds.filter(id => id !== lineItem.id)])
    }
  }

  const handleNotificationClose = (id: string): void => {
    if (id !== undefined) {
      setDismissedNotificationIds([...dismissedNotificationIds, id])
    }
  }

  const handleNotificationClick = (target: HTMLElement, id: string): void => {
    if (!target.classList.contains('btn-close')) {
      cardRefs.current[id]?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    }
  }

  const handleUpdate = async (
    lineItem: LineItem,
    quantity?: number
  ): Promise<void> => {
    const basketItem = items.find(item => item.line_item.id === lineItem.id)

    if (!basketItem) {
      return
    }

    const lineQty = quantity !== undefined ? quantity : lineItem.quantity

    const updatedBasketLineItem = {
      ...basketItem,
      line_item: {
        ...lineItem,
        quantity: lineQty,
        issues: []
      },
      product_stock: {
        ...basketItem.product_stock,
        id: basketItem.product_stock?.id ?? '0'
      }
    }

    ReactGA.event('action_item', {
      item_list_id: 'basket_items',
      item_id: lineItem.id,
      action: 'update',
      changed_fields: ['quantity']
    })

    try {
      await update(updatedBasketLineItem)
    } catch {
      showToastMessage('danger', t('basket.update.error'))
    }
  }

  const handleRemove = async (lineItem: LineItem): Promise<void> => {
    const productUrl = `/purchasing/products/${lineItem.product.id}`

    try {
      ReactGA.event('action_item', {
        item_list_id: 'basket_items',
        item_id: lineItem.product.id,
        action: 'delete'
      })

      await remove(lineItem.id)

      showToastMessage(
        'dark',
        <p>
          <Trans
            i18nKey={t('basket.remove.success')}
            values={{ product: lineItem.product.description }}
            components={{
              ProductLink: <Link to={productUrl} />
            }}
          />
        </p>
      )
    } catch {
      showToastMessage('danger', t('basket.remove.error'))
    }
  }

  const handleCreateRequestClick = (lineItems: BasketLineItem[]): void => {
    setLineItemsToSubmit(lineItems)
  }

  const handleOrderRequestsCreated = (
    orderRequests?: Array<OrderRequestResource | undefined>
  ): void => {
    const createdOrderRequests = orderRequests?.filter(
      orderRequest => !!orderRequest
    )
    const noneCreated = createdOrderRequests?.length === 0
    const partialSuccess =
      createdOrderRequests?.length !== orderRequests?.length
    const singleSuccessful =
      createdOrderRequests?.length === 1 && orderRequests?.length === 1

    setLineItemsToSubmit([])

    if (noneCreated) {
      showToastMessage('danger', t('basket.order_request.result.none_created'))
      return
    }

    if (partialSuccess) {
      createdOrderRequests?.forEach(orderRequest => {
        showToastMessage(
          'dark',
          <p>
            <Trans
              i18nKey={t('basket.order_request.result.created')}
              values={{
                ref: orderRequest?.order_ref
              }}
              components={{
                OrderRequestLink: (
                  <Link to={`/purchasing/order-requests/${orderRequest?.id}`} />
                )
              }}
            />
          </p>
        )
      })
      showToastMessage(
        'danger',
        t('basket.order_request.result.some_not_created')
      )
      return
    }

    if (singleSuccessful) {
      navigate(`/purchasing/order-requests/${createdOrderRequests[0]?.id}`)
      return
    }

    const requestedByName = orderRequests?.[0]?.requested_by?.name
    const today = format(Date.now(), 'yyyy-MM-dd')
    navigate({
      pathname: '/purchasing/order-requests',
      search: createSearchParams({
        searchType: OrderRequestSearchType.AllOrderRequests,
        dateFrom: today,
        dateTo: today,
        filters: `status:New,requested_by:${requestedByName}`,
        sort: OrderRequestSortBy.CREATED_DATE,
        direction: SortDirection.DESC
      }).toString()
    })
  }

  const selectedItems = items.filter(({ line_item: item }) =>
    selectedItemIds.includes(item.id)
  )

  return (
    <Container fluid="auto" className={classNames('ma-page', styles.basket)}>
      <MaPageTitle>{t('basket_summary.title')}</MaPageTitle>

      <div className={styles.content}>
        {isLoading && <LoadingSpinner />}

        <BasketChangeNotifications
          changes={visibleBasketItemChanges}
          onNotificationClose={handleNotificationClose}
          onNotificationClick={handleNotificationClick}
        />

        <div className={styles.items}>
          <div className={styles['item-groups']}>
            {isEmpty && <EmptyBasketMessage />}

            {suppliers.map(supplier => (
              <section key={supplier} title={supplier}>
                <div className={styles['supplier-heading']}>
                  <MaCheckbox
                    checked={getSupplierSelectionState(supplier)}
                    onCheckedChange={checked => {
                      handleToggleSelectionBySupplier(supplier, checked)
                    }}
                  />

                  <h5>{supplier}</h5>
                </div>

                <div className={styles.cards} role="list">
                  {items
                    .filter(
                      item =>
                        item.line_item?.product.supplier?.name === supplier
                    )
                    .sort(
                      (a, b) =>
                        a.line_item?.product.description?.localeCompare(
                          b.line_item?.product.description ?? ''
                        ) ?? 0
                    )
                    .map(item => (
                      <div
                        key={item.line_item?.id ?? ''}
                        ref={el =>
                          cardRefs.current &&
                          (cardRefs.current[item.line_item.id] = el)
                        }
                        role="listitem"
                      >
                        <BasketLineItemCard
                          border={getHighlightType(
                            visibleBasketItemChanges,
                            item
                          )}
                          lineItem={item.line_item}
                          stockInfo={stockInfo.get(item.line_item?.id ?? '')}
                          isBusy={isBusy}
                          price={item.product_price}
                          selected={
                            !!selectedItemIds.includes(item.line_item.id)
                          }
                          onItemSelectedChange={handleItemSelectedChange}
                          onQuantityChange={(lineItem, quantity) => {
                            void handleUpdate(lineItem, quantity)
                          }}
                          onRemove={lineItem => {
                            void handleRemove(lineItem)
                          }}
                          onUpdate={lineItem => {
                            void handleUpdate(lineItem)
                          }}
                        />
                      </div>
                    ))}
                </div>
              </section>
            ))}
          </div>

          <div className={styles.summary}>
            {!isLoading && !isEmpty && (
              <>
                <BasketActionMenu
                  classes={styles['basket-action-menu']}
                  items={selectedItems}
                />
                <OrderRequestSummary
                  selectedItems={selectedItems}
                  onCreateRequest={handleCreateRequestClick}
                />
              </>
            )}
          </div>
        </div>
      </div>

      <OrderRequestFormModal
        lineItems={lineItemsToSubmit}
        onClose={() => {
          setLineItemsToSubmit([])
        }}
        onConfirm={handleOrderRequestsCreated}
      />
    </Container>
  )
}

export default Basket
