import { useState } from 'react'
import useSWRMutation from 'swr/mutation'
import {
  type OrderRequestsApiCreateOrderRequestRequest,
  type NewOrderRequest,
  type OrderRequestResource
} from '@amici/myamici-api-client'
import useApi from '../../common/hooks/useApi'
import useAccounts from '../../common/hooks/useAccounts'
import useBasketPreview from '../../basket/hooks/useBasketPreview'
import useBasketCount from '../../basket/hooks/useBasketCount'
import useBasket from '../../basket/hooks/useBasket'

interface UseCreateOrderRequestHook {
  isSubmitting: boolean
  createOrderRequest: (
    newOrderRequest: NewOrderRequest
  ) => Promise<OrderRequestResource | undefined>
}

function useCreateOrderRequest (): UseCreateOrderRequestHook {
  const { activeAccount } = useAccounts()
  const { mutate: updateBasket } = useBasket()
  const { mutate: updateBasketPreview } = useBasketPreview()
  const { mutate: updateBasketCount } = useBasketCount()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { orderRequestsApi, fetcher } = useApi()

  const accountId = activeAccount?.accountId ?? ''

  const { trigger } = useSWRMutation(
    accountId ? ['order-requests', accountId] : null,
    async (_, { arg }: { arg: OrderRequestsApiCreateOrderRequestRequest }) =>
      await fetcher(orderRequestsApi.createOrderRequest, arg),
    { populateCache: false, revalidate: false }
  )

  const createOrderRequest = async (
    newOrderRequest: NewOrderRequest
  ): Promise<OrderRequestResource | undefined> => {
    if (!accountId) {
      return
    }

    setIsSubmitting(true)

    const createdOrderRequest = await trigger({ accountId, newOrderRequest })
    await updateBasketCount()
    await updateBasketPreview()
    await updateBasket()

    setIsSubmitting(false)

    return createdOrderRequest
  }

  return {
    isSubmitting,
    createOrderRequest
  }
}

export default useCreateOrderRequest
