import { type ReactElement } from 'react'
import { Badge } from 'react-bootstrap'

interface IconCountBadgeProps {
  count: number
}

/**
 * Displays a badge with a count which can be added to an icon. The count value will be shown if it less than 100, in
 * which case "99+" is displayed.
 *
 * @param count The count value for the badge.
 * @constructor
 */
function IconCountBadge ({
  count
}: Readonly<IconCountBadgeProps>): ReactElement | null {
  if (count === undefined || count === 0) {
    return null
  }

  const displayedCount = count < 100 ? count : '99+'

  return (
    <Badge bg="danger" pill={true}>
      {displayedCount}
    </Badge>
  )
}

export default IconCountBadge
