import { type ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import MaConfirm from '../../common/components/MaConfirm'
import { Col, Form, Row } from 'react-bootstrap'
import { type Control, Controller, useForm } from 'react-hook-form'
import useAccounts from '../../common/hooks/useAccounts'
import { formatAddress } from '../../common/utils/format-address'
import { MaSelect, MaSelectItem } from '../../common/components/MaSelect'
import { AddressStatusEnum, type Address } from '@amici/myamici-api-client'
import FormControlTextArea from '../../common/components/FormControlTextArea'

export interface DeliveryInfo {
  addressId: string
  fao?: string
  note?: string
}

const NOTE_MAX_LENGTH = 1000

function AddressSelect ({
  value,
  onChange,
  addresses,
  disabled
}: Readonly<{
  value: string
  onChange: (value: string) => void
  addresses: Address[]
  disabled: boolean
}>): ReactElement {
  const { t } = useTranslation()

  const selectedAddress = addresses.find(address => address.id === value)

  const selectedValue =
    selectedAddress?.status === AddressStatusEnum.ACTIVE ? value : undefined

  const addressTitle =
    selectedAddress?.status === AddressStatusEnum.ACTIVE
      ? formatAddress(selectedAddress)
      : ''

  return (
    <MaSelect
      aria-label={t('order_request.form.label.address')}
      value={selectedValue}
      onValueChange={onChange}
      title={addressTitle}
      disabled={disabled}
      placeholder={formatAddress(selectedAddress)}
      isInvalid={selectedAddress?.status === AddressStatusEnum.INACTIVE}
      required
    >
      {addresses
        .filter(address => address.status === AddressStatusEnum.ACTIVE)
        .map(address => {
          const formattedAddress = formatAddress(address)
          return (
            <MaSelectItem
              key={address.id}
              value={address.id}
              title={formattedAddress}
            >
              {formattedAddress}
            </MaSelectItem>
          )
        })}
    </MaSelect>
  )
}

interface OrderRequestDeliveryInfoModalProps {
  show: boolean
  onClose: () => void
  onConfirm: (deliveryInfo: DeliveryInfo) => Promise<void>
  deliveryInfo: DeliveryInfo
  disabled: boolean
}

function OrderRequestEditDeliveryInfoModal ({
  show,
  onClose,
  onConfirm,
  deliveryInfo,
  disabled
}: Readonly<OrderRequestDeliveryInfoModalProps>): ReactElement {
  const { t } = useTranslation()
  const {
    register,
    watch,
    formState: { isValid },
    control,
    reset,
    getValues,
    setValue
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    values: {
      note: deliveryInfo.note ?? '',
      addressId: deliveryInfo.addressId,
      fao: deliveryInfo.fao
    }
  })
  const { accountProfile } = useAccounts()

  const handleClose = (): void => {
    reset()
    onClose()
  }
  const handleConfirm = async (): Promise<void> => {
    const values = getValues()
    setValue('fao', values.fao?.trim())
    setValue('note', values.note?.trim())
    await onConfirm(getValues())
  }

  const note = watch('note')

  return (
    <MaConfirm
      title={t('order_request.delivery_info.edit.title')}
      size="lg"
      show={show}
      onConfirm={handleConfirm}
      onClose={handleClose}
      closeLabel={t('common.button.labels.cancel')}
      disabled={disabled || !isValid}
    >
      <Form>
        <fieldset disabled={disabled}>
          <Row>
            <Form.Group className="mb-3" as={Col} lg={4} controlId="fao">
              <Form.Label>
                <Trans i18nKey="order_request.form.label.fao" />
              </Form.Label>
              <Form.Control {...register('fao')} maxLength={100} />
            </Form.Group>
            <Form.Group className="mb-3" as={Col} lg={8} controlId="addressId">
              <Form.Label column={false}>
                <Trans i18nKey="order_request.form.label.address" />
              </Form.Label>
              <Controller
                name="addressId"
                control={control}
                rules={{
                  validate: addressId =>
                    accountProfile?.client?.addresses?.find(
                      address => address.id === addressId
                    )?.status === AddressStatusEnum.ACTIVE
                }}
                render={({ field: { onChange, value } }) => (
                  <AddressSelect
                    value={value}
                    onChange={onChange}
                    addresses={accountProfile?.client?.addresses as Address[]}
                    disabled={disabled}
                  />
                )}
              />
            </Form.Group>
          </Row>
          <Row>
            <FormControlTextArea
              label={t('order_request.delivery_info.note_to_supplier')}
              value={note}
              name="note"
              maxLength={NOTE_MAX_LENGTH}
              control={control as unknown as Control}
              rows={4}
              required={false}
            />
          </Row>
        </fieldset>
      </Form>
    </MaConfirm>
  )
}

export default OrderRequestEditDeliveryInfoModal
