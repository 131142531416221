import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { type LineItemResource } from '@amici/myamici-api-client'
import {
  type Table,
  type TableState,
  type ColumnDef,
  useReactTable,
  getCoreRowModel
} from '@tanstack/react-table'
import { OutstandingOrderItemsColumnId } from '../types/outstanding-order-items-column-id'

function useOutstandingOrderItemsTable (
  lineItems: LineItemResource[],
  pageCount: number,
  state?: Partial<TableState>
): Table<LineItemResource> {
  const { t } = useTranslation()

  const columns = useMemo<Array<ColumnDef<LineItemResource, any>>>(
    () => [
      {
        id: OutstandingOrderItemsColumnId.REFERENCE,
        header: t('order.items.po_number'),
        accessorFn: row => row.order?.reference
      },
      {
        id: OutstandingOrderItemsColumnId.PART_NUMBER,
        header: t('order.items.part_number'),
        accessorFn: row => row.product.part_number
      },
      {
        id: OutstandingOrderItemsColumnId.DESCRIPTION,
        header: t('order.items.product'),
        accessorFn: row => row.product.description,
        enableSorting: false
      },
      {
        id: OutstandingOrderItemsColumnId.QUANTITY,
        header: t('order.items.quantity'),
        accessorKey: OutstandingOrderItemsColumnId.QUANTITY,
        accessorFn: row => row.quantity - (row?.quantity_received ?? 0),
        enableSorting: false
      },
      {
        id: OutstandingOrderItemsColumnId.SUPPLIER,
        header: t('order.items.supplier'),
        accessorFn: row => row.product.supplier?.name
      },
      {
        id: OutstandingOrderItemsColumnId.ESTIMATED_DELIVERY_DATE,
        header: t('order.items.delivery_date'),
        accessorFn: ({
          estimated_delivery_date: estimatedDeliveryDate
        }): string | undefined =>
          estimatedDeliveryDate &&
          new Date(estimatedDeliveryDate).toLocaleDateString('en-GB')
      },
      {
        id: OutstandingOrderItemsColumnId.ISSUES,
        header: t('order.items.issues'),
        enableSorting: false,
        accessorFn: (row): string =>
          row.issues
            ? row.issues
                .map((issue: string) =>
                  t(`order.items.issue.${issue}`, {
                    on_hold_date: new Date(row.on_hold_date ?? ''),
                    formatParams: {
                      on_hold_date: {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric'
                      }
                    }
                  })
                )
                .join(', ')
            : ''
      }
    ],
    [t]
  )

  const table = useReactTable<LineItemResource>({
    data: lineItems,
    pageCount,
    columns,
    state,
    getCoreRowModel: getCoreRowModel(),
    manualFiltering: true,
    manualSorting: true,
    manualPagination: true
  })

  return table
}

export default useOutstandingOrderItemsTable
