import { type HTMLAttributes, type ReactElement } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import type MaTab from '../models/MaTab'
import styles from '../assets/scss/MaTabs.module.scss'

interface MaTabsProps extends HTMLAttributes<HTMLDivElement> {
  tabs: MaTab[]
  activeTab: string
  onSelectTab?: (tabKey: string) => void
}

function MaTabs ({
  tabs,
  activeTab,
  onSelectTab
}: Readonly<MaTabsProps>): ReactElement {
  const { t } = useTranslation()

  return (
    <div className={styles['ma-tabs']}>
      <Tabs
        className={styles.tabs}
        activeKey={activeTab}
        onSelect={eventKey => {
          if (onSelectTab) onSelectTab(eventKey as string)
        }}
      >
        {tabs.map(tab => (
          <Tab
            key={tab.key}
            eventKey={tab.key}
            title={
              <span className={classNames({ 'no-data': !tab.hasData })}>
                {t(`tabs.tab.title.${tab.key}`)}
              </span>
            }
          >
            {tab.component}
          </Tab>
        ))}
      </Tabs>
    </div>
  )
}

export default MaTabs
