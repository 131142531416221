import {
  type Supplier,
  type BasketLineItem,
  type OrderRequestLineItem
} from '@amici/myamici-api-client'
import { useCallback, useMemo } from 'react'

interface UseOrderRequestSummaryHook<T> {
  suppliers: Supplier[]
  supplierIds: string[]
  supplierNames: string[]
  currencies: string[]
  getSupplierCurrencies: (supplier: Supplier) => string[]
  getCurrencySuppliers: (currency: string) => Supplier[]
  getSupplierItems: (supplier: Supplier, currency: string) => T[]
  getSupplierTotal: (supplier: Supplier, currency: string) => number
  getSupplierMinOrderCharge: (supplier: Supplier, currency: string) => number
  getSupplierSurcharges: (supplier: Supplier, currency: string) => number
  getSupplierDeliveryCharge: (supplier: Supplier, currency: string) => number
  getSupplierSubtotal: (supplier: Supplier, currency: string) => number
  getTotalItemCount: (currency: string) => number
  getTotalSurcharges: (currency: string) => number
  getItemsTotal: (currency: string) => number
  getTotal: (currency: string) => number
}

function useOrderRequestSummary<
  T extends BasketLineItem | OrderRequestLineItem
> (lineItems: T[]): UseOrderRequestSummaryHook<T> {
  /**
   * Returns a list of unique supplier ids for all provided line items.
   * @returns {Array<string>}
   */
  const supplierIds = useMemo(
    () =>
      [
        ...lineItems.reduce(
          (suppliers, item) =>
            suppliers.add(item.line_item?.product.supplier?.id ?? ''),
          new Set<string>()
        )
      ].filter(id => !!id),
    [lineItems]
  )

  /**
   * A list of unique supplier names from all provided line items,
   * sorted alphabetically.
   * @returns {Array<string>}
   */
  const supplierNames = useMemo(
    () =>
      [
        ...lineItems.reduce(
          (suppliers, item) =>
            suppliers.add(item.line_item?.product.supplier?.name ?? ''),
          new Set<string>()
        )
      ]
        .filter(s => !!s)
        .sort((a, b) => a.localeCompare(b)),
    [lineItems]
  )

  /**
   * A list of unique supplier objects from all provided line items,
   * sorted alphabetically by their names.
   * @returns {Array<Supplier>}
   */
  const suppliers = useMemo<Supplier[]>(
    () =>
      supplierIds
        .map(
          id =>
            lineItems.find(
              ({
                line_item: {
                  product: { supplier }
                }
              }) => supplier?.id === id
            )?.line_item.product.supplier as Supplier
        )
        .filter(s => !!s)
        .sort((a, b) => (a?.name ?? '').localeCompare(b?.name ?? '')),
    [lineItems, supplierIds]
  )

  /**
   * Returns all the unique currencies of the provided line items.
   * @returns {Array<string>}
   */
  const currencies = useMemo(
    () =>
      [
        ...lineItems.reduce(
          (currencies, item) =>
            currencies.add(item.line_item.currency ?? 'GBP'),
          new Set<string>()
        )
      ].sort((a, b) => a.localeCompare(b)),
    [lineItems]
  )

  /**
   * Returns all the suppliers using the provided currency.
   * @param {string} currency
   * @returns {Array<Supplier>}
   */
  const getCurrencySuppliers = useCallback(
    (currency: string): Supplier[] =>
      [
        ...lineItems
          .filter(({ line_item: item }) => item.currency === currency)
          .reduce(
            (suppliers, item) =>
              suppliers.add(item.line_item.product.supplier?.id ?? ''),
            new Set<string>()
          )
      ]
        .filter(s => !!s)
        .map(id => suppliers.find(supplier => supplier.id === id) as Supplier)
        .sort((a, b) => (a?.name ?? '').localeCompare(b?.name ?? '')),
    [lineItems, suppliers]
  )

  /**
   * Returns a list of all available currencies for a supplier.
   * @param {Supplier} supplier
   * @returns {Array<string>}
   */
  const getSupplierCurrencies = useCallback(
    (supplier: Supplier): string[] => [
      ...lineItems
        .filter(
          ({ line_item: item }) => item.product.supplier?.id === supplier.id
        )
        .reduce(
          (currencies, item) =>
            currencies.add(item.line_item.currency ?? 'GBP'),
          new Set<string>()
        )
    ],
    [lineItems]
  )

  /**
   * Returns line items for a specific supplier and used currency.
   * @param {Supplier} supplier
   * @param {string} currency
   * @returns {Array<T>}
   */
  const getSupplierItems = useCallback(
    (supplier: Supplier, currency: string): T[] =>
      lineItems
        .filter(
          ({ line_item: item }) =>
            item.currency === currency &&
            item.product.supplier?.id === supplier.id
        )
        .sort((a, b) => {
          const nameA = a.line_item.product.description ?? ''
          const nameB = b.line_item.product.description ?? ''

          return nameA?.localeCompare(nameB)
        }),
    [lineItems]
  )

  /**
   * Returns the total value of items for a specific supplier and used currency.
   * @param {Supplier} supplier
   * @param {string} currency
   * @returns {number}
   */
  const getSupplierTotal = useCallback(
    (supplier: Supplier, currency: string): number =>
      getSupplierItems(supplier, currency).reduce(
        (total, item) =>
          total + (item.product_price ?? 0) * item.line_item.quantity,
        0
      ),
    [getSupplierItems]
  )

  /**
   * Returns a minimal order charge value for a specific supplier and the currency used
   * based on the requested value.
   * @param {Supplier} supplier
   * @param {string} currency
   * @returns {number}
   */
  const getSupplierMinOrderCharge = useCallback(
    (supplier: Supplier, currency: string): number => {
      const items = getSupplierItems(supplier, currency)

      if (
        items.some(
          ({ line_item: item }) =>
            item.currency !== (item.product.supplier?.currency ?? 'GBP')
        )
      ) {
        // for some items product currency does not match supplier currency
        return -1
      }

      const minOrderThreshold = supplier.min_order_threshold ?? 0
      const minOrderCharge = supplier.min_order_charge ?? 0

      return getSupplierTotal(supplier, currency) >= minOrderThreshold
        ? 0
        : minOrderCharge
    },
    [getSupplierItems, getSupplierTotal]
  )

  /**
   * Returns a total surcharge value for a specific supplier and used currency.
   * @param {Supplier} supplier
   * @param {string} currency
   * @returns {number}
   */
  const getSupplierSurcharges = useCallback(
    (supplier: Supplier, currency: string): number => {
      const items = getSupplierItems(supplier, currency)

      if (
        items.some(
          ({ line_item: item }) =>
            item.currency !== (item.product.supplier?.currency ?? 'GBP')
        )
      ) {
        // for some items product currency does not match supplier currency
        return -1
      }

      const productCharge = Math.max(
        ...items.map(
          ({ line_item: { product } }) => product.carriage_charge ?? 0
        )
      )

      const supplierCharge = Math.max(
        ...items.map(
          ({
            line_item: {
              product: { supplier }
            }
          }) => supplier?.standard_carriage_charge ?? 0
        )
      )

      const minOrderCharge = getSupplierMinOrderCharge(supplier, currency)

      return (productCharge || supplierCharge) + minOrderCharge
    },
    [getSupplierItems, getSupplierMinOrderCharge]
  )

  /**
   * Returns only the delivery charge value for a specific supplier and used currency..
   * @param {Supplier} supplier
   * @param {string} currency
   * @returns {number}
   */
  const getSupplierDeliveryCharge = useCallback(
    (supplier: Supplier, currency: string): number => {
      const supplierSurcharges = getSupplierSurcharges(supplier, currency)

      return supplierSurcharges > 0
        ? supplierSurcharges - getSupplierMinOrderCharge(supplier, currency)
        : supplierSurcharges
    },
    [getSupplierMinOrderCharge, getSupplierSurcharges]
  )

  /**
   * Returns supplier subtotal, including total value of items and surcharges
   * for a specific supplier and used currency.
   * @param {Supplier} supplier
   * @param {string} currency
   * @returns {number}
   */
  const getSupplierSubtotal = useCallback(
    (supplier: Supplier, currency: string): number => {
      const itemsTotal = getSupplierTotal(supplier, currency)
      const surcharges = getSupplierSurcharges(supplier, currency)

      return surcharges > 0 ? itemsTotal + surcharges : itemsTotal
    },
    [getSupplierSurcharges, getSupplierTotal]
  )

  /**
   * Returns a total number of items from all suppliers.
   * @param {string} currency
   * @returns {number}
   */
  const getTotalItemCount = useCallback(
    (currency: string): number =>
      suppliers.reduce(
        (count, supplier) =>
          count + getSupplierItems(supplier, currency).length,
        0
      ),
    [getSupplierItems, suppliers]
  )

  /**
   * Returns the value of total surcharges for all suppliers.
   * @param {string} currency
   * @returns {number}
   */
  const getTotalSurcharges = useCallback(
    (currency: string): number =>
      getCurrencySuppliers(currency).reduce(
        (total, supplier) =>
          total +
          (getSupplierSurcharges(supplier, currency) > 0
            ? getSupplierSurcharges(supplier, currency)
            : 0),
        0
      ),
    [getCurrencySuppliers, getSupplierSurcharges]
  )

  /**
   * Returns the total value of all items, excluding surcharges for all suppliers.
   * @param {string} currency
   * @returns {number}
   */
  const getItemsTotal = useCallback(
    (currency: string): number =>
      suppliers.reduce(
        (total, supplier) => total + getSupplierTotal(supplier, currency),
        0
      ),
    [getSupplierTotal, suppliers]
  )

  /**
   * Returns the total value of all items, including surcharges for all suppliers.
   * @param {string} currency
   * @returns {number}
   */
  const getTotal = useCallback(
    (currency: string): number =>
      getItemsTotal(currency) + getTotalSurcharges(currency),
    [getItemsTotal, getTotalSurcharges]
  )

  return {
    suppliers,
    supplierIds,
    supplierNames,
    currencies,
    getSupplierCurrencies,
    getCurrencySuppliers,
    getSupplierItems,
    getSupplierTotal,
    getSupplierMinOrderCharge,
    getSupplierSurcharges,
    getSupplierDeliveryCharge,
    getSupplierSubtotal,
    getTotal,
    getTotalItemCount,
    getTotalSurcharges,
    getItemsTotal
  }
}

export default useOrderRequestSummary
