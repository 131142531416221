import { type ReactElement } from 'react'
import { ReactComponent as AmiciLogoRotate } from '../assets/images/amici-logo-rotate.svg'
import { useTranslation } from 'react-i18next'

function LoadingSpinner (): ReactElement {
  const { t } = useTranslation()
  return (
    <div
      className="d-flex justify-content-center"
      aria-label={t('message.loading')}
    >
      <AmiciLogoRotate />
    </div>
  )
}

export default LoadingSpinner
