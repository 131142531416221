import { type ReactElement, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import SpendCategoryHistoryTable from './SpendCategoryHistoryTable'
import styles from '../assets/scss/SpendCategoryHistory.module.scss'
import MaPanel from '../../common/components/MaPanel'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import useSpendCategoryHistory from '../hooks/useSpendCategoryHistory'
import useMinWidthObserver from '../../common/hooks/useMinWidthObserver'
import SpendCategoryHistoryList from './SpendCategoryHistoryList'

const FULL_VIEW_MIN_WIDTH_PX = 600

function SpendCategoryHistoryPanel (): ReactElement {
  const ref = useRef(null)
  const isFullView = useMinWidthObserver(ref, FULL_VIEW_MIN_WIDTH_PX)
  const { t } = useTranslation()
  const { entries, isLoading } = useSpendCategoryHistory()

  const HistoryEntriesView = (
    <>
      {entries &&
        (isFullView ? (
          <SpendCategoryHistoryTable entries={entries} />
        ) : (
          <SpendCategoryHistoryList entries={entries} />
        ))}
    </>
  )
  return (
    <div ref={ref} className={styles.content}>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <MaPanel className={styles.panel}>
          <h2 className={styles.header}>
            {t('spend_categories.history.title')}
          </h2>
          <div className={styles.view}>{HistoryEntriesView}</div>
        </MaPanel>
      )}
    </div>
  )
}

export default SpendCategoryHistoryPanel
