import { useTranslation } from 'react-i18next'
import { enGB, enUS } from 'date-fns/locale'

function useDateLocale (): Locale | undefined {
  const { i18n } = useTranslation()

  return {
    'en-GB': enGB,
    'en-US': enUS
  }[i18n.resolvedLanguage ?? 'en-GB']
}

export default useDateLocale
