import { type ReactElement } from 'react'
import { type LineItem } from '@amici/myamici-api-client'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SmilesSvgRenderer } from 'react-ocl/minimal.js'
import FallbackImage from '../../common/components/FallbackImage'
import styles from '../assets/scss/BasketPreviewItem.module.scss'

function BasketPreviewItem ({
  item
}: Readonly<{ item?: LineItem }>): ReactElement | null {
  const { t } = useTranslation()

  if (!item) {
    return null
  }

  return (
    <div className={styles.item}>
      <div className={styles['img-wrapper']}>
        {item.product.product_image_url && (
          <FallbackImage
            className={styles.img}
            src={item.product.product_image_url ?? ''}
          />
        )}
        {!item.product.product_image_url && item.product.smiles && (
          <SmilesSvgRenderer
            width={40}
            height={40}
            smiles={item.product?.smiles ?? ''}
            ErrorComponent={FallbackImage}
          />
        )}
      </div>

      <div className={styles.info}>
        <Link to={`/purchasing/products/${item.product?.id}`}>
          {item.product.description}
        </Link>

        <div className={styles.summary}>
          <span>
            {t('common.price', {
              price: item.price,
              currency: item.currency ?? 'GBP'
            })}
          </span>
          <span className={styles.quantity}>
            {t('basket_preview.quantity', { quantity: item.quantity })}
          </span>
        </div>
      </div>
    </div>
  )
}

export default BasketPreviewItem
