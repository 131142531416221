import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styles from '../assets/scss/Basket.module.scss'

function EmptyBasketMessage (): ReactElement {
  const { t } = useTranslation()

  return (
    <div className={styles['empty-basket-message']}>
      <h5>{t('basket.empty_basket.title')}</h5>

      <p>{t('basket.empty_basket.message')}</p>

      <Link to="/purchasing/products" className="btn btn-primary rounded">
        {t('basket.empty_basket.browse_products')}
      </Link>
    </div>
  )
}

export default EmptyBasketMessage
