import { type ReactElement } from 'react'
import { t } from 'i18next'
import { BsBasket3 } from 'react-icons/bs'
import useIsMobile from '../../common/hooks/useIsMobile'
import useBasketCount from '../hooks/useBasketCount'
import MaIconButton from '../../common/components/MaIconButton'
import useBasketSettings from '../hooks/useBasketSettings'

function BasketIcon (): ReactElement {
  const { basketCount } = useBasketCount()
  const { basketUrl, target } = useBasketSettings()
  const isMobile = useIsMobile()

  return (
    <MaIconButton
      variant={isMobile ? 'secondary' : 'primary'}
      to={basketUrl}
      target={target}
      count={basketCount}
      title={t('basket.title') && 'Basket'}
    >
      <BsBasket3 size={24} />
    </MaIconButton>
  )
}

export default BasketIcon
