import { forwardRef, type HTMLAttributes, type ReactElement } from 'react'
import * as Checkbox from '@radix-ui/react-checkbox'
import { BsCheck, BsDash, BsSquare } from 'react-icons/bs'
import classNames from 'classnames'
import '../assets/scss/MaCheckbox.scss'

export type CheckedState = boolean | 'indeterminate'

const MaCheckbox = forwardRef<
  React.ElementRef<typeof Checkbox.Root & HTMLAttributes<HTMLButtonElement>>,
  React.ComponentPropsWithoutRef<
    typeof Checkbox.Root & HTMLAttributes<HTMLButtonElement>
  >
>(function MaCheckbox (
  { checked, onCheckedChange, className, ...props }: Checkbox.CheckboxProps,
  ref
): ReactElement {
  return (
    <Checkbox.Root
      ref={ref}
      className={classNames('ma-checkbox', className)}
      checked={checked}
      onCheckedChange={onCheckedChange}
      {...props}
    >
      <Checkbox.Indicator>
        {checked === 'indeterminate' && <BsDash size={16} />}
        {checked === true && <BsCheck size={16} />}
        {checked === false && <BsSquare size={16} />}
      </Checkbox.Indicator>
    </Checkbox.Root>
  )
})

export default MaCheckbox
