import { type ReactElement, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { BsInfoCircle } from 'react-icons/bs'
import { type ProductResource } from '@amici/myamici-api-client'
import { type Product } from '@amici/myamici-search-client'
import { ProductSearchType } from '../types/product-search-type'
import { type ProductSortType } from '../types/product-sort-type'
import useProductSearch from '../hooks/useProductSearch'
import useIsMobile from '../../common/hooks/useIsMobile'
import useProductSpecifications from '../hooks/useProductSpecifications'
import MaTooltip from '../../common/components/MaTooltip'
import ProductCard from './ProductCard'
import MaProductCarousel from '../../common/components/MaProductCarousel'
import styles from '../assets/scss/ProductAlternatives.module.scss'

const MAX_TOTAL_RESULTS = 9

function ProductAlternatives ({
  product
}: Readonly<{
  product?: ProductResource
}>): ReactElement {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const { data, isLoading: isSearching, setSearchParams } = useProductSearch()
  const { data: productSpecs } = useProductSpecifications(product?.id)

  const altProducts = data?.results ?? []

  useEffect(() => {
    setSearchParams({
      productId: product?.id,
      term: '',
      type: ProductSearchType.Alternatives,
      sort: 'default' as ProductSortType,
      page: 1,
      pageSize: MAX_TOTAL_RESULTS
    })
  }, [product?.id, setSearchParams])

  const altProductSearchLink = `/purchasing/products?productId=${
    product?.id ?? ''
  }`
  const showSearchAllLink = (data?.totalElements ?? 0) > MAX_TOTAL_RESULTS

  const matchedOnFieldText = (
    product: ProductResource,
    altProduct: Product
  ): string | undefined => {
    const getProductSpec = (specName: string): string | null | undefined =>
      productSpecs?.content?.find(spec => spec.name === specName)?.value

    if (
      !!product.manufacturer_part_number &&
      !!altProduct.manufacturePartNo &&
      product.manufacturer_part_number === altProduct.manufacturePartNo
    ) {
      return t('product.details.alternatives.matched_on.mpn')
    }

    if (
      !!getProductSpec('CAS number') &&
      !!altProduct.casNumber &&
      getProductSpec('CAS number') === altProduct.casNumber
    ) {
      return t('product.details.alternatives.matched_on.cas')
    }

    if (
      !!getProductSpec('SMILES') &&
      !!altProduct.smiles &&
      getProductSpec('SMILES') === altProduct.smiles
    ) {
      return t('product.details.alternatives.matched_on.smiles')
    }

    if (
      !!product.part_number &&
      !!altProduct.supplierPartNo &&
      product.part_number === altProduct.supplierPartNo
    ) {
      return t('product.details.alternatives.matched_on.spn')
    }
  }

  return (
    <div className={styles.alternatives}>
      <div className={styles.title}>
        <h4>{t('product.details.alternatives.title')}</h4>

        <MaTooltip
          text={
            <div className={styles['tooltip-text']}>
              {t('product.details.alternatives.info')}
            </div>
          }
          placement={isMobile && !showSearchAllLink ? 'left' : 'top'}
          trigger={['hover', 'click']}
          className={styles.tooltip}
        >
          <BsInfoCircle size={16} />
        </MaTooltip>

        {showSearchAllLink && (
          <Link to={altProductSearchLink}>
            {t('product.details.alternatives.link')}
          </Link>
        )}
      </div>

      {isSearching && (
        <p className="text-center">
          {t('product.details.alternatives.searching')}
        </p>
      )}

      {!isSearching && altProducts.length < 1 && (
        <>
          <p className="text-center">
            {t('product.details.alternatives.nothing_found.part_1')}
          </p>
          <p className="text-center">
            {t('product.details.alternatives.nothing_found.part_2')}
          </p>
        </>
      )}

      {altProducts.length > 0 && (
        <MaProductCarousel>
          {altProducts.map(altProduct => (
            <div key={altProduct.id} className={styles['alt-product']}>
              <ProductCard
                product={altProduct}
                variant="alternative"
                currentPage={0}
              />

              <h6 className={styles['matched-on']}>
                {product && matchedOnFieldText(product, altProduct)}
              </h6>
            </div>
          ))}
        </MaProductCarousel>
      )}
    </div>
  )
}

export default ProductAlternatives
