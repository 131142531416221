import useApi from '../../common/hooks/useApi'
import useSWRImmutable from 'swr/immutable'
import useAccounts from '../../common/hooks/useAccounts'
import { type SupplierResource } from '@amici/myamici-api-client'

interface UseSupplierHook {
  isLoading: boolean
  error: any
  data?: SupplierResource
}

function useSupplier (supplierId: string | null | undefined): UseSupplierHook {
  const { activeAccount } = useAccounts()
  const { suppliersApi, fetcher } = useApi()

  const accountId = activeAccount?.accountId ?? ''

  const { data, error, isLoading } = useSWRImmutable(
    supplierId && accountId ? ['supplier', supplierId, accountId] : null,
    async () =>
      await fetcher(suppliersApi.getSupplier, {
        supplierId: supplierId ?? '',
        accountId
      })
  )

  return {
    isLoading,
    error,
    data
  }
}

export default useSupplier
