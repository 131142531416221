import { type ReactElement } from 'react'
import { type ProductResource } from '@amici/myamici-api-client'
import classNames from 'classnames'
import useIsMobile from '../../common/hooks/useIsMobile'
import CoshhAndLicenceSection from './CoshhAndLicenceSection'
import HazardsAndRestrictionsSection from './HazardsAndRestrictionsSection'
import styles from '../assets/scss/ProductDetailsTabHazards.module.scss'

function ProductDetailsTabHazards ({
  product
}: Readonly<{
  product?: ProductResource
}>): ReactElement | null {
  const isMobile = useIsMobile()

  if (!product) {
    return null
  }

  return (
    <div
      className={classNames(styles['hazards-tab'], {
        [styles.mobile]: isMobile
      })}
    >
      <CoshhAndLicenceSection productId={product.id} />
      <hr />
      <HazardsAndRestrictionsSection product={product} />
    </div>
  )
}

export default ProductDetailsTabHazards
