export enum UserModule {
  HasAssetModule = 'HasAssetModule',
  HasFinanceModule = 'HasFinanceModule',
  HasFinancialControlModule = 'HasFinancialControlModule',
  HasHSEControlModule = 'HasHSEControlModule',
  HasIntegrationModule = 'HasIntegrationModule',
  HasInventoryModule = 'HasInventoryModule',
  HasNextGen = 'HasNextGen',
  HasQualityModule = 'HasQualityModule',
  HasReportingModule = 'HasReportingModule',
  HasStockCheckModule = 'HasStockCheckModule',
  IsDeliveryModuleUser = 'IsDeliveryModuleUser'
}
