import { type HTMLAttributes, type ReactElement } from 'react'
import { BsExclamationTriangleFill } from 'react-icons/bs'
import styles from '../assets/scss/MaWarning.module.scss'

interface MaWarningProps extends HTMLAttributes<HTMLElement> {
  message: string
}

function MaWarning ({ message }: Readonly<MaWarningProps>): ReactElement {
  return (
    <div className={styles.warning}>
      <BsExclamationTriangleFill size={32} />
      <p>{message}</p>
    </div>
  )
}

export default MaWarning
