import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import useIsMobile from '../../common/hooks/useIsMobile'
import useOutstandingLineItems from '../hooks/useOutstandingLineItems'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import MaPieChart from '../../common/components/MaPieChart'
import styles from '../assets/scss/OrdersAndItems.module.scss'

const colours: Record<string, string> = {
  empty: '#cdcdcd',
  overdue: '#cf012e',
  scheduled: '#3eaf3f',
  pending: '#f98f2e'
}

function OutstandingLineItemsChart (): ReactElement {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  const { data, isLoading } = useOutstandingLineItems()

  const navigateToOutstandingOrderItemsPage = (preset?: string): void => {
    if (data?.total !== undefined && data.total < 1) {
      return
    }

    navigate({
      pathname: '/purchasing/outstanding-order-items',
      ...(preset && { search: `?preset=${preset}` })
    })
  }

  return (
    <div
      className={classNames(styles['chart-widget'], {
        [styles.mobile]: isMobile
      })}
    >
      <h4 className={styles.title}>
        {t('home.chart.outstanding_line_items.title')}
      </h4>

      <div className={styles['chart-wrapper']}>
        {isLoading && <LoadingSpinner />}

        {!isLoading && (
          <MaPieChart
            data={data}
            colours={colours}
            className={styles.chart}
            noItemsText={t('home.chart.outstanding_line_items.no_items')}
            legendFormatter={(value: string | number) =>
              t(`home.chart.outstanding_line_items.${value}`)
            }
            tooltipFormatter={(value: number, name: string) =>
              t(`home.chart.outstanding_line_items.tooltip.${name}`, {
                count: value
              })
            }
            onTotalClick={() => {
              navigateToOutstandingOrderItemsPage()
            }}
            onSegmentClick={({ name }) => {
              navigateToOutstandingOrderItemsPage(name)
            }}
          />
        )}
      </div>
    </div>
  )
}

export default OutstandingLineItemsChart
