import useSWRImmutable from 'swr/immutable'
import { type ProductPriceResource } from '@amici/myamici-api-client'
import useApi from '../../common/hooks/useApi'
import useAccounts from '../../common/hooks/useAccounts'

interface UseProductPriceHook {
  currency: string | null
  listPrice: number | null
  bestPrice: number | null
  taxCode: string | null
}

function useProductPrice (
  productId: string,
  currency: string | null,
  listPrice: number | null,
  bestPrice: number | null
): UseProductPriceHook {
  const {
    productsApi: { getCurrentPrice },
    fetcher
  } = useApi()
  const { activeAccount } = useAccounts()

  const accountId = activeAccount?.accountId ?? ''

  const {
    data,
    isLoading
  }: { data?: ProductPriceResource; isLoading: boolean } = useSWRImmutable(
    productId && accountId ? ['current-price', productId, accountId] : null,
    async () =>
      await fetcher(getCurrentPrice, {
        productId,
        accountId
      }),
    { shouldRetryOnError: false }
  )

  if (isLoading || data == null) {
    return {
      currency,
      listPrice,
      bestPrice,
      taxCode: null
    }
  }

  return {
    currency: data.currency,
    listPrice: data.list_price ?? null,
    bestPrice: data.best_price ?? null,
    taxCode: data.tax_code ?? null
  }
}

export default useProductPrice
