import { type ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Table } from 'react-bootstrap'
import { BsShieldExclamation } from 'react-icons/bs'
import { type LineItemResource, type Order } from '@amici/myamici-api-client'
import classNames from 'classnames'
import { format, subYears } from 'date-fns'
import { useEnv } from '../../common/hooks/useEnv'
import useIsMobile from '../../common/hooks/useIsMobile'
import usePurchaseHistory from '../hooks/usePurchaseHistory'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import styles from '../assets/scss/ProductDetails.module.scss'

const MAX_HISTORY_ITEMS = 10

function OrderLink ({ order }: Readonly<{ order: Order }>): ReactElement {
  const { t } = useTranslation()
  const orderUrl = `/purchasing/orders/${order.id}`

  return (
    <span>
      <Link to={orderUrl}>{order.reference}</Link>

      {order.confidential && (
        <span
          className={styles['confidential-icon']}
          title={t('product.details.tabs.history.confidential')}
        >
          <BsShieldExclamation size={16} className="text-danger" />
        </span>
      )}
    </span>
  )
}

function FullView ({
  lineItems
}: Readonly<{
  lineItems: LineItemResource[]
}>): ReactElement {
  const { t } = useTranslation()

  return (
    <Table className={styles.full} role="product-purchase-history">
      <thead>
        <tr>
          <th>{t('product.details.tabs.history.ordered_date')}</th>
          <th>{t('product.details.tabs.history.purchase_order')}</th>
          <th>{t('product.details.tabs.history.quantity')}</th>
          <th>{t('product.details.tabs.history.ordered_by')}</th>
          <th>{t('product.details.tabs.history.date_received')}</th>
        </tr>
      </thead>
      <tbody>
        {lineItems.map(lineItem => (
          <tr key={lineItem.id}>
            <td>
              {t('datetime', {
                datetime: new Date(lineItem.order?.order_date as string)
              })}
            </td>
            <td>
              {lineItem.order?.reference ? (
                <OrderLink order={lineItem.order} />
              ) : (
                '-'
              )}
            </td>
            <td>{lineItem.quantity ?? '-'}</td>
            <td>{lineItem.order?.ordered_by?.name ?? '-'}</td>
            <td>
              {lineItem.date_received
                ? t('date', {
                    date: new Date(lineItem.date_received)
                  })
                : '-'}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

function CompactView ({
  lineItems
}: Readonly<{
  lineItems: LineItemResource[]
}>): ReactElement {
  const { t } = useTranslation()

  return (
    <>
      {lineItems.map(lineItem => (
        <div key={lineItem.id} className={styles.compact}>
          <Table key={lineItem.id}>
            <tbody>
              <tr>
                <th>{t('product.details.tabs.history.ordered_date')}</th>
                <td>
                  {t('datetime', {
                    datetime: new Date(lineItem.order?.order_date as string)
                  })}
                </td>
              </tr>
              <tr>
                <th>{t('product.details.tabs.history.purchase_order')}</th>
                <td>
                  {lineItem.order?.reference ? (
                    <OrderLink order={lineItem.order} />
                  ) : (
                    '-'
                  )}
                </td>
              </tr>
              <tr>
                <th>{t('product.details.tabs.history.quantity')}</th>
                <td>{lineItem.quantity ?? '-'}</td>
              </tr>
              <tr>
                <th>{t('product.details.tabs.history.ordered_by')}</th>
                <td>{lineItem.order?.ordered_by?.name ?? '-'}</td>
              </tr>
              <tr>
                <th>{t('product.details.tabs.history.date_received')}</th>
                <td>
                  {lineItem.date_received
                    ? t('date', {
                        date: new Date(lineItem.date_received)
                      })
                    : '-'}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      ))}
    </>
  )
}

function ProductDetailsTabPurchaseHistory ({
  productId
}: Readonly<{
  productId?: string
}>): ReactElement | null {
  const { REACT_APP_MA3_BASE_URL } = useEnv()
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const { data, isLoading, error } = usePurchaseHistory(productId)

  if (!productId) {
    return null
  }

  const totalElements = data?.totalElements ?? 0
  const lineItems = data?.content ?? []

  const showError: boolean = !isLoading && error
  const showHistory: boolean = totalElements > 0 && !isLoading && !error
  const showFullView: boolean = showHistory && !isMobile
  const showCompactView: boolean = showHistory && isMobile
  const showViewMore: boolean =
    !isLoading && !error && totalElements > MAX_HISTORY_ITEMS

  const orderDateFrom = format(subYears(new Date(), 5), 'yyyy-MM-dd')
  const fullHistoryUrl = `${
    (REACT_APP_MA3_BASE_URL as string) ?? ''
  }/Order/YourOrderList.aspx?Usage=OrderHistory&OrderLines=true&OrderDateFrom=${orderDateFrom}&ProductId=${productId}`

  return (
    <div
      className={classNames(styles['purchase-history'], {
        [styles.mobile]: isMobile
      })}
    >
      {isLoading && <LoadingSpinner />}

      {showError && (
        <p className="text-danger">{t('product.details.tabs.history.error')}</p>
      )}

      {!showHistory && <p>{t('product.details.tabs.history.no_record')}</p>}

      {showFullView && <FullView lineItems={lineItems} />}
      {showCompactView && <CompactView lineItems={lineItems} />}

      {showViewMore && (
        <p>
          <Link to={fullHistoryUrl} target="_blank">
            {t('product.details.tabs.history.view_more')}
          </Link>
        </p>
      )}
    </div>
  )
}

export default ProductDetailsTabPurchaseHistory
