import { type RouteObject } from 'react-router-dom'
import NotificationsPage from './pages/NotificationsPage'

const notificationsRoutes: RouteObject[] = [
  {
    path: 'notifications',
    element: <NotificationsPage />
  }
]

export default notificationsRoutes
