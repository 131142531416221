import { type ReactElement, useEffect, useState } from 'react'
import { format, addYears, getYear } from 'date-fns'
import { DayPicker, type DayPickerSingleProps } from 'react-day-picker'
import { Button, OverlayTrigger, Popover } from 'react-bootstrap'
import { type Placement } from 'react-bootstrap/esm/types'
import { BsCalendar2Week } from 'react-icons/bs'
import classNames from 'classnames'
import useDateLocale from '../hooks/useDateLocale'
import styles from '../assets/scss/MaDatePicker.module.scss'

function MaDatePicker ({
  selected,
  onSelect,
  placement = 'auto',
  container,
  isInvalid,
  ...props
}: Partial<DayPickerSingleProps> & {
  readonly placement?: Placement
  readonly container?: HTMLElement
  readonly isInvalid?: boolean
}): ReactElement {
  const [show, setShow] = useState(false)
  const dateLocale = useDateLocale()

  useEffect(() => {
    setShow(false)
  }, [selected])

  const popover = (
    <Popover className={styles.popover}>
      <Popover.Body className={styles['popover-body']}>
        <DayPicker
          mode={'single'}
          selected={selected}
          captionLayout={'dropdown-buttons'}
          fromYear={2000}
          toYear={getYear(addYears(new Date(), 5))}
          defaultMonth={selected ?? new Date()}
          modifiersClassNames={{
            selected: styles['selected-single']
          }}
          onSelect={onSelect}
          weekStartsOn={dateLocale?.code === 'en-US' ? 0 : 1}
          {...props}
        />
      </Popover.Body>
    </Popover>
  )

  return (
    <div className={props.className}>
      <div className={styles['ma-date-picker']}>
        <OverlayTrigger
          container={container}
          show={show}
          trigger="click"
          placement={placement}
          transition={false}
          overlay={popover}
          rootClose={true}
          onToggle={isVisible => {
            setShow(isVisible)
          }}
        >
          <Button
            variant="light"
            data-testid="datepicker"
            className={classNames({
              'is-invalid': isInvalid
            })}
          >
            <BsCalendar2Week size="24" />
            <div
              className={classNames({
                [styles['date-range-unselected']]: !selected
              })}
            >
              {selected ? format(selected, 'd MMM yyyy') : ''}
            </div>
          </Button>
        </OverlayTrigger>
      </div>
    </div>
  )
}

export default MaDatePicker
