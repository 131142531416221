import { type ReactElement } from 'react'
import LoadingSpinner from './LoadingSpinner'
import { ReactComponent as AmiciLogo } from '../assets/images/amici-logo.svg'
import { t } from 'i18next'

function ErrorMessage (
  props: Readonly<{ error?: boolean }>
): ReactElement | null {
  if (props.error) {
    return (
      <>
        <div className="header" role="header">
          <div>
            <AmiciLogo />
            My<strong>Amici</strong>
          </div>
        </div>
        <div className="error-message">
          <p>{t('loadingscreen.error')}</p>
        </div>
      </>
    )
  } else {
    return null
  }
}

function SupportFooter (
  props: Readonly<{ error?: boolean }>
): ReactElement | null {
  if (props.error) {
    return (
      <div role="supportFooter">
        <p>{t('loadingscreen.error.footer')}</p>
      </div>
    )
  } else {
    return null
  }
}

function LoadingScreen (
  props: Readonly<{
    error?: boolean
    children?: string | ReactElement
  }>
): ReactElement {
  return (
    <main
      className="loading-screen h-100 container-fluid d-flex flex-column align-items-center justify-content-center"
      style={{ minHeight: '100dvh' }}
    >
      <ErrorMessage error={props.error} />
      {props.children ?? <LoadingSpinner />}
      <SupportFooter error={props.error} />
    </main>
  )
}

export default LoadingScreen
