import { type ChangeEvent, type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'react-bootstrap'
import { BsSortDownAlt, BsSortUp } from 'react-icons/bs'
import classNames from 'classnames'
import { SortDirection } from '../../common/types/sort-direction'
import MaActionIcon from '../../common/components/MaActionIcon'
import styles from '../assets/scss/TasksFilter.module.scss'

interface TasksFilterProps {
  search: string
  sortingOrder: SortDirection
  totalTasks: number
  onSearchChange: (search: string) => void
  onSortingOrderChange: (order: SortDirection) => void
}

function TasksFilter ({
  search,
  sortingOrder,
  totalTasks,
  onSearchChange,
  onSortingOrderChange
}: Readonly<TasksFilterProps>): ReactElement {
  const { t } = useTranslation()

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    onSearchChange(e.target.value)
  }

  const handleSortingOrderChange = (value: SortDirection): void => {
    onSortingOrderChange(value)
  }

  return (
    <div className={classNames(styles['tasks-filter'])}>
      {totalTasks > 0 && (
        <>
          <Form.Control
            value={search}
            className={styles.input}
            placeholder={t('tasks.filter.search.placeholder')}
            title={t('tasks.filter.search.placeholder') ?? 'Search'}
            onChange={handleSearchChange}
          />

          <MaActionIcon
            title={t(`tasks.filter.sort.${sortingOrder}`)}
            onClick={() => {
              handleSortingOrderChange(
                sortingOrder === SortDirection.ASC
                  ? SortDirection.DESC
                  : SortDirection.ASC
              )
            }}
          >
            {sortingOrder === SortDirection.DESC && <BsSortDownAlt size={24} />}
            {sortingOrder === SortDirection.ASC && <BsSortUp size={24} />}
          </MaActionIcon>
        </>
      )}
    </div>
  )
}

export default TasksFilter
