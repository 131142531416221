import React from 'react'
import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import router from './common/router'
import './common/i18n'
import ApplicationInsightsWrapper from './common/components/ApplicationInsightsWrapper'
import Authentication from './common/components/Authentication'

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <ApplicationInsightsWrapper>
      <Authentication>
        <RouterProvider router={router} />
      </Authentication>
    </ApplicationInsightsWrapper>
  </React.StrictMode>
)
