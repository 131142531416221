import { type ReactElement } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import useFavourites from '../hooks/useFavourites'
import FavourteProductCard from './FavouriteCard'
import styles from '../assets/scss/Favourites.module.scss'

function Favourites (): ReactElement {
  const { t } = useTranslation()
  const {
    isLoading,
    favouriteProducts,
    totalFavourites,
    search,
    changeSearch
  } = useFavourites()

  const hasFavourites: boolean = !isLoading && totalFavourites > 0
  const noFilteredResults: boolean =
    hasFavourites && favouriteProducts.length < 1

  return (
    <Container fluid="auto" className="h-100">
      <div className={styles.controls}>
        {hasFavourites && (
          <Row className="g-0">
            <Col xs={12}>
              <Form.Control
                value={search}
                className={styles.input}
                placeholder={t('favourites.search.placeholder')}
                title={t('favourites.search.placeholder') ?? 'Search'}
                onChange={e => {
                  changeSearch(e.target.value)
                }}
              />
            </Col>
          </Row>
        )}
      </div>

      <div className={styles.favourites}>
        {hasFavourites &&
          favouriteProducts.map(product => (
            <FavourteProductCard key={product.id} product={product} />
          ))}

        {!hasFavourites && (
          <>
            <p className="text-center">
              {t('favourites.no_favourites.part_1')}
            </p>
            <p className="text-center">
              {t('favourites.no_favourites.part_2')}
            </p>
          </>
        )}

        {noFilteredResults && (
          <p className="text-center">{t('favourites.search.no_results')}</p>
        )}
      </div>
    </Container>
  )
}

export default Favourites
