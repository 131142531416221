import { type FormEvent, type ReactElement, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ProductSearchType } from '../../purchasing/types/product-search-type'
import ProductSearchForm from '../../purchasing/components/ProductSearchForm'
import styles from '../assets/scss/QuickShopPanel.module.scss'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

function QuickShopPanel (): ReactElement {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState('')

  const handleSearchSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    if (!searchTerm.trim()) {
      setSearchTerm('')
      return
    }

    navigate(
      `/purchasing/products?term=${searchTerm.trim()}&type=${
        ProductSearchType.Keyword
      }`
    )
  }

  return (
    <div className={styles['quick-shop-panel']}>
      <h2 className={styles.title}>{t('home.quick_shop.title')}</h2>

      <p className={styles.subtitle}>{t('home.quick_shop.subtitle')}</p>

      <Row>
        <Col md={5}>
          <ul>
            <li>{t('home.quick_shop.list.consumables')}</li>
            <li>{t('home.quick_shop.list.biologicals')}</li>
            <li>{t('home.quick_shop.list.chemicals')}</li>
            <li>{t('home.quick_shop.list.equipment')}</li>
            <li>{t('home.quick_shop.list.parts')}</li>
            <li>{t('home.quick_shop.list.supplies')}</li>
            <li>{t('home.quick_shop.list.gases')}</li>
          </ul>
        </Col>
        <Col md={7} className={styles['search-col']}>
          <div className={styles.search}>
            <ProductSearchForm
              type={ProductSearchType.Keyword}
              search={searchTerm}
              showType={false}
              onSearchChange={e => {
                setSearchTerm(e.target.value)
              }}
              onSubmit={handleSearchSubmit}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default QuickShopPanel
