import { type HTMLAttributes, type ReactElement } from 'react'
import { Button, OverlayTrigger, Popover } from 'react-bootstrap'
import { BsCalendar2Week } from 'react-icons/bs'
import { type DateRange, DayPicker } from 'react-day-picker'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import classNames from 'classnames'
import useIsMobile from '../hooks/useIsMobile'
import styles from '../assets/scss/MaDatePicker.module.scss'
import useDateLocale from '../hooks/useDateLocale'

interface MaDateRangePickerProps extends HTMLAttributes<HTMLDivElement> {
  value?: DateRange
  placeholder?: string
  container?: HTMLElement
  onDateRangeChange: (range: DateRange | undefined) => void
}

function MaDateRangePicker ({
  value,
  placeholder,
  className,
  container,
  onDateRangeChange
}: Readonly<MaDateRangePickerProps>): ReactElement {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const dateLocale = useDateLocale()

  const dateFormat =
    new Date().getFullYear() === value?.from?.getFullYear() &&
    (!value?.to || new Date().getFullYear() === value?.to?.getFullYear())
      ? 'd MMM'
      : 'd MMM yyyy'

  const handleDateRangeChange = (dateRange: DateRange | undefined): void => {
    onDateRangeChange(dateRange)
  }

  const popover = (
    <Popover className={styles.popover}>
      <Popover.Body className={styles['popover-body']}>
        <DayPicker
          mode={'range'}
          selected={value}
          numberOfMonths={isMobile ? 1 : 2}
          showOutsideDays
          fixedWeeks
          defaultMonth={value?.from}
          onSelect={handleDateRangeChange}
          captionLayout={'dropdown-buttons'}
          fromYear={2000}
          toYear={new Date().getFullYear() + 5}
          modifiersClassNames={{
            selected: styles.selected,
            range_middle: styles['range-middle']
          }}
          weekStartsOn={dateLocale?.code === 'en-US' ? 0 : 1}
        />
        <Button
          className={styles['clear-button']}
          onClick={() => {
            handleDateRangeChange(undefined)
          }}
          disabled={!value}
        >
          {t('date_range.clear')}
        </Button>
      </Popover.Body>
    </Popover>
  )

  return (
    <div className={classNames(styles['ma-date-picker'], className)}>
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        overlay={popover}
        rootClose={true}
        container={container}
      >
        <Button variant="light" data-testid="date-range-picker">
          <BsCalendar2Week size="24" />
          <div className={value?.from ? '' : styles['date-range-unselected']}>
            {!value?.from &&
              !value?.to &&
              (placeholder ?? t('date_range.select'))}
            {value?.from && format(value.from, dateFormat)}
            {value?.to && ` - ${format(value.to, dateFormat)}`}
          </div>
        </Button>
      </OverlayTrigger>
    </div>
  )
}

export default MaDateRangePicker
