import { type HTMLProps, type ReactElement } from 'react'
import useMainNavigation from '../hooks/useMainNavigation'
import { Nav, NavItem } from 'react-bootstrap'
import { Link, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import {
  type NavigationItem,
  NavigationSection
} from '../models/NavigationSection'
import useAccountMenu from '../hooks/useAccountMenu'
import AccountSwitcher from './AccountSwitcher'
import styles from '../assets/scss/SidebarNavigation.module.scss'

function SidebarNavigation ({
  className = ''
}: Readonly<HTMLProps<HTMLDivElement>>): ReactElement {
  const { t } = useTranslation()
  const mainNavigation = useMainNavigation()
  const [params] = useSearchParams()
  const {
    MA3_BASE_URL,
    accountProfile,
    canSwitchAccounts,
    canChangePassword,
    canSetupNewPin,
    canChangePin,
    handleLogOut
  } = useAccountMenu()

  const supportMenuNavigation = [
    new NavigationSection(
      'support.title',
      [[]],
      'https://www.support.myamici.co.uk/',
      '_blank'
    )
  ]

  const accountMenuNavigation = [
    new NavigationSection('account_menu.title', [
      [
        {
          title: 'account_menu.my_user_account',
          url: `${MA3_BASE_URL}/MyAccount/MyUserAccount.aspx`,
          isVisible: true
        },
        {
          title: 'account_menu.change_password',
          url: `${MA3_BASE_URL}/MyAccount/ChangePassword.aspx`,
          isVisible: canChangePassword
        },
        {
          title: 'account_menu.setup_new_pin',
          url: `${MA3_BASE_URL}/MyAccount/ResetPIN.aspx`,
          isVisible: canSetupNewPin
        },
        {
          title: 'account_menu.change_pin',
          url: `${MA3_BASE_URL}/MyAccount/ChangePin.aspx`,
          isVisible: canChangePin
        },
        {
          title: '',
          url: '',
          component: (
            <NavItem as="li" key="account-switcher" className={styles.item}>
              <AccountSwitcher className={styles.link} />
            </NavItem>
          ),
          isVisible: canSwitchAccounts
        },
        {
          title: '',
          url: '',
          component: (
            <NavItem
              as="li"
              key="logout"
              className={styles.item}
              onClick={handleLogOut}
            >
              <span className={styles.link}>{t('account_menu.log_out')}</span>
            </NavItem>
          ),
          isVisible: true
        }
      ]
    ])
  ]

  const navigationSections: NavigationSection[] = [
    ...mainNavigation,
    ...supportMenuNavigation,
    ...accountMenuNavigation
  ]

  return (
    <Nav className={classNames(styles['sidebar-nav'], className)} as="ul">
      <li className={styles['company-name']}>{accountProfile?.client?.name}</li>
      {navigationSections.map(section => (
        <li key={section.title}>
          <NavItem className={styles['section-title']}>
            {!section.url && t(section.title)}
            {section.url && (
              <Link
                to={section.url}
                target={section.target}
                rel="noreferrer"
                className={styles.link}
              >
                {t(section.title)}
              </Link>
            )}
          </NavItem>
          {section.getEnabledItems().length > 0 && (
            <ul className={styles.items}>
              {section
                .getEnabledItems()
                .flat()
                .map((item: NavigationItem) =>
                  item.component ? (
                    item.component
                  ) : (
                    <NavItem key={item.title} as={'li'} className={styles.item}>
                      <Link
                        className={
                          location.pathname.startsWith(
                            item.url.split('?')[0]
                          ) &&
                          (item.includeParams == null ||
                            item.includeParams?.every(param =>
                              params?.toString().includes(param)
                            )) &&
                          (item.excludeParams == null ||
                            item.excludeParams?.every(
                              param => !params?.toString().includes(param)
                            ))
                            ? styles.active_link
                            : styles.link
                        }
                        to={item.url}
                        target={item.target}
                      >
                        {t(item.title)}
                      </Link>
                    </NavItem>
                  )
                )}
            </ul>
          )}
        </li>
      ))}
    </Nav>
  )
}

export default SidebarNavigation
