import { type HTMLAttributes, type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import useAccountSwitcher from '../hooks/useAccountSwitcher'

function AccountSwitcher ({
  className
}: Readonly<HTMLAttributes<HTMLElement>>): ReactElement | null {
  const { t } = useTranslation()
  const accountSwitcherUrl = useAccountSwitcher()

  return (
    <a href={accountSwitcherUrl} className={className}>
      {t('account_menu.switch_account')}
    </a>
  )
}

export default AccountSwitcher
