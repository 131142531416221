import { Table } from 'react-bootstrap'
import type { Invoice, InvoiceResource } from '@amici/myamici-api-client'
import type { ReactElement } from 'react'
import styles from '../assets/scss/OrderInvoicesTable.module.scss'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import { format, parseISO } from 'date-fns'
import { useEnv } from '../../common/hooks/useEnv'

function TableHead (): ReactElement {
  return (
    <thead>
      <tr>
        <th scope="col" colSpan={2}>
          <Trans i18nKey="purchase_order.invoice.number" />
        </th>
        <th scope="col" colSpan={2}>
          <Trans i18nKey="purchase_order.invoice.date" />
        </th>
        <th scope="col" colSpan={2}>
          <Trans i18nKey="purchase_order.invoice.status" />
        </th>
        <th scope="col" colSpan={2}>
          <Trans i18nKey="purchase_order.invoice.net" />
        </th>
        <th scope="col" colSpan={2}>
          <Trans i18nKey="purchase_order.invoice.gross" />
        </th>
        <th scope="col">
          <Trans i18nKey="purchase_order.invoice.added_by" />
        </th>
      </tr>
    </thead>
  )
}

function InvoiceItemRow ({
  invoice,
  isFinanceUser
}: Readonly<{
  invoice: Invoice
  isFinanceUser: boolean
}>): ReactElement {
  const { REACT_APP_MA3_BASE_URL } = useEnv()
  const invoiceUrl = `${REACT_APP_MA3_BASE_URL}/Invoice/InvoicePage2.aspx?InvoiceId=${invoice.id}`

  return (
    <tr key={invoice.id}>
      <td className={styles.number} colSpan={2}>
        {isFinanceUser ? (
          <Link to={invoiceUrl} target="_blank">
            {invoice.number}
          </Link>
        ) : (
          <span>{invoice.number}</span>
        )}
      </td>
      <td colSpan={2}>
        {format(parseISO(invoice.invoice_date ?? ''), 'd MMM yyyy')}
      </td>
      <td colSpan={2}>
        <Trans
          i18nKey={`purchase_order.invoice.status.values.${invoice.status?.toLowerCase()}`}
        />
      </td>
      <td colSpan={2}>
        {isFinanceUser ? (
          <Trans
            i18nKey="common.price"
            values={{
              price: invoice.net,
              currency: invoice.currency
            }}
          />
        ) : (
          '-'
        )}
      </td>
      <td colSpan={2}>
        {isFinanceUser ? (
          <Trans
            i18nKey="common.price"
            values={{
              price: invoice.gross,
              currency: invoice.currency
            }}
          />
        ) : (
          '-'
        )}
      </td>
      <td>{invoice.added_by?.name}</td>
    </tr>
  )
}

function OrderInvoiceTable ({
  invoices,
  isFinanceUser
}: Readonly<{
  invoices: InvoiceResource[]
  isFinanceUser: boolean
}>): ReactElement {
  return (
    <div className={styles['order-invoices-table']}>
      <Table bordered>
        <TableHead />
        <tbody>
          {invoices.map(invoice => (
            <InvoiceItemRow
              key={invoice.id}
              invoice={invoice}
              isFinanceUser={isFinanceUser}
            />
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default OrderInvoiceTable
