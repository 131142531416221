import { type ReactElement } from 'react'
import { type NavigationTarget } from '../types/navigation-target'

export interface NavigationItem {
  title: string
  url: string
  component?: ReactElement
  target?: NavigationTarget
  isVisible?: boolean
  includeParams?: string[]
  excludeParams?: string[]
}

export class NavigationSection {
  public title: string
  public groups: NavigationItem[][]
  public url?: string
  public target?: NavigationTarget

  public constructor (
    title: string,
    groups: NavigationItem[][],
    url?: string,
    target?: NavigationTarget
  ) {
    this.title = title
    this.groups = groups
    this.url = url
    this.target = target
  }

  public isVisible (): boolean {
    return this.groups.flat().some((item: NavigationItem) => item.isVisible)
  }

  public getEnabledItems (): NavigationItem[][] {
    return this.groups.map((group: NavigationItem[]): NavigationItem[] =>
      group.filter((item: NavigationItem): boolean => !!item.isVisible)
    )
  }
}
