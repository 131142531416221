import {
  type HTMLAttributes,
  type MouseEventHandler,
  type ReactElement,
  useMemo
} from 'react'
import { useTranslation } from 'react-i18next'
import { type Chart, type ChartSegment } from '@amici/myamici-api-client'
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
  LabelList,
  Label,
  Tooltip,
  type TooltipProps
} from 'recharts'
import {
  type Formatter as LegendFormatter,
  type Payload,
  type Props as LegendProps
} from 'recharts/types/component/DefaultLegendContent'
import { type Formatter as TooltipFormatter } from 'recharts/types/component/DefaultTooltipContent'
import classNames from 'classnames'
import useIsMobile from '../hooks/useIsMobile'
import styles from '../assets/scss/MaPieChart.module.scss'
import tooltipStyles from '../../common/assets/scss/MaTooltip.module.scss'

const ChartTooltip = ({
  active,
  payload,
  formatter
}: Readonly<TooltipProps<any, any>>): ReactElement | null => {
  if (active && payload?.length) {
    return (
      <div className={tooltipStyles['ma-tooltip']}>
        <span className={classNames(tooltipStyles.text)}>
          {formatter
            ? formatter?.(
                payload[0].value,
                payload[0].name,
                payload[0],
                0,
                payload
              )
            : payload[0].name}
        </span>
      </div>
    )
  }

  return null
}

interface MaPieChartProps extends HTMLAttributes<HTMLDivElement> {
  data: Chart
  colours: Record<string, string>
  noItemsText?: string
  totalCountCaption?: string
  legendFormatter?: LegendFormatter
  tooltipFormatter?: TooltipFormatter<number, string>
  onLegendClick?: (item: Payload) => void
  onSegmentClick?: (entry: ChartSegment) => void
  onTotalClick?: MouseEventHandler
}

function ChartLegend ({
  payload,
  onClick,
  formatter
}: Readonly<LegendProps>): ReactElement {
  return (
    <ul className={styles.legend}>
      {payload?.map((entry: any, index: number) => (
        <li
          className={styles['legend-item']}
          key={`item-${index}`}
          onClick={event => {
            onClick?.(entry as Payload, index, event)
          }}
        >
          <span
            className={styles['legend-icon']}
            style={{ backgroundColor: payload[index].color }}
          />
          {formatter
            ? formatter?.(entry.value, entry as Payload, index)
            : entry.value}
        </li>
      ))}
    </ul>
  )
}

const NoItemsLegend = ({ text }: { readonly text: string }): ReactElement => (
  <div className={styles.legend}>{text}</div>
)

function MaPieChart ({
  data,
  colours,
  className,
  legendFormatter,
  tooltipFormatter,
  noItemsText = '',
  totalCountCaption = '',
  onSegmentClick,
  onTotalClick,
  ...props
}: Readonly<MaPieChartProps>): ReactElement {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const chartData = useMemo(
    () =>
      data?.total && data?.total > 0
        ? data.values
        : [{ name: 'empty', value: 1 }],
    [data]
  )

  return (
    <div
      className={classNames(
        styles['ma-pie-chart'],
        {
          [styles.mobile]: isMobile
        },
        className
      )}
      {...props}
    >
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={chartData}
            dataKey="value"
            legendType="square"
            outerRadius={'100%'}
            innerRadius={'63%'}
            blendStroke
            isAnimationActive={false}
          >
            {chartData?.map(entry => (
              <Cell
                key={`cell-${entry.name ?? ''}`}
                fill={colours[entry.name]}
                style={{ outline: 'none' }}
                {...(onSegmentClick && {
                  onClick: () => {
                    onSegmentClick(entry)
                  }
                })}
              />
            ))}
            <Label
              className="total-count"
              value={data?.total ?? 0}
              position="center"
              {...(onTotalClick && {
                onClick: onTotalClick
              })}
              style={{ cursor: 'pointer' }}
            />
            <Label
              className="total-caption"
              value={totalCountCaption || t('common.chart.total')}
              position="center"
              {...(onTotalClick && {
                onClick: onTotalClick
              })}
              style={{ cursor: 'pointer' }}
            />
            {data?.total && data?.total > 0 && (
              <LabelList
                dataKey="value"
                position="inside"
                formatter={(v: number) => (v > 0 ? v : '')}
              />
            )}
          </Pie>
          <Tooltip content={<ChartTooltip formatter={tooltipFormatter} />} />

          <Legend
            key={data?.total}
            formatter={legendFormatter}
            layout={isMobile ? 'horizontal' : 'vertical'}
            verticalAlign={isMobile ? 'bottom' : 'middle'}
            align={isMobile ? 'center' : 'right'}
            content={
              data?.total && data?.total > 0 ? (
                <ChartLegend />
              ) : (
                <NoItemsLegend text={noItemsText} />
              )
            }
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  )
}

export default MaPieChart
