import { type ReactNode } from 'react'
import styles from '../assets/scss/OrderRequests.module.scss'
import { useTranslation } from 'react-i18next'

function OrderRequestsSearchSummary ({
  total
}: Readonly<{ total?: number }>): ReactNode {
  const { t } = useTranslation()

  const notFound = total === 0

  if (total === undefined) {
    return null
  }

  return (
    <div className={styles.summary}>
      <p>
        {notFound && t('order_requests.search.summary.no_results')}

        {total > 0 &&
          t('order_requests.search.summary.results', { count: total })}
      </p>
    </div>
  )
}

export default OrderRequestsSearchSummary
