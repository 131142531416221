import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Container, Form } from 'react-bootstrap'
import { BsFillCaretDownFill, BsFillCaretRightFill } from 'react-icons/bs'
import { type NodeRendererProps, Tree, type NodeApi } from 'react-arborist'
import {
  type SpendCategoryField,
  SpendCategoryFieldStatusEnum,
  SpendCategoryStatusEnum,
  type SpendCategory
} from '@amici/myamici-api-client'
import classNames from 'classnames'
import useResizeObserver from 'use-resize-observer'
import { type SpendCategoryNode } from '../types/spend-category-node'
import { MaSelect, MaSelectItem } from '../../common/components/MaSelect'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import MaCheckbox from '../../common/components/MaCheckbox'
import MaPageTitle from '../../common/components/MaPageTitle'
import MaPanel from '../../common/components/MaPanel'
import MaSwitch from '../../common/components/MaSwitch'
import MaInlineEditInput from '../../common/components/MaInlineEditInput'
import MaActionMenu, {
  MaActionMenuItem
} from '../../common/components/MaActionMenu'
import useAccounts from '../../common/hooks/useAccounts'
import { useToastNotification } from '../../common/components/ToastNotificationContextProvider'
import useSpendCategoriesPage from '../hooks/useSpendCategoriesPage'
import SpendCategoryFormModal from '../components/SpendCategoryFormModal'
import SpendCategorySelect from '../components/SpendCategorySelect'
import styles from '../assets/scss/SpendCategories.module.scss'
import MaTabs from '../../common/components/MaTabs'
import MaTab from '../../common/models/MaTab'
import SpendCategoryHistoryPanel from '../components/SpendCategoryHistoryPanel'

const MAX_TREE_HEIGHT = 6

interface NodeProps extends NodeRendererProps<SpendCategoryNode> {
  defaultSpendCategory?: SpendCategory
  getPath: (id: number) => Array<number | null>
  onAddNew: (parentNode: SpendCategoryNode | null) => void
  onEdit: (node: SpendCategoryNode) => void
  onToggle: (node: SpendCategoryNode) => Promise<void>
  onUpdateDefault: (id: string, remove: boolean) => Promise<void>
}

function Node ({
  node,
  style,
  dragHandle,
  defaultSpendCategory,
  onAddNew,
  onEdit,
  onToggle,
  onUpdateDefault,
  getPath
}: Readonly<NodeProps>): ReactElement {
  const { t } = useTranslation()

  const depth = getPath(node.data.id).filter(id => id !== null).length
  const isDefault = defaultSpendCategory?.id === node.data.id

  return (
    <div
      style={style}
      ref={dragHandle}
      className={classNames(styles['node-wrapper'])}
    >
      <div
        className={classNames(styles.node, node.data.status, {
          selected: isDefault
        })}
      >
        <span className={classNames(styles['node-name'])}>
          <span
            role="button"
            className={styles['node-icon']}
            onClick={e => {
              e.stopPropagation()
              node.isInternal &&
                (node.children?.length ?? 0) > 0 &&
                node.toggle()
            }}
          >
            {(node.children?.length ?? 0) < 1 ? null : node.state.isOpen ? (
              <BsFillCaretDownFill size={16} />
            ) : (
              <BsFillCaretRightFill size={16} />
            )}
          </span>
          {node.data.name} - {node.data.code}{' '}
          {node.data.external_ref && `(${node.data.external_ref})`}{' '}
          <span className={styles['node-description']}>
            {node.data.description ? '-' : ''} {node.data.description}
          </span>
        </span>

        <MaActionMenu>
          <MaActionMenuItem
            disabled={depth > MAX_TREE_HEIGHT}
            onClick={e => {
              e.stopPropagation()
              onAddNew(node.data)
              node.open()
            }}
          >
            {t('spend_categories.label.add_child')}
          </MaActionMenuItem>
          <MaActionMenuItem
            onClick={e => {
              e.stopPropagation()
              onEdit(node.data)
            }}
          >
            {t('spend_categories.label.edit')}
          </MaActionMenuItem>
          {!isDefault && (
            <MaActionMenuItem
              onClick={e => {
                e.stopPropagation()
                void onUpdateDefault(node.data.id.toString(), false)
              }}
              disabled={node.data.status === SpendCategoryStatusEnum.INACTIVE}
            >
              {t('spend_categories.label.set_as_default')}
            </MaActionMenuItem>
          )}
          {isDefault && (
            <MaActionMenuItem
              onClick={e => {
                e.stopPropagation()
                void onUpdateDefault(node.data.id.toString(), true)
              }}
              disabled={node.data.status === SpendCategoryStatusEnum.INACTIVE}
            >
              {t('spend_categories.label.remove_default')}
            </MaActionMenuItem>
          )}
          <MaActionMenuItem
            onClick={e => {
              e.stopPropagation()
              void onToggle(node.data)
            }}
            disabled={
              node.parent?.data.status === SpendCategoryStatusEnum.INACTIVE
            }
          >
            {t(`spend_categories.label.toggle.${node.data.status}`)}
          </MaActionMenuItem>
        </MaActionMenu>
      </div>
    </div>
  )
}

enum SpendCategoriesTab {
  SPEND_CATEGORIES = 'spend_categories',
  HISTORY = 'spend_category_history'
}

function SpendCategories (): ReactElement {
  const { t } = useTranslation()
  const { accountProfile, refreshAccountProfile, isRefreshingAccountProfile } =
    useAccounts()
  const { showToastMessage } = useToastNotification()
  const {
    data,
    isLoading,
    isUpdatingField,
    isSettingClientDefault,
    activeGroupIndex,
    activeGroup,
    activeTree,
    showInactive,
    spendCategoryFormParams,
    handleActiveGroupChange,
    handleShowInactiveToggle,
    handleAddNew,
    handleEdit,
    handleUpdate,
    handleUpdateField,
    handleSetClientDefault,
    handleModalClose,
    groupNames,
    getPath,
    getHeight,
    getEmptySpendCategory
  } = useSpendCategoriesPage()
  const { height: treeContainerHeight, ref: treeContainerRef } =
    useResizeObserver()

  const findSpendCategoryById = (
    id: string | number | null
  ): SpendCategory | undefined => {
    const spendCategoryId = typeof id === 'string' ? parseInt(id, 10) : id

    return activeGroup?.spend_categories.find(
      spendCategory => spendCategory.id === spendCategoryId
    )
  }

  const showErrorToast = (message: string): void => {
    showToastMessage('danger', message)
  }

  const handleMove = async ({
    dragIds,
    parentId,
    dragNodes
  }: {
    dragIds: string[]
    parentId: string | null
    dragNodes: Array<NodeApi<SpendCategoryNode>>
  }): Promise<void> => {
    const newParentId = parentId ? parseInt(parentId.split('_')[1]) : null
    const dragId = parseInt(dragIds[0].split('_')[1])

    const parent = findSpendCategoryById(newParentId)
    const spendCategory = findSpendCategoryById(dragId)

    // Ignore move within the same branch
    if (spendCategory?.parent_id === newParentId) {
      return
    }

    const newSiblings =
      activeGroup?.spend_categories.filter(
        ({ parent_id: parentId, id }) =>
          spendCategory?.id !== id && newParentId === parentId
      ) ?? []

    const subtreeHeight = getHeight(
      activeGroup?.spend_categories ?? [],
      dragNodes[0].data
    )

    const newParentDepth =
      newParentId === null
        ? 0
        : getPath(newParentId).filter(id => id !== null).length

    const exceedsMaxHeigth = subtreeHeight + newParentDepth > MAX_TREE_HEIGHT

    if (exceedsMaxHeigth) {
      showErrorToast(t('spend_categories.error.exceeds_max_height'))
      return
    }

    const canMove =
      spendCategory &&
      !newSiblings.some(newSibling => newSibling.name === spendCategory.name)

    if (!canMove) {
      showErrorToast(t('spend_categories.error.non_unique'))
      return
    }

    try {
      await handleUpdate({
        ...spendCategory,
        parent_id: newParentId,
        status:
          parent?.status === SpendCategoryFieldStatusEnum.INACTIVE
            ? SpendCategoryFieldStatusEnum.INACTIVE
            : spendCategory.status
      })
      await refreshAccountProfile()
    } catch {
      showErrorToast(t('spend_categories.error.move'))
    }
  }

  const handleToggle = async (node: SpendCategoryNode): Promise<void> => {
    const spendCategory = findSpendCategoryById(node.id)

    if (!spendCategory) {
      return
    }

    try {
      await handleUpdate({
        ...spendCategory,
        status:
          spendCategory?.status === SpendCategoryStatusEnum.ACTIVE
            ? SpendCategoryFieldStatusEnum.INACTIVE
            : SpendCategoryStatusEnum.ACTIVE
      })
      await refreshAccountProfile()
    } catch {
      showErrorToast(t('spend_categories.error.move'))
    }
  }

  const handleSubmissionError = (message: string): void => {
    showErrorToast(message)
  }

  const fieldGroupNameValidator = (name: string): string | boolean => {
    if (name.trim().length === 0) {
      return t('validation.error.spend_category.field_name.required')
    }

    if (
      groupNames
        .filter(groupName => groupName !== activeGroup?.name)
        .some(groupName => groupName === name.trim())
    ) {
      return t('validation.error.spend_category.field_name.non_unique')
    }

    return false
  }

  const handleSpendCategoryFieldUpdate = async (
    spendCategoryField: SpendCategoryField
  ): Promise<void> => {
    try {
      await handleUpdateField(spendCategoryField)
    } catch {
      showErrorToast(t('spend_categories.error.update_field'))
    }
  }

  const handleToggleField = async (checked: boolean): Promise<void> => {
    if (!activeGroup) {
      return
    }

    const spendCategoryField = {
      ...activeGroup,
      status: checked
        ? SpendCategoryFieldStatusEnum.ACTIVE
        : SpendCategoryFieldStatusEnum.INACTIVE
    }

    await handleSpendCategoryFieldUpdate(spendCategoryField)
    await refreshAccountProfile()
  }

  const handleFieldNameSave = async (name: string): Promise<void> => {
    if (!activeGroup) {
      return
    }

    const spendCategoryField = {
      ...activeGroup,
      name: name.trim()
    }

    await handleSpendCategoryFieldUpdate(spendCategoryField)
  }

  const handleUpdateDefault = async (
    id: string,
    remove = false
  ): Promise<void> => {
    const spendCategory =
      remove || id === '-1'
        ? getEmptySpendCategory((activeGroup?.id ?? 0).toString())
        : findSpendCategoryById(id)

    if (!spendCategory) {
      return
    }

    await handleSetClientDefault(spendCategory)
    await refreshAccountProfile()
  }

  const defaultSpendCategory =
    accountProfile?.client?.default_spend_categories?.find(
      spendCategory => spendCategory.field_id === activeGroup?.id
    )

  const isSpendCategorySelectDisabled =
    isSettingClientDefault ||
    isRefreshingAccountProfile ||
    activeGroup?.status === SpendCategoryFieldStatusEnum.INACTIVE

  const locationHash = location.hash.substring(1)
  const activeTab =
    (locationHash as SpendCategoriesTab) === SpendCategoriesTab.HISTORY
      ? SpendCategoriesTab.HISTORY
      : SpendCategoriesTab.SPEND_CATEGORIES

  return (
    <Container fluid="auto" className="ma-page">
      <MaPageTitle>{t('spend_categories.title')}</MaPageTitle>

      <MaTabs
        tabs={[
          new MaTab(
            SpendCategoriesTab.SPEND_CATEGORIES,
            (
              <div className={styles.content}>
                {isLoading && <LoadingSpinner />}

                {!isLoading && activeGroup && (
                  <>
                    <div className={styles['group-select']}>
                      <MaSelect
                        value={activeGroupIndex?.toString()}
                        onValueChange={handleActiveGroupChange}
                      >
                        {groupNames.map((group, index) => (
                          <MaSelectItem key={index} value={index.toString()}>
                            {group}
                          </MaSelectItem>
                        ))}
                      </MaSelect>
                    </div>

                    {data && (
                      <MaPanel className={styles.group}>
                        <MaPanel.Header>
                          <div className={styles['group-header']}>
                            <MaInlineEditInput
                              className={styles['group-name']}
                              value={activeGroup.name}
                              maxLength={50}
                              isBusy={isUpdatingField}
                              validator={fieldGroupNameValidator}
                              onSave={name => {
                                void handleFieldNameSave(name)
                              }}
                            />

                            <MaSwitch
                              id="field-status"
                              checked={
                                activeGroup.status ===
                                SpendCategoryFieldStatusEnum.ACTIVE
                              }
                              onCheckedChange={checked => {
                                void handleToggleField(checked)
                              }}
                            >
                              {activeGroup.status ===
                              SpendCategoryFieldStatusEnum.ACTIVE
                                ? t('common.status.values.enabled')
                                : t('common.status.values.disabled')}
                            </MaSwitch>
                          </div>
                        </MaPanel.Header>

                        <MaPanel.Body>
                          <div className={styles['group-controls']}>
                            <div className={styles['group-controls-wrapper']}>
                              <div className={styles['default-category']}>
                                <Form.Group controlId="default-category">
                                  <Form.Label>
                                    {t('spend_categories.label.default')}
                                  </Form.Label>
                                  <SpendCategorySelect
                                    id="default-category"
                                    value={(
                                      defaultSpendCategory?.id ?? ''
                                    ).toString()}
                                    placeholder={
                                      !isSpendCategorySelectDisabled
                                        ? t('spend_categories.label.none')
                                        : ''
                                    }
                                    disabled={isSpendCategorySelectDisabled}
                                    spendCategories={
                                      activeGroup.spend_categories
                                    }
                                    allowEmpty={true}
                                    onValueChange={value => {
                                      void handleUpdateDefault(value)
                                    }}
                                  />
                                </Form.Group>
                              </div>

                              <Form.Group className={styles['toggle-inactive']}>
                                <Form.Label htmlFor="toggle-inactive">
                                  {t('spend_categories.label.show_inactive')}
                                </Form.Label>
                                <MaCheckbox
                                  id="toggle-inactive"
                                  checked={showInactive}
                                  onCheckedChange={handleShowInactiveToggle}
                                />
                              </Form.Group>
                            </div>

                            <div className={styles['add-new-category']}>
                              <Button
                                variant="outline-primary"
                                className="rounded"
                                onClick={() => {
                                  handleAddNew(null)
                                }}
                              >
                                {t('spend_categories.label.add')}
                              </Button>
                            </div>
                          </div>

                          <div
                            className={styles['spend-categories']}
                            ref={treeContainerRef}
                          >
                            <Tree
                              onMove={handleMove}
                              data={activeTree}
                              width="100%"
                              height={treeContainerHeight}
                              disableEdit={true}
                              disableMultiSelection={true}
                              idAccessor="node_id"
                              rowHeight={44}
                              disableDrop={({ parentNode, dragNodes }) => {
                                const [node] = dragNodes

                                // Disable drop when moved within the same branch
                                return (
                                  parentNode.data.id === node.data.parent_id ||
                                  (!parentNode.data.name &&
                                    node.data.parent_id === null)
                                )
                              }}
                            >
                              {props =>
                                Node({
                                  ...props,
                                  defaultSpendCategory,
                                  getPath,
                                  onAddNew: handleAddNew,
                                  onEdit: handleEdit,
                                  onToggle: handleToggle,
                                  onUpdateDefault: handleUpdateDefault
                                })
                              }
                            </Tree>
                          </div>
                        </MaPanel.Body>
                      </MaPanel>
                    )}
                  </>
                )}
              </div>
            )
          ),
          new MaTab(SpendCategoriesTab.HISTORY, <SpendCategoryHistoryPanel />)
        ]}
        activeTab={activeTab}
        onSelectTab={tabKey => {
          location.hash = tabKey
        }}
      />

      <SpendCategoryFormModal
        parentNode={spendCategoryFormParams?.parentNode}
        node={spendCategoryFormParams?.node}
        activeGroup={activeGroup}
        fieldId={(activeGroupIndex + 1).toString()}
        onError={handleSubmissionError}
        onClose={handleModalClose}
      />
    </Container>
  )
}

export default SpendCategories
