import { forwardRef, type PropsWithChildren, type ReactElement } from 'react'
import { Button, type ModalProps } from 'react-bootstrap'
import {
  BsExclamationOctagonFill,
  BsExclamationTriangleFill,
  BsInfoCircleFill
} from 'react-icons/bs'
import { Trans } from 'react-i18next'
import MaModal from './MaModal'
import styles from '../assets/scss/MaAlert.module.scss'
import type { IconType } from 'react-icons'
import classNames from 'classnames'

interface MaAlertProps extends ModalProps, PropsWithChildren {
  readonly variant?: 'info' | 'warning' | 'error'
  readonly onClose: () => void
}

const MaAlert = forwardRef(function MaAlert (
  { variant = 'info', onClose, ...props }: MaAlertProps,
  ref
): ReactElement {
  let Icon: IconType
  switch (variant) {
    case 'info':
      Icon = BsInfoCircleFill
      break
    case 'warning':
      Icon = BsExclamationTriangleFill
      break
    case 'error':
      Icon = BsExclamationOctagonFill
      break
  }
  return (
    <MaModal
      {...props}
      ref={ref}
      onClose={onClose}
      className={classNames(styles['ma-alert'], styles[variant])}
      header={
        <div className={styles.header}>
          <Icon size={24} />
          <h4>
            <Trans i18nKey={`common.title.${variant}`} />
          </h4>
        </div>
      }
      footer={
        <div className={styles.buttons}>
          <Button variant="primary" onClick={onClose}>
            <Trans i18nKey="common.button.labels.ok" />
          </Button>
        </div>
      }
    />
  )
})

export default MaAlert
