import {
  Children,
  type PropsWithChildren,
  type ReactElement,
  useEffect,
  useState
} from 'react'
import { Carousel } from 'react-bootstrap'
import classNames from 'classnames'
import useIsMobile from '../hooks/useIsMobile'
import useResizeObserver from 'use-resize-observer'
import styles from '../assets/scss/MaProductCarousel.module.scss'

const DEFAULT_PRODUCT_CARD_WIDTH_PX = 340

interface MaProductCarouselProps extends PropsWithChildren {
  cardWidth?: number
}

function MaProductCarousel ({
  children,
  cardWidth = DEFAULT_PRODUCT_CARD_WIDTH_PX
}: Readonly<MaProductCarouselProps>): ReactElement {
  const isMobile = useIsMobile()
  const { ref: carouselWrapperRef, width: carouselWidth = 0 } =
    useResizeObserver()
  const [activeSlide, setActiveSlide] = useState(0)

  const items = Children.toArray(children)
  const totalCards = items.length
  const cardsPerSlide: number = Math.floor(carouselWidth / cardWidth) || 1
  const numOfSlides: number = Math.ceil(totalCards / cardsPerSlide)

  useEffect(() => {
    setActiveSlide(0)
  }, [numOfSlides])

  const carouselItems = [...new Array(numOfSlides ?? 0)].map((_, i) => (
    <Carousel.Item key={`slide-${i}`}>
      <div className={styles['slide-wrapper']}>
        {items.slice(i * cardsPerSlide, i * cardsPerSlide + cardsPerSlide)}
      </div>
    </Carousel.Item>
  ))

  return (
    <div
      ref={carouselWrapperRef}
      className={classNames(styles['ma-product-carousel'], {
        [styles.mobile]: isMobile
      })}
    >
      <Carousel
        activeIndex={activeSlide}
        controls={false}
        indicators={numOfSlides > 1}
        onSelect={i => {
          setActiveSlide(i)
        }}
        slide={true}
        touch={isMobile}
      >
        {carouselItems}
      </Carousel>
    </div>
  )
}

export default MaProductCarousel
