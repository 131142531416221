import classNames from 'classnames'
import {
  type HTMLAttributes,
  useState,
  type ReactElement,
  type ChangeEventHandler,
  useRef,
  useLayoutEffect,
  type KeyboardEventHandler,
  useEffect
} from 'react'
import { Form, Spinner } from 'react-bootstrap'
import { BsCheckLg, BsPencilFill, BsXLg } from 'react-icons/bs'
import { useOnClickOutside } from 'usehooks-ts'
import MaActionIcon from './MaActionIcon'
import styles from '../assets/scss/MaInlineEditInput.module.scss'

export interface MaInlineEditInputProps extends HTMLAttributes<HTMLDivElement> {
  value: string
  maxLength?: number
  disabled?: boolean
  isBusy?: boolean
  validator?: (value: string) => boolean | string
  onSave: (value: string) => void
  onCancel?: () => void
}

function MaInlineEditInput ({
  value,
  disabled,
  isBusy,
  maxLength,
  validator,
  onSave,
  onCancel,
  className,
  id
}: Readonly<MaInlineEditInputProps>): ReactElement {
  const [mode, setMode] = useState<'view' | 'edit'>('view')
  const [newValue, setNewValue] = useState(value)
  const [showEditIcon, setShowEditIcon] = useState(false)

  const wrapperRef = useRef(null)
  const inputRef = useRef<HTMLInputElement | null>(null)

  useLayoutEffect(() => {
    if (mode === 'edit') {
      inputRef.current?.focus()
    }
  }, [mode])

  useEffect(() => {
    setNewValue(value)
  }, [value])

  useOnClickOutside([wrapperRef], () => {
    handleSetViewMode()
  })

  const handleSetViewMode = (): void => {
    setNewValue(value)
    setMode('view')
  }

  const handleNewValueChange: ChangeEventHandler<HTMLInputElement> = event => {
    setNewValue(event.target.value)
  }

  const handleConfirm = (): void => {
    onSave(newValue)
    handleSetViewMode()
  }

  const handleCancel = (): void => {
    handleSetViewMode()
    onCancel?.()
  }

  const error = validator?.(newValue)

  const handleInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (
    event
  ): void => {
    if (event.key === 'Escape') {
      handleSetViewMode()
    }

    if (event.key === 'Enter' && !error && !disabled) {
      handleConfirm()
    }
  }

  return (
    <div
      ref={wrapperRef}
      className={classNames(styles['ma-inline-edit-input'])}
      onMouseEnter={() => {
        setShowEditIcon(true)
      }}
      onMouseLeave={() => {
        setShowEditIcon(false)
      }}
      data-testid="ma-inline-edit-input"
    >
      {mode === 'view' && (
        <div className={className}>
          {isBusy && <Spinner size="sm" />}

          {!isBusy && value}
        </div>
      )}

      {mode === 'edit' && (
        <Form.Group controlId={id}>
          <Form.Control
            ref={inputRef}
            value={newValue}
            title={typeof error === 'string' ? error : ''}
            isInvalid={!!error}
            maxLength={maxLength}
            onChange={handleNewValueChange}
            onKeyDown={handleInputKeyDown}
          />
        </Form.Group>
      )}

      <div className={styles['icon-wrapper']}>
        {mode === 'view' && showEditIcon && !disabled && !isBusy && (
          <MaActionIcon
            onClick={() => {
              setMode('edit')
            }}
          >
            <BsPencilFill size={16} />
          </MaActionIcon>
        )}

        {mode === 'edit' && (
          <>
            <MaActionIcon
              className={styles.confirm}
              disabled={!!error || disabled}
              onClick={handleConfirm}
            >
              <BsCheckLg size={16} />
            </MaActionIcon>

            <MaActionIcon
              className={styles.cancel}
              disabled={disabled}
              onClick={handleCancel}
            >
              <BsXLg size={16} />
            </MaActionIcon>
          </>
        )}
      </div>
    </div>
  )
}

export default MaInlineEditInput
