import useSWRImmutable from 'swr'
import { type DateRange } from 'react-day-picker'
import { format } from 'date-fns'
import { OrderSearchType } from '../types/order-search-type'
import useAccounts from '../../common/hooks/useAccounts'
import useApi from '../../common/hooks/useApi'

interface UseOrderFacetsHook {
  isLoading: boolean
  initialFacets: Record<string, Record<string, number>>
  availableFacets: Record<string, Record<string, number>>
}

function useOrderFacets (
  term: string,
  orderSearchType: OrderSearchType,
  facetFilter: string[],
  orderDateRange?: DateRange
): UseOrderFacetsHook {
  const { activeAccount } = useAccounts()
  const { ordersApi, fetcher } = useApi()

  const accountId = activeAccount?.accountId ?? ''

  const startDate =
    orderDateRange?.from && format(orderDateRange?.from, 'yyyy-MM-dd')
  const endDate = orderDateRange?.to && format(orderDateRange?.to, 'yyyy-MM-dd')

  // Keeping track of the available facets w/o any filters applied
  const { data: initialFacetsData } = useSWRImmutable(
    accountId
      ? [
          'initial-order-search-facets',
          term,
          orderSearchType,
          startDate,
          endDate,
          accountId
        ]
      : null,
    async () =>
      await fetcher(ordersApi.getPage, {
        query: term,
        ...(startDate && { dateFrom: startDate }),
        ...(endDate && { dateTo: endDate }),
        returnFacets: true,
        returnResults: false,
        includeClientPurchases: orderSearchType === OrderSearchType.AllOrders,
        accountId
      })
  )

  const { data, isLoading } = useSWRImmutable(
    initialFacetsData && accountId
      ? [
          'available-order-search-facets',
          term,
          orderSearchType,
          facetFilter,
          startDate,
          endDate,
          accountId
        ]
      : null,
    async () =>
      await fetcher(ordersApi.getPage, {
        query: term,
        ...(startDate && { dateFrom: startDate }),
        ...(endDate && { dateTo: endDate }),
        ...(facetFilter.length && { facetFilter }),
        returnFacets: true,
        returnResults: false,
        includeClientPurchases: orderSearchType === OrderSearchType.AllOrders,
        accountId
      })
  )

  return {
    isLoading,
    initialFacets: initialFacetsData?.facets ?? {},
    availableFacets: data?.facets ?? {}
  }
}

export default useOrderFacets
