import { type ReactElement } from 'react'
import { Accordion, Form } from 'react-bootstrap'
import styles from '../assets/scss/SearchFilters.module.scss'

export const MIN_ITEMS_TO_FILTER = 10

interface SearchFiltersCategoryProps {
  category: string
  initialOptions: Record<string, number>
  availableOptions: Record<string, number>
  enabledOptions: Set<string>
  textFilter: string
  categoryNameFormatter: (category: string) => string
  filterNameFormatter?: (category: string, name: string) => string
  filterEntriesSort?: (
    entries: Array<Record<string, number>>,
    category?: string,
    formatter?: (category: string, name: string) => string
  ) => Array<Record<string, number>>
  onFilterChange: (category: string, value: string, enabled: boolean) => void
  onTextFilterChange: (category: string, value: string) => void
}

function SearchFiltersCategory ({
  category,
  initialOptions,
  availableOptions,
  enabledOptions,
  textFilter,
  categoryNameFormatter,
  filterNameFormatter,
  filterEntriesSort,
  onFilterChange,
  onTextFilterChange
}: Readonly<SearchFiltersCategoryProps>): ReactElement {
  const options = Object.entries(initialOptions).map(([name, value]) => ({
    [name]: value
  }))

  const filteredOptions = options.filter(option => {
    const [name] = Object.keys(option)
    const formattedName = filterNameFormatter
      ? filterNameFormatter(category, name)
      : name
    return formattedName?.toLowerCase().includes(textFilter?.toLowerCase())
  })

  const sortedOptions = filterEntriesSort
    ? filterEntriesSort(filteredOptions, category, filterNameFormatter)
    : filteredOptions

  const showTextFilter =
    Object.entries(initialOptions).length > MIN_ITEMS_TO_FILTER

  return (
    <Accordion.Item eventKey={category} className={styles.accordion}>
      <Accordion.Header>{categoryNameFormatter(category)}</Accordion.Header>
      <Accordion.Body>
        {showTextFilter && (
          <div className={styles['filter-input-wrapper']}>
            <Form.Control
              className={styles['filter-input']}
              size="sm"
              value={textFilter}
              onChange={e => {
                onTextFilterChange(category, e.target.value)
              }}
            />
          </div>
        )}

        <div className={styles['filter-list-wrapper']}>
          {sortedOptions.map(option => {
            const [name] = Object.keys(option)
            const formattedName = filterNameFormatter
              ? filterNameFormatter(category, name)
              : name
            const availableOptionsCount = availableOptions?.[name]
            const isChecked = enabledOptions.has(name)
            const isDisabled =
              !isChecked &&
              (availableOptionsCount < 1 || availableOptionsCount == null)

            return (
              <Form.Check
                key={`${category}-${name}`}
                className={styles.entry}
                id={`filter-${category}-${name}`}
                type="checkbox"
                checked={isChecked}
                disabled={isDisabled}
                label={`${formattedName} ${
                  isDisabled || availableOptionsCount == null
                    ? ''
                    : `(${availableOptionsCount})`
                }`}
                onChange={e => {
                  onFilterChange(category, name, e.target.checked)
                }}
              />
            )
          })}
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
}

export default SearchFiltersCategory
