import useSWR from 'swr'

interface UserSession {
  CURRENT_ACCOUNT: string
}

interface UseSharedSessionHook {
  isLoading: boolean
  userSessionData: UserSession
}

function useSharedSession (sid = ''): UseSharedSessionHook {
  const { data: userSessionData, isLoading } = useSWR(
    sid ? ['shared-session', sid] : null,
    async () => {
      const response = await fetch(
        `${process.env.REACT_APP_ENV_API_HOST ?? ''}/api/shared-session/${sid}`,
        {
          method: 'GET'
        }
      )
      return await response.json()
    }
  )

  return {
    userSessionData,
    isLoading
  }
}

export default useSharedSession
