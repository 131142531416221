import { type FormEvent, type ReactElement, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { OrderSearchType } from '../types/order-search-type'
import MaPageTitle from '../../common/components/MaPageTitle'
import OrderSearchForm from './OrderSearchForm'
import styles from '../assets/scss/PurchaseOrder.module.scss'

function ProductDetailsHeader (): ReactElement {
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState('')
  const [searchType, setSearchType] = useState<OrderSearchType>(
    OrderSearchType.MyOrders
  )
  const navigate = useNavigate()

  const handleSearchSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    if (!searchTerm.trim()) {
      setSearchTerm('')
      return
    }

    navigate(
      `/purchasing/orders?term=${searchTerm.trim()}&searchType=${searchType}`
    )
  }

  const searchTypeOptions = [
    {
      value: OrderSearchType.MyOrders,
      label: t('order.filters.select.my_orders')
    },
    {
      value: OrderSearchType.AllOrders,
      label: t('order.filters.select.all_orders')
    }
  ]

  return (
    <div className={styles.header}>
      <MaPageTitle>{t('purchase_order.title')}</MaPageTitle>

      <Row>
        <Col sm={12} md={8} xl={6}>
          <OrderSearchForm
            search={searchTerm}
            type={searchType}
            onSearchChange={e => {
              setSearchTerm(e.target.value)
            }}
            onTypeChange={setSearchType}
            onSubmit={handleSearchSubmit}
            searchTypeOptions={searchTypeOptions}
          />
        </Col>
      </Row>
    </div>
  )
}

export default ProductDetailsHeader
